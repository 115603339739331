/* eslint-disable react/jsx-no-literals */
import React from 'react'
import {
  A1, A2, A3, A4, A5, A6
} from '../../../9-assets/images/picturesPatient'

const PicturesPatientMessages = {
  title: 'Fotografías del paciente [patient]',
  button: 'Guardar',
  buttonBack: 'Paso anterior',
  inputsFiles: [
    {
      text: 'De frente sonrisa natural',
      prop: 'trueSmile',
      uploaded: false,
      maxFiles: 1,
      image: <img
        src={A1}
        alt="true-smile"
      />,
      placeHolder: 'Presione aquí para seleccionar el archivo',
    },
    {
      text: 'De frente serio',
      prop: 'profileFront',
      uploaded: false,
      maxFiles: 1,
      image: <img
        src={A1}
        alt="profile-front"
      />,
      placeHolder: 'Presione aquí para seleccionar el archivo'
    },
    {
      text: 'De lado serio',
      prop: 'profileRight',
      uploaded: false,
      maxFiles: 1,
      image: <img
        src={A1}
        alt="profile-right"
      />,
      placeHolder: 'Presione aquí para seleccionar el archivo'
    },
    {
      text: 'De frente en oclusión',
      prop: 'frontOcclusion',
      uploaded: false,
      maxFiles: 1,
      image: <img
        src={A2}
        alt="front-occ"
      />,
      placeHolder: 'Presione aquí para seleccionar el archivo'
    },
    {
      text: 'Lateral derecha oclusión',
      prop: 'rightLateralOcclusion',
      uploaded: false,
      maxFiles: 1,
      image: <img
        src={A3}
        alt="right-lat-occ"
      />,
      placeHolder: 'Presione aquí para seleccionar el archivo'
    },
    {
      text: 'Lateral izquierda oclusión',
      prop: 'leftLateralOcclusion',
      uploaded: false,
      maxFiles: 1,
      image: <img
        src={A4}
        alt="left-lat-occ"
      />,
      placeHolder: 'Presione aquí para seleccionar el archivo'
    },
    {
      text: 'Oclusal superior',
      prop: 'superiorOcclusion',
      uploaded: false,
      maxFiles: 1,
      image: <img
        src={A5}
        alt="sup-occ"
      />,
      placeHolder: 'Presione aquí para seleccionar el archivo'
    },
    {
      text: 'Oclusal inferior',
      prop: 'lowerOcclusion',
      uploaded: false,
      maxFiles: 1,
      image: <img
        src={A6}
        alt="low-occ"
      />,
      placeHolder: 'Presione aquí para seleccionar el archivo'
    },
    {
      text: 'Radiografías',
      prop: 'xray',
      uploaded: false,
      maxFiles: 1,
      icon: 'create_new_folder',
      image: null,
      placeHolder: 'Agregar radiografía'
    },
  ]

}

export default PicturesPatientMessages
