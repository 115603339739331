/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-literals */
import React, {
  useEffect, useContext, useState, useMemo
} from 'react'
import { Link, useParams } from 'react-router-dom'
import { AppContext } from '../../../../4-hooks/contexts/App/App.context'
import { getPlanningData } from '../../../../7-services/App/Planning.service'
import Planning from '../../../../6-models/App/Planning.model'
import { CardMoons } from '../../../UI/CardMoons/CardMoons'
import { IconButtonMoons } from '../../../UI/IconButtonMoons/IconButtonMoons'
import ReturnHistory from '../../../UI/ReturnHistory/ReturnHistory'
import messages from './StlMethod.messages'
import styles from './StlMethod.module.scss'
import { getTitle, selectStlMethod, getWaitMessage } from './StlMethod.logic'
import LoaderMoons from '../../../UI/LoaderMoons/LoaderMoons'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * StlMethod component
 * @returns {Void} component
 */
const StlMethod = ({ urlNextStep, urlBackStep }) => {
  const { stateApp: { functions, user, historyApp } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const { planningId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [chosenMethod, setChosenMethod] = useState('')
  const [doctorId, setDoctorId] = useState('')
  const [statePlanning, setStatePlanning] = useState('')
  const [statusPlanning, setStatusPlanning] = useState('')
  const [patientCustomerId, setCustomerId] = useState('')
  const [patientName, setPatientName] = useState('')
  const [patientData, setPatientData] = useState({})

  /**
   * nav back component
   * @returns {Void} component
   */
  const navBarBack = () => (
    <div className={styles.barNavigation}>
      <div className={styles.buttonBack}>
        <IconButtonMoons
          edge="start"
          aria-label="back_stl_method"
          icon="arrow_back"
          color="secondary"
          onClick={() => setChosenMethod('')}
        />
        {messages.back}
      </div>
      {getTitle(chosenMethod, styles.imgIcon)}
    </div>
  )

  useEffect(() => {
    if (planningId) {
      setIsLoading(true)
      getPlanningData(user.sessionToken, planningId)
        .then(res => {
          const planningData = new Planning(res)
          const {
            stlMethod, state, status, patient
          } = planningData
          const { customerId, name, user: { objectId } } = patient
          setChosenMethod(stlMethod)
          setDoctorId(objectId)
          setStatePlanning(state)
          setStatusPlanning(status)
          setCustomerId(customerId)
          setPatientName(name)
          setPatientData(patient)
        })
        .catch(error => {
          handleSnack(true, 'Error al traer la información de la Planeación', 'error')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Render waiting status message
   */
  const waitingMessage = useMemo(() => getWaitMessage({
    state: statePlanning, status: statusPlanning, planningId, historyApp
  }), [
    historyApp,
    statePlanning,
    statusPlanning,
    planningId
  ])

  return (
    <>
      <ReturnHistory
        route={user.userType === 'DOCTOR' ? `/${prefijApp}/patients/${user.objectId}` : `/${prefijApp}/patients`}
        historyApp={historyApp}
      />
      <CardMoons
        title={messages.title.replace('[patient]', patientName)}
        actions={(
          <>
            {
              urlBackStep
              && (
                <Link
                  className="linkStep"
                  to={urlBackStep}
                >
                  {messages.backButton}
                </Link>
              )
            }
            {
              urlNextStep
              && (
                <Link
                  className="linkStep"
                  to={urlNextStep}
                  onClick={() => {
                    // invocar metodo de guardado aqui
                  }}
                >
                  {messages.button}
                </Link>
              )
            }
          </>
        )}
      >
        {
          isLoading ? <LoaderMoons size={120} /> : (
            <>
              {
                (statePlanning === 'diagnosed' || statePlanning === 'stl_required' || statePlanning === 'appointment' || statePlanning === 'delimited')
                  && (statusPlanning === 'candidate' || statusPlanning === 'waiting_stl' || statusPlanning === 'not_attended' || statusPlanning === 'ready') ? (
                    <>
                      { chosenMethod && navBarBack() }
                      {
                        selectStlMethod(
                          {
                            chosenMethod,
                            doctorId,
                            planningId,
                            customerId: patientCustomerId,
                            patientData
                          },
                          { setChosenMethod }
                        )
                      }
                    </>
                  ) : <h3>{waitingMessage}</h3>
              }
            </>
          )
        }
      </CardMoons>
    </>
  )
}

export default StlMethod
