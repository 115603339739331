/* eslint-disable max-len */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import {
  Card, CardActions, CardContent
} from '@material-ui/core'
import styles from './CardMoons.module.scss'

/**
 * Generic card moons comp
 * @param {*} param0 .
 * @param {*} param0.children to render
 * @param {*} param0.title for card
 * @param {*} param0.actions actions components to render
 * @param {*} param0.actionHeader header for actions
 * @param {*} param0.heightFull style height
 * @returns {Void} component
 */
export const CardMoons = ({
  children, title, actions, actionHeader, heightFull = false, ...rest
}) => (
  <Card
    className={`${styles.cardContainer} ${heightFull ? styles.heightFull : ''}`}
    {...rest}
  >
    {
      title && (
        <h1 className={styles.title}>
          {title}
          {' '}

          <span>{actionHeader}</span>
        </h1>
      )
    }
    <CardContent className={styles.heightFull}>
      {children}
    </CardContent>
    {
      actions && (
        <CardActions
          className={
            Array.isArray(actions?.props?.children)
            && actions?.props?.children.every(child => child) ? styles.cardActionsMultiple : styles.cardActionsSolo
          }
        >
          {
            actions
          }
        </CardActions>
      )
    }
  </Card>
)
