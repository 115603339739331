/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useContext, useState } from 'react'
import { Redirect, useParams } from 'react-router'
import messages from './ValidationsView.messages'
import styles from './ValidationsView.module.scss'
import { ValidatePictures } from '../../../3-components/App/ValidatePictures/ValidatePictures'
import { Diagnose } from '../../../3-components/App/Diagnose/Diagnose'
import { AppContext } from '../../../4-hooks/contexts/App/App.context'
import { useForm } from '../../../4-hooks/useForm'
import PlanningModel from '../../../6-models/App/Planning.model'
import { PicturesPatientModel } from '../../../6-models/App/PicturesPatient.model'
import { getPlanningData } from '../../../7-services/App/Planning.service'
import { getArrayFile } from './ValidationsView.logic'
import LoaderMoons from '../../../3-components/UI/LoaderMoons/LoaderMoons'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * Validations Admin View
 * choose between validate picture and diagnose
 * @returns {Void} component
 */
export const ValidationsView = () => {
  const { stateApp: { functions, user, historyApp } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const [isLoading, setIsLoading] = useState(false)
  const [imageList, setImageList] = useState([])
  const { planningId, view } = useParams()
  const [
    formValues,
    setValues,,,
    progress,
    setInProgress
  ] = useForm(new PlanningModel())
  const {
    // state,
    status,

    /*
     * upperAligners,
     * lowerAligners,
     * patientType,
     * severity,
     */
    patient
  } = formValues

  useEffect(() => {
    if (planningId) {
      setIsLoading(true)
      getPlanningData(user.sessionToken, planningId)
        .then(res => {
          const planning = new PlanningModel(res)
          setValues(planning)
          const { files } = planning
          const filesObj = new PicturesPatientModel(files)
          const listFiles = getArrayFile(filesObj)
          setImageList(listFiles)
        })
        .catch(error => {
          handleSnack(true, 'Error al traer información de la Planeación', 'error')
          historyApp.push(`/${prefijApp}/patients`)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!(view === messages.validatePictures || view === messages.diagnose)) {
    return <Redirect to="./home" />
  }

  /**
   * get styles by type of view
   * @returns {String} class name
   */
  const getClass = () => (view === messages.validatePictures ? styles.onlyValidationPicture : '')

  return (
    isLoading ? <LoaderMoons size={120} /> : (
      <div className={[styles.gridContainer, getClass()].join(' ')}>
        {view === messages.diagnose && (
          <div>
            <Diagnose />
          </div>
        )}
        <ValidatePictures
          useDiagnose={view === messages.diagnose}
          name={patient?.name || ''}
          images={imageList}
          token={user.sessionToken}
          planningId={planningId}
          progress={progress}
          status={status}
          setInProgress={setInProgress}
          handleSnack={handleSnack}
          historyApp={historyApp}
          setValues={setValues}
        />
      </div>
    )
  )
}
