/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-process-env */
import React, { useContext } from 'react'
import {
  Redirect, Route, useLocation
} from 'react-router-dom'
import APP_ROUTES from './Profiles/ConfigProfiles'
import { HomeView } from '../2-views/App/Home/HomeView'
import { AppContext } from '../4-hooks/contexts/App/App.context'
import { ProfileTypes } from './Profiles/Propfiles.type'

const prefijApp = process.env.REACT_APP_PREFIJ_APP
const zero = 0


// Creacion de vistas a partir de perfiles

/**
 * handle swtich router
 * @param {*} param0 .
 * @returns {Void} show routes by role
 */
export const SwitchProfile = ({
  ...rest
}) => {
  const { stateApp } = useContext(AppContext)
  const { user: { role: [role], objectId } } = stateApp
  const location = useLocation()

  /**
   * select component by profile
   * @returns {Void} component
   */
  const selectComponentProfile = () => {
    /**
     * validate routes
     * @param {*} route .
     * @returns {Boolean} is valid
     */
    const validateRoutes = (route) => {
      const elements1 = route.split('/')
      const elements2 = location.pathname.split('/')
      return (elements1.length === elements2.length) && (elements1[2] === elements2[2])
    }

    const structureProfile = APP_ROUTES.find(appRoute => {
      const route = `/${prefijApp}${appRoute.route}`
      let validRoute = false

      if (appRoute.route.includes(':')) {
        validRoute = validateRoutes(route)
      } else {
        validRoute = route === location.pathname
      }
      return validRoute
        && appRoute.profiles.some(
          profile => profile === role.name.toUpperCase()
        ) ? appRoute : null
    })

    let _route = null

    if (structureProfile) {
      const { component, route } = structureProfile
      _route = (
        <Route
          {...rest}
          path={`/${prefijApp}${route}`}
          key={route}
          component={component}
        />
      )
    } else {
      _route = <Redirect to={`/${prefijApp}/home`} />
    }

    return _route
  }

  /**
   * Menu links list by role
   * @param {*} roleName name of role
   * @returns {Array} list of links
   */
  const returnLinksByProfile = (roleName) => {
    let _links = []

    const routes = APP_ROUTES.filter(appRouteConfig => {
      const foundRoute = appRouteConfig.profiles.some(profile => profile === roleName)
      return foundRoute ? appRouteConfig : null
    })

    if (routes.length > zero) {
      routes.forEach(filteredRoute => {
        if (filteredRoute.profilesMenu.some(roleMenu => roleMenu === roleName)) {
          if (roleName === 'DOCTOR') {
            const _route = filteredRoute.route.replace(':doctorId', objectId)
            _links = [..._links, { ...filteredRoute, route: `/${prefijApp}${_route}` }]
          } else {
            _links = [..._links, { ...filteredRoute, route: `/${prefijApp}${filteredRoute.route}` }]
          }
        }
      })
    }

    return _links
  }

  /**
   * get default view by role
   * @param {String} roleName user's role
   * @returns {String} role's default route
   */
  const getDefaultView = (roleName) => {
    switch (roleName) {
    case ProfileTypes.doctor_moons:
    case ProfileTypes.superadmin:
      return 'doctors'

    default: return `patients/${objectId}`
    }
  }

  return (
    <HomeView links={[...returnLinksByProfile(role.name)]}>
      {
        (rest.location.pathname === `/${prefijApp}/home` ? <Redirect to={`/${prefijApp}/${getDefaultView(role.name)}`} /> : selectComponentProfile()
        )
      }
    </HomeView>
  )
}
