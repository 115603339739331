/* eslint-disable import/prefer-default-export */
import { requestMoons } from '../../8-common/Request'

/**
 * get list of all doctors. for admin
 * @param {String} userType user role to get list by
 * @returns {Array} list of doctors
 */
export const getAllUsersByRole = (userType) => requestMoons({
  endpoint: '/users/get',
  method: 'GET',
  params: { userType },
  useBasicAuthHeader: true
})
