import { OptionModelSelectMoons } from '../../../UI/SelectMoons/Option.model'

const PatientdetailMessages = {
  FormH1: 'Nuevo Paciente',
  h1Edit: 'Editar Paciente [name]',
  name: 'Nombre Completo',
  email: 'Correo electrónico',
  phone: 'Celular',
  countryOps: 'País',
  finalPrice: 'Precio Final',
  paidAmount: 'Cantidad Pagada',
  state: 'Estado',
  status: 'Estatus',
  textButton: 'Crear',
  btnEdit: 'Guardar',
  backButton: 'Pacientes',
  chosenDr: 'Doctor a asignar',
  countries: [
    new OptionModelSelectMoons({
      text: 'México',
      value: 'MX'
    }),
    new OptionModelSelectMoons({
      text: 'Colombia',
      value: 'CO'
    })
  ],
}

export default PatientdetailMessages
