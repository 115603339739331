/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { Link } from 'react-router-dom'
import { getAllUsersByRole } from '../../../../7-services/App/Doctor.service'
import { RowDataTableModel } from '../../../UI/DataTableMoons/RowDataTable.model'
import { ButtonMoons } from '../../../UI'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * Handlw API call to get list of doctors
 * @param {String} location route location
 * @returns {Array} list of doctors modified for table
 */
export const getRowsDoctors = async (location) => {
  let listType = 'DOCTOR'
  if (location.pathname.includes('doctors-moons')) {
    listType = 'DOCTOR_MOONS'
  }
  if (location.pathname.includes('admins-moons')) {
    listType = 'SUPER_ADMIN'
  }
  let doctors = []
  try {
    doctors = await getAllUsersByRole(listType)
  } catch (error) {
    console.error('Error GET Doctors: ', error)
  }
  const one = 1

  return doctors.map((doctor, idx) => new RowDataTableModel({
    id: idx + one,
    objectId: doctor.objectId,
    name: doctor.name,
    email: doctor.username,
    phone: doctor.phone
  }))
}

/**
 * Render cell actiosn components
 * @param {Object} rowData material row data object
 * @returns {Object} jsx component
 */
export const renderCellActions = (rowData) => (
  <Link to={`/${prefijApp}/doctor/${rowData?.row?.objectId}`}>
    <ButtonMoons
      size="small"
      onClick={(evt) => {
        evt.stopPropagation()
      }}
    >
      Actualizar
    </ButtonMoons>
  </Link>
)

/**
 * handles row click in doctor list
 * @param {Object} rowData data table row data doctor
 * @param {Function} historyApp history router
 * @returns {Void} handles routing
 */
export const onDoctorClick = (rowData, historyApp) => {
  if (rowData.field !== 'actions') {
    historyApp.push(`/${prefijApp}/patients/${rowData?.row?.objectId}`)
  }
}

/**
 * get component list title by location
 * @param {*} location router location
 * @returns {String} title
 */
export const getListTitle = (location) => {
  let listType = 'doctores'
  if (location.pathname.includes('doctors-moons')) {
    listType = 'doctores moons'
  }
  if (location.pathname.includes('admins-moons')) {
    listType = 'administradores'
  }
  return listType
}
