import { RadioModelRadioButtonsGroupMoons } from '../../UI/RadioButtonsGroupMoons/Radio.model'
import { OptionModelSelectMoons } from '../../UI/SelectMoons/Option.model'

const DiagnoseMessages = {
  message1: 'Diagnostico',
  message2: 'Severidad',
  message3: 'Rango de Alineadores',
  message4: 'Notas Paciente',
  message5: 'Notas Doctor',
  message6: 'Guardar',
  message7: 'Diagnosticar',

  optionsSeverity: [
    new RadioModelRadioButtonsGroupMoons({
      text: 'Leve',
      value: 'low',
    }),
    new RadioModelRadioButtonsGroupMoons({
      text: 'Moderado',
      value: 'half',
    }),
    new RadioModelRadioButtonsGroupMoons({
      text: 'Complejo',
      value: 'high',
    }),
  ],
  optionsRank: [
    new RadioModelRadioButtonsGroupMoons({
      text: 'Simple / 20 Alineadores',
      value: 'low_20',
    }),
    new RadioModelRadioButtonsGroupMoons({
      text: 'Moderado / 21 a 35 Alineadores',
      value: 'half_21_35',
    }),
    new RadioModelRadioButtonsGroupMoons({
      text: 'Complejo / +35 Alineadores',
      value: 'higher_35',
    }),
  ],
  diagnosisOptions: [
    new OptionModelSelectMoons({
      text: 'moons',
      value: 'moons'
    }),
    new OptionModelSelectMoons({
      text: 'Candidato',
      value: 'candidate'
    }),
    new OptionModelSelectMoons({
      text: 'moons Posible Advanced',
      value: 'moons_possible_advanced'
    }),
    new OptionModelSelectMoons({
      text: 'moons Advanced',
      value: 'moons_advanced'
    }),
    new OptionModelSelectMoons({
      text: 'No Candidato',
      value: 'no_candidate'
    }),
    new OptionModelSelectMoons({
      text: 'Necesita Más Información',
      value: 'need_extra_data'
    }),
    new OptionModelSelectMoons({
      text: 'Necesita Tratamiento Médico',
      value: 'need_medical_treatment'
    })
  ],
}

export default DiagnoseMessages
