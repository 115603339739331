/**
 * Shipping class dto
 */
class Shipping {
  /**
   * constructor
   * @param {*} data Shipping dto
   */
  constructor(data = {}) {
    this.datetime = data.datetime || null
    this.label = data.label || ''
    this.state = data.state || ''
    this.status = data.status || ''
  }
}

export default Shipping
