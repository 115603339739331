/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import { ButtonMoons } from '../../../../UI'
import style from './SelectMethod.module.scss'
import { image1, image2, image3 } from '../../../../../9-assets/images/stlMethod'
import messages from './SelectMethod.messages'
import messages2 from '../StlMethod.messages'

/**
 * select portion of stl method component
 * @param {Object} param0 .
 * @param {Function} param0.setMethod change selected method setState
 * @returns {Void} component selection
 */
export const SelectMethod = ({ setMethod }) => (
  <div className={style.gridContainer}>
    <div>
      <img
        src={image1}
        alt="set-printing"
      />
      {messages.message1}
      <ButtonMoons
        variant="outlined"
        color="secondary"
        onClick={() => setMethod(messages2.printing)}
      >
        {messages.button1}
      </ButtonMoons>
    </div>
    <div>
      <img
        src={image2}
        alt="set-appointment"
      />
      {messages.message2}
      <ButtonMoons
        variant="outlined"
        color="secondary"
        onClick={() => setMethod(messages2.appointment)}
      >
        {messages.button1}
      </ButtonMoons>
    </div>
    <div>
      <img
        src={image3}
        alt="set-upload"
      />
      {messages.message3}
      <ButtonMoons
        variant="outlined"
        color="secondary"
        onClick={() => setMethod(messages2.uploadStl)}
      >
        {messages.button1}
      </ButtonMoons>
    </div>
  </div>
)
