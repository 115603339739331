/* eslint-disable import/prefer-default-export */
/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import { DataGrid } from '@material-ui/data-grid'
import { five } from '../../../8-common/Constants'
// import styles from './DataTableMoons.module.scss'

/**
 * data table moons component
 * @param {*} param0 .
 * @returns {Void} component
 */
export const DataTableMoons = ({ pageSize, checkboxSelection, ...rest }) => (
  <DataGrid
    {...rest}
    pageSize={pageSize || five}
    checkboxSelection={checkboxSelection || false}
    scrollbarSize={45}
  />
)

/*
 * Config: https://material-ui.com/api/data-grid/
 * Example: https://github.com/mui-org/material-ui-x/blob/master/docs/src/pages/components/data-grid/overview/DataGridDemo.js
 */

DataTableMoons.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageSize: PropTypes.number,
  // eslint-disable-next-line react/boolean-prop-naming
  checkboxSelection: PropTypes.bool
}
