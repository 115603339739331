const PlanSummaryViewMessages = {
  title1: 'Plan de tratamiento de [patient]',
  backButton: 'Pacientes',
  message1: 'Para obtener los resultados de tu video planeación, serán necesarios:',
  message2: 'Copiar link de tratamiento',
  message3: 'El Navegador que estas utilizando no permite mostrar algunos videos. Favor de utilizar uno diferente.',
  message4: ' alineadores superiores',
  message5: ' alineadores inferiores',
  message6: ' meses de tratamiento',
  message7: 'Si inicias hoy, estarás terminando tu tratamiento en ',
  message8: 'Precio del tratamiento',
  message9: 'Precio Normal:',
  message10: 'Precio:',
  message11: 'Por liquidar:',
  message12: 'Aprobar Tratamiento',
  message13: 'Consideraciones dentales del plan de tratamiento',
  iconCopy: 'content_copy',
  icon1: 'cloud_download',
  iconCheck: 'check_box',
  lblApprDate: 'Aceptaste el tratamiento el: [date]',
  iconDelivery: 'all_inbox',
  lblDeliverDate: 'Fecha estimada de entrega: [date]',
  iconPayment: 'attach_money',
  lblPayDate: 'Gracias por tu pago: [date]',
  downloadDatasheet: 'Descargar Ficha Técnica',
  srcVideo: 'https://pro-diagnostics-media.s3.amazonaws.com/c91ac2bc4062ccc3984235376202b86b077c4e93-video.mp4',
  listPriceSeverity: {
    low: '$18,500.00',
    moderate: '$19,600.00',
    severe: '$25,500.00'
  },
  treatmentPlan: 'Plan de tratamiento',
  clickPicture: 'Haz click en la imagen ',
  viewTreatmentPlan: 'para visualizar el plan de tratamiento',
}

export default PlanSummaryViewMessages
