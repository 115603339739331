/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import { FormWrapper } from '../../../3-components/Login/FormWrapper/FormWrapper'
import { UpdateForm } from '../../../3-components/Login/UpdateForm/UpdateForm'

/**
 * update view
 * @returns {Void} component
 */
export const UpdateView = () => (
  <FormWrapper classContainer="signInContainer">
    <UpdateForm />
  </FormWrapper>
)
