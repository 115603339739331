/* eslint-disable max-statements */
import moment from 'moment-timezone'
import { updatePlanning } from '../../../../../7-services/App/Planning.service'
import { createShipping } from '../../../../../7-services/App/Shipping.service'
import stlMessages from '../StlMethod.messages'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * handle create shipping and edit Planning state/status/stlMethod
 * @param {*} event submit event
 * @param {*} param1 .
 * @param {*} param1.pickUpDate datetime for Shipping
 * @param {*} param1.planningId Planning obejctId for PUT
 * @param {*} param1.user authenticated user object
 * @param {*} param1.historyApp router
 * @param {*} param2 .
 * @param {*} param2.handleSnack open snackbar
 * @param {*} param2.setInProgress set progress flag
 * @returns {Void} chreates shipping, then edit planning
 */
const handleSubmitForm = async (
  event,
  {
    pickUpDate, planningId, user, historyApp
  },
  { handleSnack, setInProgress }
) => {
  event.preventDefault()

  setInProgress(true)
  try {
    const shipping = { datetime: moment(pickUpDate).utc(true).toString(), updatedBy: user.objectId }
    await createShipping({ token: user.sessionToken, shipping, planningId })
  } catch (error) {
    handleSnack(true, 'Error al crear recolección', 'error')
    setInProgress(false)
  }

  try {
    const putPlannReq = {
      stlMethod: stlMessages.printing,
      state: 'stl_required',
      status: 'pickup_requested',
      updatedBy: user.objectId
    }
    await updatePlanning(user.sessionToken, planningId, putPlannReq)
    historyApp.push(`/${prefijApp}/home`)
  } catch (error) {
    handleSnack(true, 'Error al completar selección de recolección', 'error')
  } finally {
    setInProgress(false)
  }
}

export default handleSubmitForm
