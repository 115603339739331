import { useMemo } from 'react'
import { COUNTRIES } from '../8-common/Constants'

/**
 * Reads the path an checks if the string is an allowed country code
 * @returns {String} Country code
 */
const useLocationOrigin = () => {
  const countryPath = useMemo(() => {
    const countryOrigin = window.location.href.split('/')[3].toLowerCase()
    if (!COUNTRIES.includes(countryOrigin)) return 'mx'
    return countryOrigin
  }, [])

  return countryPath
}

export default useLocationOrigin
