/* eslint-disable import/prefer-default-export */

import React, { createContext, useReducer } from 'react'
import { getStoreGlobal } from '../../../7-services/LocalStorage.service'
import { appReducer } from './App.reducer'


export const AppContext = createContext(null)

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const AppContextProvider = ({ children }) => {
  const [stateApp, dispatch] = useReducer(appReducer, {}, () => getStoreGlobal('app') || {
    historyApp: {},
    functions: {},
    user: {},
  })

  return (
    <AppContext.Provider value={{
      stateApp,
      dispatch
    }}
    >
      {children}
    </AppContext.Provider>
  )
}
