/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-process-env */
import React, {
  useContext, useEffect, useState, useCallback, useRef
} from 'react'
import { Link, useParams } from 'react-router-dom'
import { CardMoons } from '../../../UI/CardMoons/CardMoons'
import { DataTableMoons } from '../../../UI/DataTableMoons/DataTableMoons'
// import styles from './PatientList.module.scss';
import messages from './PatientList.messages'
import { deleteSelectedPatient, getRowsPatients, onPatientClick } from './PatientList.logic'
import { AppContext } from '../../../../4-hooks/contexts/App/App.context'
import { IconMoons } from '../../../UI/IconMoons/IconMoons'
import { TooltipMoons } from '../../../UI/TooltipMoons/TooltipMoons'
import { getUserData } from '../../../../7-services/App/User.service'
import NoRowsButton from '../../../UI/NoRowsButton/NoRowsButton'
import ConfirmationModal from '../../../UI/Modals/ConfirmationModal'

const prefijApp = process.env.REACT_APP_PREFIJ_APP


/**
 * patient list component
 * @returns {Void} component
 */
export const PatientList = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(null)
  const [patients, setPatients] = useState([])
  const [drName, setDrName] = useState('')
  const { stateApp: { user, functions, historyApp } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const { doctorId } = useParams()
  const isRendered = useRef(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [patient, setPatient] = useState(null)

  /**
   * Fetch patients
   * @return {*} void
   */
  const loadPatients = () => {
    getRowsPatients(user, doctorId)
      .then(_persons => {
        setPatients(_persons)
        setIsError(null)
        setIsLoading(false)
      })
      .catch(error => {
        setIsError(true)
        handleSnack(true, 'Error al traer la lista de pacientes', 'error')
        setIsLoading(false)
      })
  }

  useEffect(() => {
    isRendered.current = true
    setIsLoading(true)
    if (doctorId) {
      getUserData(doctorId)
        .then(res => {
          if (isRendered.current) {
            setDrName(res?.name)
            loadPatients()
          }
        })
      return () => {
        isRendered.current = false
      }
    }
    loadPatients()

    return () => {
      isRendered.current = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * No rows componente custom overlay
   * @returns {Void} component
   */
  const renderNoRows = useCallback(() => (
    <NoRowsButton
      message={messages.noRowsMsg}
    />
  ), [])


  /** 
   * Open confirmation modal
  */
  const openDeletionConfirmModal = (rowData) => {
    setIsOpenModal(true)
    setPatient(rowData)
  }

  return (
    <CardMoons
      title={
        doctorId ? messages.title.replace('[drName]', `de ${drName}`) : messages.title.replace('[drName]', '')
      }
      actionHeader={(
        <TooltipMoons
          title="Nuevo Paciente"
          placement="left"
        >
          <Link to={`/${prefijApp}/patient/new`}>
            <IconMoons name="add_circle" />
          </Link>
        </TooltipMoons>
      )}
      heightFull
    >
      <DataTableMoons
        id={Math.random()}
        rows={patients}
        columns={messages.columnsTable(user, openDeletionConfirmModal, handleSnack, setIsLoading).filter(column => column)}
        pageSize={50}
        loading={isLoading}
        error={isError}
        onCellClick={(rowData) => onPatientClick(rowData, historyApp)}
        components={{
          NoRowsOverlay: renderNoRows
        }}
      />
      <ConfirmationModal
        title={messages.modal.title}
        description={patient ? messages.modal.description.replace('[patient]', patient.row.name) : ''}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        isLoading={isLoading}
        primaryButtonLabel={messages.modal.primaryButtonLabel}
        primaryButtonCta={() => deleteSelectedPatient(patient, user, setIsOpenModal, setIsLoading, handleSnack, loadPatients)}
      />
    </CardMoons>
  )
}
