/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import messages from './LoginForm.messages'
import { LoginDto } from '../../../6-models/Login/Login.dto'
import { useForm } from '../../../4-hooks/useForm'
import { handleSubmitFormLogin } from './LoginForm.logic'
import { ButtonMoons, InputMoons } from '../../UI'
import { AppContext } from '../../../4-hooks/contexts/App/App.context'
// import styles from './LoginForm.module.scss'

/**
 * login form comp
 * @returns {Void} component
 */
export const LoginForm = () => {
  const { dispatch, stateApp } = useContext(AppContext)
  const { historyApp, functions: { handleSnack } } = stateApp
  const [formValues, , handleInputChange,
    reset,
    progress,
    setInProgress] = useForm(new LoginDto())
  const { email, password } = formValues

  return (
    <form
      className="generic"
      onSubmit={(event) => handleSubmitFormLogin({ event, formValues }, {
        reset, handleSnack, setInProgress, historyApp, dispatch
      })}
    >
      <h1>{messages.FormH1}</h1>
      <InputMoons
        type="email"
        name="email"
        autoComplete="off"
        placeholder={messages.placeHolderInput1}
        value={email}
        onChange={handleInputChange}
      />
      <InputMoons
        type="password"
        name="password"
        autoComplete="off"
        placeholder={messages.placeHolderInput2}
        value={password}
        onChange={handleInputChange}
      />
      <Link to="./recovery">{messages.recoveryText}</Link>
      <ButtonMoons
        variant="contained"
        color="primary"
        type="submit"
        isprogress={Number(progress)}
      >
        {messages.textButton}
      </ButtonMoons>
    </form>
  )
}
