/* eslint-disable import/prefer-default-export */
/**
 * update form dto
 */
export class UpdateDto {
    publicKey;

    password;

    /**
     * constructor update password form dto
     * @param {*} data password form dto
     */
    constructor(data = {}) {
      this.publicKey = data.publicKey || ''
      this.password = data.password || ''
    }
}
