/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../../../4-hooks/contexts/App/App.context'
import { useForm } from '../../../4-hooks/useForm'
import { RecoveryDto } from '../../../6-models/Login/Recovery.dto'
import { ButtonMoons, InputMoons } from '../../UI'
import { handleSubmitFormRecovery } from './RecoveryForm.logic'
import messages from './RecoveryForm.messages'

/**
 * recover form component
 * @returns {Void} component
 */
export const RecoveryForm = () => {
  const { stateApp: { functions: { handleSnack } } } = useContext(AppContext)
  const [formValues, , handleInputChange,
    reset,
    progress,
    setInProgress] = useForm(new RecoveryDto())
  const { email } = formValues

  return (
    <form
      className="generic"
      onSubmit={(evt) => handleSubmitFormRecovery(
        evt, formValues, { reset, handleSnack, setInProgress }
      )}
    >
      <h1>{messages.FormH1}</h1>
      <InputMoons
        type="text"
        name="email"
        autoComplete="off"
        placeholder={messages.placeHolderInput1}
        value={email}
        onChange={handleInputChange}
      />
      <Link to="./login">{messages.accesText}</Link>
      <ButtonMoons
        variant="contained"
        color="primary"
        type="submit"
        isprogress={Number(progress)}
      >
        {messages.textButton}
      </ButtonMoons>
    </form>
  )
}
