/* eslint-disable max-statements */
import { createMedHist, updateMedHist } from '../../../7-services/App/MedicalHistory.service'

/**
 * Update or create med hist data
 * @param {Object} obj .
 * @param {String} obj.token user's session token
 * @param {Object} obj.medHist Medical history data object
 * @param {String} obj.[patientId] Patient objectId to relate med hist and Planning to
 * @param {String} obj.[medHistObjId] Medical History objectId to fetch to update
 * @returns {Object} update or created med hist sata
 */
export const upsertMedHist = async ({
  token, patientId = null, medHistObjId = null, urlNextStep, formValues,
  handleSnack, setInProgress, historyApp, user
}) => {
  const medHist = buildMedHistBody(formValues)
  medHist.updatedBy = user.objectId
  setInProgress(true)
  if (medHistObjId) {
    try {
      await updateMedHist(token, medHistObjId, medHist)
      handleSnack(true, 'Éxito al editar el Historial Médico', 'success')
      urlNextStep && historyApp.push(urlNextStep)
      return
    } catch (error) {
      handleSnack(true, 'Error al editar el Historial Médico', 'error')
      return
    } finally {
      setInProgress(false)
    }
  }
  try {
    await createMedHist(token, patientId, medHist)
    handleSnack(true, 'Éxito al editar el Historial Médico', 'success')
    urlNextStep && historyApp.push(urlNextStep)
    return
  } catch (error) {
    handleSnack(true, 'Error al guardar información del Historial Médico', 'error')
    return
  } finally {
    setInProgress(false)
  }
}

/**
 * Convert form values to Parse obj data
 * @param {Object} values form values med hist data
 * @returns {Object} Med Hist Parse obj data
 */
export const buildMedHistBody = (values) => ({
  birthdate: values.birthdate,
  gender: values.gender,
  quiz: {
    patientTarget: values.patientTarget,
    isMedicated: values.isMedicated,
    hasPathology: values.hasPathology,
    hadOrthodontics: values.hadOrthodontics,
    haveDentalImplantsOrBridges: values.haveDentalImplantsOrBridges,
    haveCrownsVeneersResins: values.haveCrownsVeneersResins,
    alterationInAtm: values.alterationInAtm
  }
})
