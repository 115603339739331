/* eslint-disable import/prefer-default-export */
/**
 * radio model
 */
export class RadioModelRadioButtonsGroupMoons {
  /**
   * constructor
   * @param {*} object radio model buttons group
   */
  constructor(object = {}) {
    this.text = object.text || ''
    this.value = object.value || ''
    this.disabled = object.disabled || false
  }
}
