/* eslint-disable react/jsx-no-literals */
import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useParams } from 'react-router-dom'
import { AppContext } from '../../../4-hooks/contexts/App/App.context'
import { useForm } from '../../../4-hooks/useForm'
import PlanningModel from '../../../6-models/App/Planning.model'
import ShippingModel from '../../../6-models/App/Shipping.model'
import { ButtonMoons, InputMoons } from '../../UI'
import { DatePickerMoons } from '../../UI/DatePickerMoons/DatePickerMoons'
import { IconButtonMoons } from '../../UI/IconButtonMoons/IconButtonMoons'
import { CardMoons } from '../../UI/CardMoons/CardMoons'
import SwitchMoons from '../../UI/SwitchMoons/SwitchMoons'
import { getPlanningData } from '../../../7-services/App/Planning.service'
import { handleSubmitForm } from './ShippingForm.logic'
import LoaderMoons from '../../UI/LoaderMoons/LoaderMoons'
import styles from './ShippingForm.module.scss'
import messages from './ShippingForm.messages'
import { getTimezone } from '../../../8-common/UtilFunctions'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * ShippingForm component for edit Shipping admin use
 * @returns {Void} component
 */
const ShippingForm = () => {
  const { stateApp: { functions, user, historyApp } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const [isLoading, setIsLoading] = useState(false)
  const [patientName, setPatientName] = useState('')
  const [changeStatus, setChangeStatus] = useState(false)
  const [
    formValues,
    setValues,
    handleInputChange,,
    progress,
    setInProgress, ,
    handleDatePickerChange
  ] = useForm(new ShippingModel())
  const {
    datetime,
    label,
    state,
    status
  } = formValues
  const { planningId, shippingId } = useParams()

  useEffect(() => {
    if (planningId) {
      setIsLoading(true)
      getPlanningData(user.sessionToken, planningId)
        .then(res => {
          const planning = new PlanningModel(res)
          const { shipping, patient } = planning
          const shippingData = new ShippingModel(shipping)
          shippingData.datetime = moment(shippingData?.datetime?.iso)
            .tz(getTimezone(patient?.countryOps)).toString() || null
          setValues(shippingData)
          const { patient: { name } } = planning
          setPatientName(name)
        })
        .catch(error => {
          handleSnack(true, 'Error al obtener información de la Planeación', 'error')
          historyApp.push(`/${prefijApp}/patients`)
        })
        .finally(() => {
          setIsLoading(false)
        })
      return
    }
    historyApp.push(`/${prefijApp}/patients`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    isLoading ? <LoaderMoons size={120} /> : (
      <>
        <div className={[styles.gridContainer, styles.header].join(' ')}>
          <div className={styles.regionBtnBack}>
            <IconButtonMoons
              edge="start"
              aria-label="back_history"
              icon="arrow_back"
              color="secondary"
              onClick={() => historyApp.push(`/${prefijApp}/patients`)}
            />
            <p>{messages.backBtn}</p>
          </div>
        </div>

        <form
          onSubmit={(evt) => handleSubmitForm(
            evt,
            {
              formValues, user, historyApp, planningId, shippingId, changeStatus
            },
            { handleSnack, setInProgress }
          )}
        >
          <CardMoons
            title={messages.title.replace('[patient]', patientName)}
            actions={(
              <>
                <ButtonMoons
                  variant="contained"
                  color="primary"
                  type="submit"
                  isprogress={Number(progress)}
                >
                  {messages.btnEdit}
                </ButtonMoons>
              </>
            )}
          >
            <div className={styles.gridContainer}>

              <DatePickerMoons
                label={messages.phDatetime}
                name="datetime"
                value={datetime}
                onChange={handleDatePickerChange}
                showTime
                timeLabel={messages.lblTimeDatetime}
                required
              />

              <InputMoons
                type="text"
                name="label"
                autoComplete="off"
                placeholder={messages.phLabelUrl}
                value={label}
                onChange={handleInputChange}
                required
              />

              <InputMoons
                type="text"
                name="state"
                autoComplete="off"
                placeholder={messages.phState}
                value={state}
                onChange={handleInputChange}
              />

              <InputMoons
                type="text"
                name="status"
                autoComplete="off"
                placeholder={messages.phStatus}
                value={status}
                onChange={handleInputChange}
              />

              <>
                <SwitchMoons
                  name="changeStatus"
                  checked={changeStatus}
                  onChange={(evt) => setChangeStatus(evt.target.checked)}
                  label={messages.lblChangeStatus}
                  color="primary"
                />
                {
                  changeStatus && (
                    <p className={styles.statusAlert}>{messages.alertStatus}</p>
                  )
                }
              </>

            </div>
          </CardMoons>
        </form>
      </>
    )
  )
}

export default ShippingForm
