/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable import/prefer-default-export */
import { createPatient, updatePatient } from '../../../../7-services/App/Patient.service'
import { someEmptyProperty } from '../../../../8-common/UtilFunctions'
import {
  zero, three, errorCodes, ten
} from '../../../../8-common/Constants'
import messagesCopy from './PatientDetail.messages'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * handle submit edit and create
 * if patientId is present it handle edit, otherwise create
 * @param {*} event submit event
 * @param {*} param1 .
 * @param {Object} param1.formValues Patient form values dto
 * @param {Object} param1.user user data
 * @param {Function} param1.historyApp router
 * @param {String} param1.patientId Patient´s objectId in case of update
 * @param {Object} param1.chosenDr Doctor object item from list to bind new patient to
 * @param {*} param2 .
 * @param {Function} param2.reset reset form
 * @param {Function} param2.handleSnack open snackbar
 * @param {Function} param2.setInProgress change flag in progress
 * @returns {Void} submit
 */
export const handleSubmitForm = async (
  event,
  {
    formValues, user, historyApp, patientId, chosenDr
  },
  { handleSnack, setInProgress }
) => {
  event.preventDefault()

  if (patientId) {
    const messageError = validateNewPatientForm(formValues, user.userType)
    if (messageError) {
      handleSnack(true, messageError, 'error')
      return
    }
    const formValsCopy = { ...formValues }
    formValsCopy.name = formValsCopy?.name?.trim() || formValsCopy?.name
    formValsCopy.email = formValsCopy?.email?.trim() || formValsCopy?.email
    formValsCopy.phone = formValsCopy?.phone?.trim() || formValsCopy?.phone
    formValsCopy.finalPrice = parseFloat(formValsCopy.finalPrice, ten)
    formValsCopy.paidAmount = parseFloat(formValsCopy.paidAmount, ten)
    formValsCopy.updatedBy = user.objectId
    formValsCopy.chosenDr = chosenDr

    try {
      setInProgress(true)
      await updatePatient({ token: user.sessionToken, patientId, patientData: formValsCopy })
      handleSnack(true, 'Paciente actualizado con éxito', 'success')
      setInProgress(false)
    } catch (error) {
      handleSnack(true, errorCodes[error.code] || 'Error al actualizar el Paciente', 'error')
      setInProgress(false)
    }
  } else {
    const formValsCopy = { ...formValues }
    formValsCopy.chosenDr = chosenDr
    const msgCreateErr = validateNewPatientForm(formValsCopy, user.userType, true)
    if (msgCreateErr) {
      handleSnack(true, msgCreateErr, 'error')
      return
    }
    delete formValsCopy.chosenDr

    formValsCopy.name = formValsCopy?.name?.trim() || formValsCopy?.name
    formValsCopy.email = formValsCopy?.email?.trim() || formValsCopy?.email
    formValsCopy.phone = formValsCopy?.phone?.trim() || formValsCopy?.phone
    formValsCopy.updatedBy = user.objectId

    try {
      setInProgress(true)
      let userId = null
      if (chosenDr) {
        userId = chosenDr
      }
      const newPatient = await createPatient({
        patient: formValsCopy, token: user.sessionToken, userId
      })
      handleSnack(true, 'Paciente creado correctamente', 'success')
      setInProgress(false)
      historyApp.push(`/${prefijApp}/patient-planning/${newPatient?.planning?.objectId}/medical-history`)
    } catch (error) {
      console.error(error)
      handleSnack(true, errorCodes[error.code] || 'Error al crear Paciente', 'error')
      setInProgress(false)
    }
  }
}

/**
 * form validation new/edit patient
 * @param {Object} formValues form Patient dto
 * @param {String} userRole user´s role to identify validation
 * @param {Boolean} isCreate is create or edit
 * @returns {String} error message if exists
 */
const validateNewPatientForm = (formValues, userRole = null, isCreate = false) => {
  const { name } = formValues
  let message = ''

  /**
   * validate message empty
   * @returns {Boolean} is empty
   */
  const isEmptyMessage = () => message === ''

  const fieldsToValid = [
    'name', 'countryOps'
  ]

  if (isCreate && ['SUPER_ADMIN', 'DOCTOR_MOONS'].includes(userRole)) {
    fieldsToValid.push('chosenDr')
  }

  /*
   * if (['SUPER_ADMIN', 'DOCTOR_MOONS'].includes(userRole)) {
   *   fieldsToValid.push('finalPrice', 'paidAmount')
   * }
   */

  const { invalidFields } = someEmptyProperty(formValues, fieldsToValid)

  if (isEmptyMessage() && invalidFields.length > zero) {
    const fieldNames = invalidFields.map(fieldKey => ` ${messagesCopy[fieldKey]}`)
    message = `Campos vacíos o con solo espacios en blanco: ${fieldNames}`
  }
  if (isEmptyMessage()) {
    const trimmed = name.trim()
    if (trimmed.split(' ').length < three) {
      message = 'Formato de nombre incorrecto. Debe ser: Nombre, Apellido Paterno, Apellido Materno'
    }
  }

  /*
   * if (isEmptyMessage() && !validatePhone(phone)) {
   *   message = 'Teléfono requerido a 10 dígitos'
   * }
   * if (isEmptyMessage() && !validarEmail(email)) {
   *   message = 'Debes ingresar un email válido.'
   * }
   */

  return message
}

/**
 * validate is current user is moons
 * @param {String} userRole current user´s role
 * @returns {Boolean} if moons
 */
export const checkUserMoons = (userRole) => ['SUPER_ADMIN', 'DOCTOR_MOONS'].includes(userRole)

/**
 * Handle go to new planning form
 * @param {Function} historyApp hisory function
 * @param {Function} reset useForm method set values to clean form
 * @returns {Void} handles redirection
 */
export const goToNewForm = (historyApp, reset) => {
  reset()
  historyApp.push(`/${prefijApp}/patient/new`)
}
