/* eslint-disable complexity */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, {
  useEffect, useContext, useState, useMemo
} from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import 'moment/locale/es'
import { CardMoons } from '../../../3-components/UI/CardMoons/CardMoons'
import { IconMoons } from '../../../3-components/UI/IconMoons/IconMoons'
import messages from './PlanSummaryView.messages'
import styles from './PlanSummaryView.module.scss'
import 'react-responsive-modal/styles.css'
import { C1, C2 } from '../../../9-assets/images/planningSumary'
import { Comments } from '../../../3-components/App/Comments/Comments'
import { getButtonAccept } from './PlanSummaryView.logic'
import { useForm } from '../../../4-hooks/useForm'
import { AppContext } from '../../../4-hooks/contexts/App/App.context'
import PlanningModel from '../../../6-models/App/Planning.model'
import { getPlanningData } from '../../../7-services/App/Planning.service'
import { one, zero, countryTz } from '../../../8-common/Constants'
import ReturnHistory from '../../../3-components/UI/ReturnHistory/ReturnHistory'
import { Treatment } from '../../../3-components/App/Treatment/Treatment'
import AccordionMoons from '../../../3-components/UI/AccordionMoons/AccordionMoons'
import LoaderMoons from '../../../3-components/UI/LoaderMoons/LoaderMoons'
import { getIntlNumFormat, getDateString } from '../../../8-common/UtilFunctions'
import copy from "copy-to-clipboard";
import { Dialog, DialogContent, IconButton } from '@material-ui/core'
import { usePlanningQuery } from '../../../4-hooks/quieries/usePlanningQuery'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP
const assetsUrl = process.env.REACT_APP_ASSETS_URL

/**
 * Planning Summary component
 * @returns {Void} component
 */
export const PlanSummaryView = () => {
  const { stateApp: { functions, user, historyApp } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const [isLoading, setIsLoading] = useState(false)
  const [
    formValues,
    setValues,,,
    progress,
    setInProgress
  ] = useForm(new PlanningModel())
  const {
    state,
    status,
    upperAligners,
    lowerAligners,
    treatmentMonths,
    datasheet,
    isPaid,
    videoUrl,
    deliverPromiseDate,
    paymentDate,
    approvedDate,
    patient,
    treatment,
    archformUrl
  } = formValues
  const { planningId } = useParams()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { data: dataPlanning } = usePlanningQuery(user.sessionToken, planningId)

  useEffect(() => {
    if (planningId) {
      setIsLoading(true)
      getPlanningData(user.sessionToken, planningId)
        .then(res => {
          const planning = new PlanningModel(res)
          if (planning?.deliverPromiseDate) {
            planning.deliverPromiseDate = planning?.deliverPromiseDate?.iso || null
          }
          if (planning?.paymentDate) {
            planning.paymentDate = planning?.paymentDate?.iso || null
          }
          if (planning?.approvedDate) {
            planning.approvedDate = planning?.approvedDate?.iso || null
          }
          setValues(planning)
          setIsLoading(false)
        })
        .catch(error => {
          handleSnack(true, 'Error al obtener datos de Planeación', 'error')
          setIsLoading(false)
          historyApp.push(`/${prefijApp}/home`)
        })
      return
    }
    historyApp.push(`/${prefijApp}/home`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Calculate months to end treatment
   */
  const monthsDuration = useMemo(() => {
    moment.addRealMonth = function addRealMonth(dateToModify) {
      const fm = moment(dateToModify).add(treatmentMonths || zero, 'M').tz(countryTz[patient?.countryOps] || 'America/Mexico_City')
      const fmEnd = moment(fm).endOf('month')
      return dateToModify?.date() !== fm?.date() && fm?.isSame(fmEnd?.format('YYYY-MM-DD')) ? fm?.add(one, 'd') : fm
    }

    const nextMonth = moment.addRealMonth(moment())
    const newString = moment(nextMonth).locale('es').format('MMMM YYYY')
    return newString
  }, [patient?.countryOps, treatmentMonths])

  /**
   * Calculate amount to pay by final price - paid amount
   */
  const calcLiquidation = useMemo(() => {
    const calculated = (patient?.finalPrice || zero) - (patient?.paidAmount || zero)
    return getIntlNumFormat(calculated, patient?.countryOps)
  }, [
    patient?.countryOps,
    patient?.finalPrice,
    patient?.paidAmount
  ])

  /**
   * Show approve btn
   */
  const showApprove = useMemo(() => state === 'planned' && status === '', [state, status])

  return (
    isLoading ? <LoaderMoons size={120} /> : (
      <>
        <ReturnHistory
          route={`/${prefijApp}/patients`}
          historyApp={historyApp}
          message={messages.backButton}
        />
        <CardMoons
          title={messages.title1.replace('[patient]', patient?.name)}
          actionHeader={showApprove && getButtonAccept(progress, {
            user, historyApp, planningId, handleSnack, setInProgress
          })}
        >
          {
            approvedDate && (
              <div className={styles.apprDateContainer}>
                <IconMoons
                  name={messages.iconCheck}
                  color="primary"
                />
                <p>{messages.lblApprDate.replace('[date]', getDateString(approvedDate || null, patient?.countryOps))}</p>
              </div>
            )
          }

          {
            deliverPromiseDate && (
              <div className={styles.apprDateContainer}>
                <IconMoons
                  name={messages.iconDelivery}
                  color="primary"
                />
                <p>{messages.lblDeliverDate.replace('[date]', getDateString(deliverPromiseDate || null, patient?.countryOps))}</p>
              </div>
            )
          }

          {
            paymentDate && isPaid && (
              <div className={styles.apprDateContainer}>
                <IconMoons
                  name={messages.iconPayment}
                  color="primary"
                />
                <p>{messages.lblPayDate.replace('[date]', getDateString(paymentDate || null, patient?.countryOps))}</p>
              </div>
            )
          }

          <div className={styles.gridContainer}>
            {
              archformUrl && (
                <div>
                  <div className={styles.visorImage}>
                    <img
                      id="btn_view_treatment"
                      onClick={() => setIsOpenModal(true)}
                      src={`${assetsUrl}/images/treatment-visor.webp`}
                      alt="treatment-plan"
                    />
                    <p>
                      {messages.clickPicture}
                      <span>{messages.viewTreatmentPlan}</span>
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      copy(archformUrl)
                      handleSnack(true, 'Link de tratamiento copiado', 'info')
                    }}
                  >
                    <IconMoons name={messages.iconCopy} />
                    {messages.message2}
                  </button>
                  {
                    datasheet && (
                      <a
                        href={datasheet}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <IconMoons name={messages.icon1} />
                        {messages.downloadDatasheet}
                      </a>
                    )
                  }
                </div>
              )
            }
            <div>
              <p>{messages.message1}</p>
              <p className={styles.aligners}>
                <img
                  src={C1}
                  alt="upper-aligners"
                />
                <b>{upperAligners}</b>
                {messages.message4}
              </p>
              <p className={styles.aligners}>
                <img
                  src={C2}
                  alt="lower-aligners"
                />
                <b>{lowerAligners}</b>
                {messages.message5}
              </p>
              <h3>
                {treatmentMonths}
                {' '}
                {messages.message6}
              </h3>
              <p>
                {messages.message7}
                {' '}
                <b>{monthsDuration}</b>
              </p>
            </div>
            <div className={[styles.priceContainer, styles.doubleColumn].join(' ')}>
              <h3>{messages.message8}</h3>
              <div>
                {/* <p>
                  {messages.message9}
                  {' '}
                  <i>{messages.listPriceSeverity[severity] || '$0.00'}</i>
                </p> */}
                <p>
                  {messages.message10}
                  {' '}
                  <i>{getIntlNumFormat(patient?.finalPrice, patient?.countryOps)}</i>
                </p>
                <hr />
                <p>
                  {messages.message11}
                  {' '}
                  <b>{calcLiquidation}</b>
                </p>
                {
                  showApprove
                  && getButtonAccept(progress, {
                    user, historyApp, planningId, handleSnack, setInProgress
                  })
                }
              </div>
            </div>
            <div className={[styles.doubleColumn, styles.comments].join(' ')}>
              <h3>{messages.message13}</h3>
              <Comments
                planning={dataPlanning}
                planningId={planningId}
                countryOps={patient?.countryOps}
              />
            </div>
          </div>
          <Dialog
            open={isOpenModal}
            classes={{
              paper: styles.modal,
            }}
            fullWidth
            maxWidth="lg"
          >
            <DialogContent classes={{
              root: styles.modalRoot
            }}
            >
              <IconButton
                onClick={() => setIsOpenModal(false)}
                style={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <IconMoons name="close" />
              </IconButton>
              <iframe
                src={archformUrl}
                width="100%"
                height="100%"
                frameBorder="0"
              />
            </DialogContent>
          </Dialog>
        </CardMoons>
        {
          treatment?.settings && (
            <>
              <br />
              <AccordionMoons title="Preferencias de tratamiento">
                <Treatment isSummary />
              </AccordionMoons>
            </>
          )
        }
      </>
    )
  )
}
