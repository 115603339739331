import { DoctorsView } from '../../2-views/App/Doctors/DoctorsView'
import { PatientPlanningView } from '../../2-views/App/PatientPlanning/PatientPlanningView'
import { PatientsView } from '../../2-views/App/Patients/PatientsView'
import { DoctorDetail } from '../../3-components/App/Doctor/DoctorDetail/DoctorDetail'
import { PatientDetail } from '../../3-components/App/Patient/PatientDetail/PatientDetail'
import { ProfileTypes } from './Propfiles.type'
import PlanningForm from '../../3-components/App/Planning/PlanningForm/PlanningForm'
import { ValidationsView } from '../../2-views/App/Validations/ValidationsView'
import { PlanSummaryView } from '../../2-views/App/PlanSummary/PlanSummaryView'
import ShippingForm from '../../3-components/App/Shipping/ShippingForm'
import { CreateAccountForm } from '../../3-components/Login/CreateAccountForm/CreateAccountForm'

// paths, componentes, permisos para mostrar en menu, permisos de acceso segun el rol
const APP_ROUTES = [
  {
    text: 'Lista de Doctores',
    route: '/doctors',
    icon: 'list',
    component: DoctorsView,
    profilesMenu: [
      ProfileTypes.superadmin, ProfileTypes.doctor_moons
    ],
    profiles: [
      ProfileTypes.superadmin, ProfileTypes.doctor_moons
    ]
  },
  {
    text: 'Lista de Pacientes',
    route: '/patients',
    icon: 'list',
    component: PatientsView,
    profilesMenu: [
      ProfileTypes.superadmin, ProfileTypes.doctor_moons
    ],
    profiles: [
      ProfileTypes.superadmin, ProfileTypes.doctor_moons
    ]
  },
  {
    route: '/patient-planning/:planningId/:component',
    component: PatientPlanningView,
    profilesMenu: [
    ],
    profiles: [
      ProfileTypes.doctor,
      ProfileTypes.doctor_moons,
      ProfileTypes.superadmin
    ]
  },
  {
    text: 'Nuevo paciente',
    icon: 'group_add',
    route: '/patient/new',
    component: PatientDetail,
    profilesMenu: [
      ProfileTypes.doctor,
      ProfileTypes.superadmin,
      ProfileTypes.doctor_moons
    ],
    profiles: [
      ProfileTypes.doctor,
      ProfileTypes.superadmin,
      ProfileTypes.doctor_moons
    ]
  },
  {
    text: 'Nuevo Doctor',
    icon: 'supervised_user_circle',
    route: '/doctor/new',
    component: DoctorDetail,
    profilesMenu: [
      ProfileTypes.superadmin, ProfileTypes.doctor_moons
    ],
    profiles: [
      ProfileTypes.superadmin, ProfileTypes.doctor_moons
    ]
  },
  {
    route: '/planning/form/:planningId',
    includeInMenu: false,
    component: PlanningForm,
    profilesMenu: [
    ],
    profiles: [
      ProfileTypes.superadmin, ProfileTypes.doctor_moons
    ]
  },
  {
    route: '/planning/form/new/:patientId',
    component: PlanningForm,
    profilesMenu: [
    ],
    profiles: [
      ProfileTypes.superadmin,
      ProfileTypes.doctor_moons,
      ProfileTypes.doctor
    ]
  },
  {
    text: 'Mis Pacientes',
    route: '/patients/:doctorId',
    icon: 'list',
    component: PatientsView,
    profilesMenu: [
      ProfileTypes.doctor,
    ],
    profiles: [
      ProfileTypes.superadmin,
      ProfileTypes.doctor,
      ProfileTypes.doctor_moons
    ]
  },
  {
    route: '/patient/:patientId',
    component: PatientDetail,
    profilesMenu: [
    ],
    profiles: [
      ProfileTypes.doctor,
      ProfileTypes.superadmin,
      ProfileTypes.doctor_moons
    ]
  },
  {
    route: '/doctor/:userObjId',
    component: DoctorDetail,
    profilesMenu: [
    ],
    profiles: [
      ProfileTypes.superadmin, ProfileTypes.doctor_moons
    ]
  },
  {
    route: '/planning-validation/:planningId/:view',
    component: ValidationsView,
    profilesMenu: [],
    profiles: [
      ProfileTypes.superadmin, ProfileTypes.doctor_moons
    ]
  },
  {
    route: '/planning-sumary/:planningId',
    component: PlanSummaryView,
    profilesMenu: [],
    profiles: [
      ProfileTypes.superadmin,
      ProfileTypes.doctor_moons,
      ProfileTypes.doctor
    ]
  },
  {
    route: '/planning/:planningId/edit/shipping/:shippingId',
    includeInMenu: false,
    component: ShippingForm,
    profilesMenu: [
    ],
    profiles: [
      ProfileTypes.superadmin, ProfileTypes.doctor_moons
    ]
  },
  {
    route: '/user/:userObjId',
    component: CreateAccountForm,
    profilesMenu: [
    ],
    profiles: [
      ProfileTypes.superadmin,
      ProfileTypes.doctor_moons,
      ProfileTypes.doctor
    ]
  },
  {
    text: 'Nuevo Admin',
    icon: 'assignment_ind',
    route: '/admin/new',
    component: CreateAccountForm,
    profilesMenu: [ProfileTypes.superadmin],
    profiles: [ProfileTypes.superadmin]
  },
  {
    text: 'Lista de Doctores Futura',
    route: '/doctors-moons',
    icon: 'list',
    component: DoctorsView,
    profilesMenu: [ProfileTypes.superadmin, ProfileTypes.doctor_moons],
    profiles: [ProfileTypes.superadmin, ProfileTypes.doctor_moons]
  },
  {
    text: 'Lista de Admins',
    route: '/admins-moons',
    icon: 'list',
    component: DoctorsView,
    profilesMenu: [ProfileTypes.superadmin],
    profiles: [ProfileTypes.superadmin]
  }
]

export default APP_ROUTES

// diagnose
