/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import styles from './ButtonMoons.module.scss'

/**
 * Button moons reusable component
 * @param {*} param0 .
 * @param {*} param0.isprogress progress flag
 * @param {*} param0.children to wrap
 * @param {*} param0.className styles
 * @param {*} param0.color material color
 * @param {*} param0.variant material variant
 * @returns {Void} component
 */
export const ButtonMoons = ({
  isprogress, children, className, color = 'primary', variant = 'contained', ...rest
}) => (
  <Button
    disabled={!!isprogress}
    {...rest}
    disableElevation
    color={color}
    variant={variant}
    className={`${styles[className]} ${styles.buttonMoons}`}
  >
    {children}
    {
      !!isprogress && (
        <CircularProgress
          size={20}
          variant="indeterminate"
          className={styles.spinner}
        />
      )
    }

  </Button>
)
