/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import {
  AppBar, Drawer, Menu, MenuItem, Toolbar
} from '@material-ui/core'
import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { IconButtonMoons } from '../IconButtonMoons/IconButtonMoons'
import { IconMoons } from '../IconMoons/IconMoons'
import styles from './NavBarMoons.module.scss'
import futuraLogo from '../../../9-assets/images/futura_logo.svg'

/**
 * NavBarMoons reusable component
 * @param {*} props .
 * @returns {Void} component
 */
export const NavBarMoons = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [showLinks, setShowLinks] = useState(false)
  const { menuAccount, links, account } = props

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleClickAction = useCallback((itemAction) => {
    itemAction()
    handleClose()
  }, [handleClose])

  return (
    <>
      <AppBar className={styles.navBarContainer}>
        <Toolbar>
          <IconButtonMoons
            edge="start"
            aria-label="menu"
            icon="menu"
            onClick={() => setShowLinks(true)}
          />
          <h3>
            <Link to="/home">
              <img
                className={styles.menuLogo}
                src={futuraLogo}
                alt="Moons"
              />
            </Link>

          </h3>
          <p className={styles.nameBar}>{account.name}</p>
          <IconButtonMoons
            edge="start"
            aria-label="account_circle"
            icon="account_circle"
            onClick={(event) => (menuAccount ? setAnchorEl(event.currentTarget) : null)}
          />
          {
            menuAccount && (
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <div className={styles.userInfo}>
                  <div>
                    <IconMoons name="account_circle" />
                  </div>
                  <div>
                    <h3>{account.name}</h3>
                    <span>
                      {account.email}
                      {' '}
                    </span>
                    {/* <span>{account.role}</span> */}
                  </div>
                </div>
                <hr />
                {
                  menuAccount.map((menuItem, index) => (
                    <MenuItem
                      key={`menu-item-${menuItem.text || menuItem.icon || index}`}
                      onClick={() => handleClickAction(menuItem.action)}
                    >
                      { menuItem.icon && <IconMoons name={menuItem.icon} />}
                      {menuItem.text}
                    </MenuItem>
                  ))
                }
              </Menu>
            )
          }
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={showLinks}
        onClose={() => setShowLinks(false)}
        className={styles.containerLinks}
      >
        <h1>
          Menú
        </h1>
        {
          links.map((link, index) => (
            <Link
              className={styles.links}
              key={`link-item -${link.text}`}
              to={link.route}
              onClick={() => setShowLinks(false)}
            >
              {
                (link.icon && <IconMoons name={link.icon} />)
                || <IconMoons name="navigate_next" />
              }
              {link.text}
            </Link>
          ))
        }
      </Drawer>
    </>
  )
}

NavBarMoons.propTypes = {
  menuAccount: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    action: PropTypes.func
  })).isRequired,
  account: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    route: PropTypes.string
  })).isRequired
}
