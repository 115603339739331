/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import { FormWrapper } from '../../../3-components/Login/FormWrapper/FormWrapper'
import { RecoveryForm } from '../../../3-components/Login/RecoveryForm/RecoveryForm'

/**
 * Recovery view
 * @returns {Void} component
 */
export const RecoveryView = () => (
  <FormWrapper classContainer="signInContainer">
    <RecoveryForm />
  </FormWrapper>
)
