/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable import/prefer-default-export */
import { fifty } from '../../../8-common/Constants'

const typesData = 'string' || 'number' || 'date' || 'dateTime'
const gridAlignmentData = 'left' || 'right' || 'center'

/**
 * class column data model
 */
export class ColumnDataTableModel {
  /**
   * constructor
   * @param {Object} object object data
   */
  constructor(object = {}) {
    this.field = object.field || ''
    this.headerName = object.headerName || ''
    this.width = object.width || fifty
    this.sortable = object.sortable || true
    this.disableColumnMenu = object.disableColumnMenu || false
    this.hideSortIcons = object.hideSortIcons || false
    this.renderCell = object.renderCell || (() => null)
    this.flex = object.flex || null
    this.hide = object.hide || false
    this.type = object.type || typesData
    this.align = object.align || gridAlignmentData
    this.headerAlign = object.headerAlign || gridAlignmentData
    this.disableClickEventBubbling = object.disableClickEventBubbling || false
    this.filterable = object.filterable || true
    this.cellClassName = object.cellClassName || '' || []
    this.headerClassName = object.headerClassName || '' || []
  }
}
