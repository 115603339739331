/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable */
import React, {
  useContext, useEffect, useState, useMemo
} from 'react'
import { useParams, Link } from 'react-router-dom'
import { AppContext } from '../../../../4-hooks/contexts/App/App.context'
import { useForm } from '../../../../4-hooks/useForm'
import Planning from '../../../../6-models/App/Planning.model'
import {
  handleSubmitForm, calculateTreatment, goToNewForm, fetchData, validateArchFormUrl,
  validateArchFormDelimitedModelUrl,
  compressToZip,
  assignPlanning
} from './PlanningForm.logic'
import { ButtonMoons, InputMoons } from '../../../UI'
import { SelectMoons } from '../../../UI/SelectMoons/SelectMoons'
import { DatePickerMoons } from '../../../UI/DatePickerMoons/DatePickerMoons'
import { IconButtonMoons } from '../../../UI/IconButtonMoons/IconButtonMoons'
import SwitchMoons from '../../../UI/SwitchMoons/SwitchMoons'
import messages from './PlanningForm.messages'
import styles from './PlanningForm.module.scss'
import { CardMoons } from '../../../UI/CardMoons/CardMoons'
import { DragAndDropMoons } from '../../../UI/DragAndDropMoons/DragAndDropMoons'
import { TooltipMoons } from '../../../UI/TooltipMoons/TooltipMoons'
import { ValidatePictures } from '../../ValidatePictures/ValidatePictures'
import { PicturesPatientModel } from '../../../../6-models/App/PicturesPatient.model'
import { getArrayFile } from '../../../../2-views/App/Validations/ValidationsView.logic'
import { getPatientData } from '../../../../7-services/App/Patient.service'
import ReturnHistory from '../../../UI/ReturnHistory/ReturnHistory'
import LoaderMoons from '../../../UI/LoaderMoons/LoaderMoons'
import PlanningStates from './model/PlanningStates'
import InfoTabs from './InfoTabs/InfoTabs'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * PlanningForm component
 * Form for new and edit planning. Use for admins to edit Planning data
 * @returns {Void} component
 */
const PlanningForm = () => {
  const { stateApp: { functions, user, historyApp } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const [isLoading, setIsLoading] = useState(false)
  const [patientObjId, setPatientObjId] = useState('')
  const [patientName, setPatientName] = useState('')
  const [fileDatasheet, setFileDatasheet] = useState(null)
  const [fileVideo, setFileVideo] = useState(null)
  const [fileStl, setFileStl] = useState(null)
  const [prodStl, setProdStl] = useState(null)
  const [lowerArchFileStl, setLowerArchFileStl] = useState(null)
  const [firstOcclusionFileStl, setFirstOcclusionFileStl] = useState(null)
  const [secondOcclusionfileStl, setSecondOcclusionFileStl] = useState(null)
  const [imageList, setImageList] = useState([])
  const [patientCustomerId, setCustomerId] = useState('')
  const [shippingId, setShippingId] = useState('')
  const [
    formValues,
    setValues,
    handleInputChange,
    reset,
    progress,
    setInProgress, ,
    handleDatePickerChange,
    handleSwitchChange
  ] = useForm(new Planning())
  const {
    state,
    status,
    alignersRange,
    upperAligners,
    lowerAligners,
    patientType,
    severity,
    patientNotes,
    doctorNotes,
    datasheet,
    isPaid,
    stlMethod,
    active,
    videoUrl,
    deliverPromiseDate,
    paymentDate,
    approvedDate,
    patient,
    archformUrl,
    diagnoseNotes,
    delimitedModelUrl,
    prodStlFileUrl,
    stlFileUrl,
    stlLowerArchFileUrl,
    stlFirstOcclusionFileUrl,
    stlSecondOcclusionFileUrl
  } = formValues
  const { planningId, patientId } = useParams()

  useEffect(() => {
    if (planningId) {
      setIsLoading(true)

      let planningData = null
      fetchData(user.sessionToken, planningId)
        .then((res) => {
          planningData = res
          const planning = new Planning(planningData)
          const { patient: { customerId, name } } = planning
          planning.deliverPromiseDate = planning?.deliverPromiseDate?.iso || null
          planning.paymentDate = planning?.paymentDate?.iso || null
          planning.approvedDate = planning?.approvedDate?.iso || null
          setCustomerId(customerId)
          setValues(planning)
          setPatientObjId(planning?.patient?.objectId)
          setPatientName(name)
          const { files } = planning
          const filesObj = new PicturesPatientModel(files)
          const listFiles = getArrayFile(filesObj)
          setImageList(listFiles)
          const { shipping: { objectId } } = planning
          setShippingId(objectId || '')
          setIsLoading(false)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('error fetching planning data for update: ', error)
          setIsLoading(false)
        })
      return
    }
    if (patientId) {
      setIsLoading(true)
      setPatientObjId(patientId)
      getPatientData(user.sessionToken, patientId)
        .then(res => {
          const { name } = res
          setPatientName(name)
          setIsLoading(false)
        })
        .catch(error => {
          setIsLoading(false)
        })
    } else {
      historyApp.push(`/${prefijApp}/patients`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const calcTreat = useMemo(() => calculateTreatment(upperAligners, lowerAligners), [
    upperAligners, lowerAligners
  ])

  /**
   * get options list for status by state
   */
  const statusOptions = useMemo(() => {
    const selectedState = PlanningStates.find(planningState => planningState.value === state)
    if (selectedState && selectedState.status) {
      return selectedState.status
    }
    return []
  }, [state])

  useEffect(() => {
    const containsTheOption = statusOptions.find(option => option.value === status)
    if (!status || !containsTheOption) handleInputChange({ target: { name: 'status', value: statusOptions.length > 0 ? statusOptions[0].value : null } })
  }, [statusOptions, state])

  const handleProdStlsUpload = async (files) => {
    try {
      const folderName = patient.name.replaceAll(' ', '_')
      const compressedFile = await compressToZip({ files, folderName })
      setProdStl(compressedFile)
    } catch (error) {
      console.error(error)
      handleSnack(true, 'Error al subir los STLs', 'error')
    }
  }

  return (
    isLoading ? <LoaderMoons size={120} /> : (
      <>
        <div className={[styles.gridContainer, styles.header].join(' ')}>
          <div className={styles.regionBtnBack}>
            <ReturnHistory
              route={`/${prefijApp}/patients`}
              historyApp={historyApp}
              message={messages.backButton}
            />
          </div>

          {
            planningId && (
              <div className={styles.regionNewPlanning}>
                <ButtonMoons
                  size="small"
                  onClick={() => goToNewForm(patientObjId, historyApp, reset)}
                >
                  {messages.btnNewPlann}
                </ButtonMoons>
              </div>
            )
          }
        </div>
        <form
          onSubmit={
            (event) => handleSubmitForm(
              event,
              {
                formValues,
                user,
                historyApp,
                fileDatasheet,
                fileVideo,
                fileStl,
                prodStl,
                lowerArchFileStl,
                firstOcclusionFileStl,
                secondOcclusionfileStl,
                customerId: patientCustomerId,
                planningId,
                calcTreat,
                patientObjId
              },
              {
                reset, handleSnack, setInProgress, historyApp
              }
            )
          }
        >
          <CardMoons
            title={planningId ? messages.h1Edit.replace('[patient]', patientName) : messages.h1New.replace('[patient]', patientName)}
            actions={(
              <>
                <ButtonMoons
                  variant="contained"
                  color="secundary"
                  isprogress={Number(progress)}
                  onClick={() => assignPlanning(user, planningId, handleSnack, setInProgress)}
                >
                  Asignarme el caso
                </ButtonMoons>
                {
                  shippingId && (
                    <Link to={`/${prefijApp}/planning/${planningId}/edit/shipping/${shippingId}`}>
                      <ButtonMoons size="small">
                        {messages.btnEditShipping}
                      </ButtonMoons>
                    </Link>
                  )
                }
                <Link to={`/${prefijApp}/doctor/${patient?.user?.objectId}`}>
                  <ButtonMoons size="small">
                    {messages.btnEditDoctor}
                  </ButtonMoons>
                </Link>
                <Link to={`/${prefijApp}/patient/${patient?.objectId}`}>
                  <ButtonMoons size="small">
                    {messages.btnEditPatient}
                  </ButtonMoons>
                </Link>
                {
                  planningId && (
                    <Link to={`/${prefijApp}/planning-sumary/${planningId}`}>
                      <ButtonMoons size="small">
                        {messages.btnPlanningSummary}
                      </ButtonMoons>
                    </Link>
                  )
                }
                <ButtonMoons
                  variant="contained"
                  color="primary"
                  type="submit"
                  isprogress={Number(progress)}
                >
                  {planningId ? messages.btnEdit : messages.btnNew}
                </ButtonMoons>
              </>
            )}
          >

            <div className={styles.gridContainer}>

              <SelectMoons
                name="state"
                items={PlanningStates}
                label={messages.lblState}
                value={state}
                onChange={handleInputChange}
              />

              <SelectMoons
                name="status"
                items={statusOptions}
                label={messages.lblStatus}
                value={status}
                onChange={handleInputChange}
                disabled={!statusOptions.length > 0}
              />

              <SelectMoons
                name="alignersRange"
                items={messages.alignersRange}
                label={messages.phAlignersRange}
                value={alignersRange}
                onChange={handleInputChange}
              />

              <InputMoons
                type="number"
                name="upperAligners"
                autoComplete="off"
                placeholder={messages.phUpperAlign}
                value={upperAligners}
                onChange={handleInputChange}
              />

              <InputMoons
                type="number"
                name="lowerAligners"
                autoComplete="off"
                placeholder={messages.phLowerAlign}
                value={lowerAligners}
                onChange={handleInputChange}
              />

              <InputMoons
                type="text"
                name="treatmentMonths"
                autoComplete="off"
                placeholder={messages.phTreatMonths}
                value={calcTreat}
                disabled
              />

              <SelectMoons
                name="patientType"
                items={messages.patientType}
                label={messages.lblSeverity}
                value={patientType}
                onChange={handleInputChange}
              />

              <SelectMoons
                name="severity"
                items={messages.severity}
                label={messages.lblPatientType}
                value={severity}
                onChange={handleInputChange}
              />

              <InputMoons
                type="text"
                name="patientNotes"
                placeholder={messages.phPatientNotes}
                autoComplete="off"
                value={patientNotes}
                onChange={handleInputChange}
                multiline
              />

              <InputMoons
                type="text"
                name="doctorNotes"
                placeholder={messages.phDoctorNotes}
                autoComplete="off"
                value={doctorNotes}
                onChange={handleInputChange}
                multiline
              />

              <InputMoons
                type="text"
                name="diagnoseNotes"
                placeholder={messages.phDiagnoseNotes}
                autoComplete="off"
                value={diagnoseNotes}
                onChange={handleInputChange}
                multiline
              />

              <SelectMoons
                name="stlMethod"
                items={messages.stlMethod}
                label="Método de recolección STL"
                value={stlMethod}
                onChange={handleInputChange}
              />

              <DatePickerMoons
                label={messages.phDeliveryDate}
                name="deliverPromiseDate"
                value={deliverPromiseDate}
                onChange={handleDatePickerChange}
              />

              <div className={styles.gridContainer}>
                <SwitchMoons
                  name="isPaid"
                  checked={isPaid}
                  onChange={handleSwitchChange}
                  label={messages.lblIsPaid}
                  color="primary"
                />

                <SwitchMoons
                  name="active"
                  checked={active}
                  onChange={handleSwitchChange}
                  label={messages.lblActive}
                  color="primary"
                />
              </div>

              <DatePickerMoons
                label={messages.phPaymentDate}
                name="paymentDate"
                value={paymentDate}
                onChange={handleDatePickerChange}
                showTime
                timeLabel={messages.lblTimePayment}
              />

              <DatePickerMoons
                label={messages.phApprovedDate}
                name="approvedDate"
                value={approvedDate}
                onChange={handleDatePickerChange}
                showTime
                timeLabel={messages.lblTimeAppr}
              />

              <div className={styles.externalLink}>
                <InputMoons
                  type="text"
                  name="delimitedModelUrl"
                  placeholder={messages.phDelimitedModelUrl}
                  autoComplete="off"
                  value={delimitedModelUrl}
                  onChange={handleInputChange}
                />
                {
                  (delimitedModelUrl && validateArchFormDelimitedModelUrl(delimitedModelUrl)) &&
                  <a
                      href={delimitedModelUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.downloadButton}
                  >
                    <IconButtonMoons
                      aria-label="open_in_new"
                      icon="open_in_new"
                      color="primary"
                    />
                  </a>
                }
              </div>

              <div className={styles.externalLink}>
                <InputMoons
                  type="text"
                  name="archformUrl"
                  placeholder={messages.pharchformUrl}
                  autoComplete="off"
                  value={archformUrl}
                  onChange={handleInputChange}
                />
                {
                  (archformUrl && validateArchFormUrl(archformUrl)) &&
                  <a
                      href={archformUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.downloadButton}
                  >
                    <IconButtonMoons
                      aria-label="open_in_new"
                      icon="open_in_new"
                      color="primary"
                    />
                  </a>
                }
              </div>

              <div className={styles.filePickers}>
                <DragAndDropMoons
                  handledFiles={(files, event) => setFileDatasheet(files[0])}
                  maxFiles={messages.maxFilesDatash}
                  placeholder={messages.phFileTechnicalDatasheet}
                  showAcceptedFiles
                  typeFiles=".pdf,.zip"
                />
                {
                  datasheet && (
                    <TooltipMoons
                      title="Ver y Descargar"
                      placement="left"
                    >
                      <a
                        href={datasheet}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.downloadButton}
                      >
                        <IconButtonMoons
                          edge="start"
                          aria-label="download-file"
                          icon="cloud_download"
                          color="primary"
                        />
                      </a>
                    </TooltipMoons>
                  )
                }
              </div>

              <div className={styles.filePickers}>
                <DragAndDropMoons
                  handledFiles={(files, event) => setFileVideo(files[0])}
                  maxFiles={messages.maxFilesVideo}
                  placeholder={messages.phFileVideo}
                  showAcceptedFiles
                  typeFiles=".pdf,.zip,video/*"
                />
                {
                  videoUrl && (
                    <TooltipMoons
                      title="Ver y Descargar"
                      placement="left"
                    >
                      <a
                        href={videoUrl}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.downloadButton}
                      >
                        <IconButtonMoons
                          edge="start"
                          aria-label="download-file"
                          icon="cloud_download"
                          color="primary"
                        />
                      </a>
                    </TooltipMoons>
                  )
                }
              </div>

              <div className={styles.filePickers}>
                <DragAndDropMoons
                  handledFiles={(files, event) => setFileStl(files[0])}
                  maxFiles={messages.maxFileStl}
                  placeholder={messages.phFileStl}
                  showAcceptedFiles
                  typeFiles=".obj,.stl"
                />
                {
                  stlFileUrl && (
                    <TooltipMoons
                      title="Ver y Descargar"
                      placement="left"
                    >
                      <a
                        href={stlFileUrl}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.downloadButton}
                      >
                        <IconButtonMoons
                          edge="start"
                          aria-label="download-file"
                          icon="cloud_download"
                          color="primary"
                        />
                      </a>
                    </TooltipMoons>
                  )
                }
              </div>

              <div className={styles.filePickers}>
                <DragAndDropMoons
                  handledFiles={(files, event) => setLowerArchFileStl(files[0])}
                  maxFiles={messages.maxFileStl}
                  placeholder={messages.phLowerArchFileStl}
                  showAcceptedFiles
                  typeFiles=".obj,.stl"
                />
                {
                  stlLowerArchFileUrl && (
                    <TooltipMoons
                      title="Ver y Descargar"
                      placement="left"
                    >
                      <a
                        href={stlLowerArchFileUrl}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.downloadButton}
                      >
                        <IconButtonMoons
                          edge="start"
                          aria-label="download-file"
                          icon="cloud_download"
                          color="primary"
                        />
                      </a>
                    </TooltipMoons>
                  )
                }
              </div>

              <div className={styles.filePickers}>
                <DragAndDropMoons
                  handledFiles={(files, event) => setFirstOcclusionFileStl(files[0])}
                  maxFiles={messages.maxFileStl}
                  placeholder={messages.phFirstOcclusionFileStl}
                  showAcceptedFiles
                  typeFiles=".obj,.stl"
                />
                {
                  stlFirstOcclusionFileUrl && (
                    <TooltipMoons
                      title="Ver y Descargar"
                      placement="left"
                    >
                      <a
                        href={stlFirstOcclusionFileUrl}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.downloadButton}
                      >
                        <IconButtonMoons
                          edge="start"
                          aria-label="download-file"
                          icon="cloud_download"
                          color="primary"
                        />
                      </a>
                    </TooltipMoons>
                  )
                }
              </div>

              <div className={styles.filePickers}>
                <DragAndDropMoons
                  handledFiles={(files, event) => setSecondOcclusionFileStl(files[0])}
                  maxFiles={messages.maxFileStl}
                  placeholder={messages.phSecondOcclusionFileStl}
                  showAcceptedFiles
                  typeFiles=".objs,.stl"
                />
                {
                  stlSecondOcclusionFileUrl && (
                    <TooltipMoons
                      title="Ver y Descargar"
                      placement="left"
                    >
                      <a
                        href={stlSecondOcclusionFileUrl}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.downloadButton}
                      >
                        <IconButtonMoons
                          edge="start"
                          aria-label="download-file"
                          icon="cloud_download"
                          color="primary"
                        />
                      </a>
                    </TooltipMoons>
                  )
                }
              </div>

              <div className={styles.filePickers}>
                <DragAndDropMoons
                  handledFiles={handleProdStlsUpload}
                  placeholder={messages.prodFileStl}
                  showAcceptedFiles
                  typeFiles=".stl,.obj"
                  maxFiles={100}
                />
                {
                  prodStlFileUrl && (
                    <TooltipMoons
                      title="Ver y Descargar"
                      placement="left"
                    >
                      <a
                        href={prodStlFileUrl}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.downloadButton}
                      >
                        <IconButtonMoons
                          edge="start"
                          aria-label="download-file"
                          icon="cloud_download"
                          color="primary"
                        />
                      </a>
                    </TooltipMoons>
                  )
                }
              </div>
            </div>

          </CardMoons>

          <br />
          <br />
        </form>
        <InfoTabs
          patient={patient}
          imageList={imageList}
          progress={progress}
          status={status}
          setInProgress={setInProgress}
          setValues={setValues}
        />
      </>
    )
  )
}

export default PlanningForm
