const PatientPlanningViewMessages = {
  buttonFinish: 'Finalizar',
  titles: [
    'Historial Médico',
    'Tratamiento',
    'Fotos y RX',
    'Recolección'
  ],
  medicalHistory: 'medical-history',
  treatment: 'treatment',
  picturesPatient: 'pictures-patient',
  collectionMethod: 'collection-method'

}

export default PatientPlanningViewMessages
