/* eslint-disable no-process-env */
/* eslint-disable max-statements */
/* eslint-disable no-useless-escape */
import moment from 'moment-timezone'
import lodash from 'lodash'
import {
  zero, ten, five, countryTz
} from './Constants'

/**
 * check if empty needed property
 * @param {*} _object form data object
 * @param {*} requiredFields list of validation fields
 * @returns {Boolean} it's valid
 */
export const someEmptyProperty = (_object = {}, requiredFields = []) => {
  const invalidFields = []
  if (requiredFields.length > zero) {
    requiredFields.forEach(key => {
      if (key in _object) {
        switch (_object[key]) {
        case '':
          invalidFields.push(key)
          break

        case null:
          invalidFields.push(key)
          break

        default:
          break
        }
        if (!(/^$|.*\S+.*/).test(_object[key])) {
          invalidFields.push(key)
        }
      } else {
        invalidFields.push(key)
      }
    })
  }
  const checkSome = Object.values(_object).some(vulue => typeof vulue === 'undefined' || vulue === null || vulue === '')
  return { invalidFields, someInvalidProperty: checkSome }

  /*
   * if (noValidate.length > zero) {
   *   console.log('in to validete');
   *   for (const key in _object) {
   *     console.log('key check: ', key);
   *     if (noValidate.find(fieldKey => fieldKey === key)) {
   *       console.log('found key in array validate: ', key);
   *       if (!_object[key]) {
   *         console.log('key invalid: ', key);
   *         return true
   *       }
   *     }
   *   }
   * }
   * const checkSome = Object.values(_object)
   * .some(vulue => typeof vulue === 'undefined' || vulue === null || vulue === '')
   * console.log('check some', checkSome);
   * return checkSome
   */
}

/**
 * validate route from login
 * @returns {Boolean} is valid route
 */
export const isRouteLogin = () => {
  const { pathname } = window.location
  return pathname.includes('login')
      || pathname.includes('recovery')
      || pathname.includes('update-password')
      || pathname.includes('create-account')
}

/**
 * validates email format
 * @param {*} email input email
 * @returns {Boolean} is valid
 */
export const validarEmail = (email) => (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i).test(email)

/**
 * scroll top
 * @param {*} idElement .
 * @returns {Void} scroll
 */
export const scrollTopInit = (idElement) => {
  const homeContainer = document.getElementById(idElement)
  homeContainer.scroll(zero, zero)
}

/**
 * validates phone number
 * @param {String} phone user's phone number
 * @returns {Boolean} valid or not
 */
export const validatePhone = (phone) => {
  let formatted = phone
  formatted = formatted.replace(/^(\+)|\D/g, '')
  if ((/^\d+$/).test(formatted)) {
    const tenDig = formatted.substr(formatted.length - ten)
    if (tenDig.length !== ten) {
      return false
    }
    if (!(/^(\d)\1{9}$/).test(tenDig)) {
      return true
    }
    return false
  }
  return false
}

/**
 * alidate zip code
 * @param {String} zipCode zip code
 * @returns {Boolean} is valid
 */
export const validateZip = (zipCode) => {
  if (zipCode.length === five) {
    return (/^\d*$/).test(zipCode)
  }
  return false
}

/**
 * c
 * @param {*} formData .
 * @param {*} serviceData .
 * @returns {Void} c
 */
export const compareObjects = (formData, serviceData) => lodash.isEqual(formData, serviceData)

/**
 * get Intl.NumberFormat
 * @param {Number} numValue num to format
 * @param {String} country country ops
 * @returns {String} currency
 */
export const getIntlNumFormat = (numValue, country) => {
  let numAux = numValue
  if (typeof numValue === 'undefined' || numValue === null) {
    numAux = zero
  }
  switch (country) {
  case 'CO':
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(numAux)

  default: return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(numAux)
  }
}

/**
 * get string format from date
 * @param {Object} dateValue date to format
 * @param {String} countryOps to define tz
 * @returns {String} formatted date
 */
export const getDateString = (dateValue, countryOps) => moment(dateValue)
  .tz(countryTz[countryOps] || 'America/Mexico_City').format('DD/MM/YYYY')

/**
 * @param {Object} dateValue date to format
 * @param {String} countryOps to define tz
 * @returns {String} formatted date
 */
export const getDatetimeString = (dateValue, countryOps) => moment(dateValue)
  .tz(countryTz[countryOps] || 'America/Mexico_City').format('DD/MM/YYYY HH:mm')

/**
 * get timezone by country
 * @param {String} countryOps country
 * @returns {String} timezone
 */
export const getTimezone = (countryOps) => {
  switch (countryOps) {
  case 'CO': return 'America/Bogota'

  default: return 'America/Mexico_City'
  }
}

/**
 * Create a Basic Authorization Header
 * @param {*} _user manual or env basic auth user
 * @param {*} _password manual or env basic auth password
 * @returns {Object} {Authorization}
 */
export const basicAuthHeader = (_user = null, _password = null) => {
  const user = _user ?? process.env.REACT_APP_BASIC_AUTH_USERNAME
  const password = _password ?? process.env.REACT_APP_BASIC_AUTH_PASSWORD
  const auth = `${user}:${password}`
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`
  return {
    Authorization: authEncoded
  }
}
