/* eslint-disable no-process-env */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-no-literals */
import React, { useCallback, useContext } from 'react'
import { AppContext } from '../../../../../4-hooks/contexts/App/App.context'
import messages from './StlAppointment.messages'
import styles from './StlAppointment.module.scss'
import { ButtonMoons } from '../../../../UI'
import { zero } from '../../../../../8-common/Constants'
import handleRequestAppmnt from './StlAppointment.logic'

const isProd = process.env.REACT_APP_IS_PROD === '1'
const acuityUrl = process.env.REACT_APP_ACUITY_BOOK_URL

/**
 * STL method appointment
 * @param {String} planningId Planning obejctId to relate to when create appointment
 * @param {Object} patientData Patient's data
 * @returns {Void} component
 */
export const StlAppointment = ({ planningId, patientData }) => {
  const { stateApp: { user, historyApp } } = useContext(AppContext)
  const getCategory = useCallback((countryOps) => {
    switch (countryOps) {
    case 'CO': return 'Colombia'

    default: return 'Mexico'
    }
  }, [])

  const firstName = patientData?.name?.split(' ')[0]
  const lastName = patientData?.name?.split(' ')?.filter((elem, index) => index !== zero).join(' ')
  const appmntTypeCat = isProd ? getCategory(patientData?.countryOps) : 'development'
  const finalUrl = acuityUrl.replace('[patientFirstName]', firstName)
    .replace('[patientLastName]', lastName)
    .replace('[patientPhone]', patientData?.phone || '')
    .replace('[patientEmail]', patientData?.email || '')
    .replace('[patientCustomerId]', patientData?.customerId || '')
    .replace('[appmntTypeCat]', appmntTypeCat)
    .replace('[planningId]', planningId)
    .replace('[sessionToken]', user.sessionToken)

  return (
    <div className={styles.gridContainer}>
      <h3>{messages.bookingMsg}</h3>
      <a
        href={finalUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ButtonMoons
          variant="outlined"
          color="primary"
          type="button"
          onClick={() => handleRequestAppmnt({ planningId, user, historyApp })}
        >
          {messages.message2}
        </ButtonMoons>
      </a>
    </div>
  )
}
