/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-literals */
import { ColumnDataTableModel } from '../../../UI/DataTableMoons/ColumnDataTable.model'
import { renderCellActions } from './DoctorList.logic'

const DoctorListMessages = {
  title: '[typeList]',
  noRowsMsg: 'Aún no hay doctores, agrega uno en este ícono de la esquina superior derecha',
  columnsTable: [
    new ColumnDataTableModel({
      field: 'name',
      headerName: 'Nombre Doctor',
      flex: 1,
      cellClassName: 'textCapitalize'
    }),
    new ColumnDataTableModel({
      field: 'email',
      headerName: 'Correo',
      flex: 1
    }),
    new ColumnDataTableModel({
      field: 'phone',
      headerName: 'Teléfono',
      width: 130,
    }),
    new ColumnDataTableModel({
      field: 'actions',
      headerName: 'Acciones',
      sortable: false,
      hideSortIcons: true,
      width: 160,
      disableColumnMenu: true,
      renderCell: renderCellActions
    })
  ],
}

export default DoctorListMessages
