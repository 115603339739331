/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, {
  useContext, useState, useEffect, useMemo
} from 'react'
import { useParams } from 'react-router-dom'
import { AppContext } from '../../../../4-hooks/contexts/App/App.context'
import { useForm } from '../../../../4-hooks/useForm'
import { PatientDto } from '../../../../6-models/App/Patient.dto'
import { ButtonMoons, InputMoons } from '../../../UI'
import { IconButtonMoons } from '../../../UI/IconButtonMoons/IconButtonMoons'
import { SelectMoons } from '../../../UI/SelectMoons/SelectMoons'
import { CardMoons } from '../../../UI/CardMoons/CardMoons'
import { handleSubmitForm, checkUserMoons, goToNewForm } from './PatientDetail.logic'
import { getPatientData } from '../../../../7-services/App/Patient.service'
import { getAllUsersByRole } from '../../../../7-services/App/Doctor.service'
import { OptionModelSelectMoons } from '../../../UI/SelectMoons/Option.model'
import { compareObjects } from '../../../../8-common/UtilFunctions'
import LoaderMoons from '../../../UI/LoaderMoons/LoaderMoons'
import messages from './PatientDetail.messages'
import styles from './PatientDetail.module.scss'
import { ROLES } from '../../../../8-common/Constants'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * Patient detial create/edit form
 * @param {*} param0 .
 * @returns {Void} component
 */
export const PatientDetail = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { stateApp: { functions, user, historyApp } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const [
    formValues,
    setValues,
    handleInputChange,
    reset,
    progress,
    setInProgress
  ] = useForm(new PatientDto())
  const {
    name,
    finalPrice,
    paidAmount,
    state,
    status
  } = formValues
  const { patientId } = useParams()
  const [doctors, setDoctors] = useState([])
  const [chosenDr, setChosenDr] = useState('')
  const [serviceData, setServiceData] = useState({})

  useEffect(() => {
    if (patientId) {
      setIsLoading(true)
      getPatientData(user.sessionToken, patientId)
        .then(res => {
          const patient = new PatientDto(res)
          if (res?.user?.objectId) setChosenDr(res?.user?.objectId)
          setValues(patient)
          setServiceData(patient)
          setIsLoading(false)
        })
        .catch(error => {
          setIsLoading(false)
        })
    }
    getAllUsersByRole(ROLES.doctor)
      .then(doctorsList => {
        const formatDrs = doctorsList.map(drItem => new OptionModelSelectMoons({
          text: drItem.name,
          value: drItem.objectId
        }))
        setDoctors(formatDrs)
      })
      .catch(error => {
        setDoctors([])
      })
    handleInputChange({ target: { name: 'countryOps', value: user.countryOps } })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showAdminFields = useMemo(() => checkUserMoons(user.userType), [user.userType])

  return (
    isLoading ? <LoaderMoons size={120} /> : (
      <>
        <div className={[styles.gridContainer, styles.header].join(' ')}>
          <div className={styles.regionBtnBack}>
            <IconButtonMoons
              edge="start"
              aria-label="back_history"
              icon="arrow_back"
              color="primary"
              onClick={() => historyApp.push(`/${prefijApp}/home`)}
            />
            <p>{messages.backButton}</p>
          </div>

          {
            patientId && (
              <div className={styles.regionNewPlanning}>
                <ButtonMoons
                  size="small"
                  onClick={() => goToNewForm(historyApp, reset)}
                >
                  {messages.FormH1}
                </ButtonMoons>
              </div>
            )
          }
        </div>

        <form
          onSubmit={(evt) => handleSubmitForm(
            evt,
            {
              formValues, user, historyApp, patientId, chosenDr
            },
            { handleSnack, setInProgress }
          )}
        >

          <CardMoons
            title={patientId ? messages.h1Edit.replace('[name]', name) : messages.FormH1}
            actions={(
              <ButtonMoons
                variant="contained"
                color="primary"
                type="submit"
                isprogress={Number(progress)}
              >
                {patientId ? messages.btnEdit : messages.textButton}
              </ButtonMoons>
            )}
          >
            {
              progress ? <LoaderMoons size={100} /> : (

                <div className={styles.gridContainer}>

                  <InputMoons
                    autoFocus
                    type="text"
                    name="name"
                    autoComplete="off"
                    placeholder={messages.name}
                    value={name}
                    onChange={handleInputChange}
                    required
                  />

                  {/* <InputMoons
                    type="email"
                    name="email"
                    autoComplete="off"
                    placeholder={messages.email}
                    value={email}
                    onChange={handleInputChange}
                    required
                  />

                  <InputMoons
                    type="text"
                    name="phone"
                    autoComplete="off"
                    placeholder={messages.phone}
                    value={phone}
                    onChange={handleInputChange}
                    required
                  /> */}

                  {/* <SelectMoons
                    name="countryOps"
                    items={messages.countries}
                    label={messages.countryOps}
                    value={countryOps}
                    onChange={handleInputChange}
                    required
                  /> */}

                  {
                    (!patientId || user.role[0].name.includes('ADMIN')) && showAdminFields && (
                      <SelectMoons
                        name="chosenDr"
                        items={doctors}
                        label={messages.chosenDr}
                        value={chosenDr}
                        onChange={(evt) => setChosenDr(evt.target.value)}
                        required
                      />
                    )
                  }

                  {
                    patientId && showAdminFields && (
                      <>
                        <InputMoons
                          type="number"
                          name="finalPrice"
                          autoComplete="off"
                          placeholder={messages.finalPrice}
                          value={finalPrice}
                          onChange={handleInputChange}
                        />

                        <InputMoons
                          type="number"
                          name="paidAmount"
                          autoComplete="off"
                          placeholder={messages.paidAmount}
                          value={paidAmount}
                          onChange={handleInputChange}
                        />

                        <InputMoons
                          type="text"
                          name="state"
                          autoComplete="off"
                          placeholder={messages.state}
                          value={state}
                          onChange={handleInputChange}
                        />

                        <InputMoons
                          type="text"
                          name="status"
                          autoComplete="off"
                          placeholder={messages.status}
                          value={status}
                          onChange={handleInputChange}
                        />
                      </>
                    )
                  }
                </div>
              )
            }

          </CardMoons>

        </form>
      </>
    )
  )
}
