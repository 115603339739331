/* eslint-disable import/prefer-default-export */
/**
 * option model
 */
export class OptionModelSelectMoons {
  /**
   * constructor
   * @param {*} object option data model
   */
  constructor(object = {}) {
    this.text = object.text || ''
    this.value = object.value || ''
    this.status = object.status
  }
}
