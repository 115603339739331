/* eslint-disable import/prefer-default-export */
/**
 * login dto
 */
export class LoginDto {
    email;

    password;

    /**
     * constructor login data
     * @param {*} data login form
     */
    constructor(data = {}) {
      this.password = data.password || ''
      this.email = data.email || ''
    }
}
