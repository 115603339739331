const infoPanelMsgs = {
  recoveryTitle: 'Estás a un paso',
  recoveryDesc: 'Solo ingresa la información solicitada.',
  createTitle: 'Felicidades uno más',
  createDesc: 'Ingresa la información solicitada para crear una nueva cuenta.',
  loginTitle: '¡Hola!',
  loginDesc: 'Ingresa para interactuar con el sistema.'
}

export default infoPanelMsgs
