/* eslint-disable import/prefer-default-export */
import { useState } from 'react'

/**
 * useSnack hook to manage snackbar
 * @param {Object} initialState snackbar config
 * @returns {Array} state, and set state
 */
export const useSnack = (initialState = {}) => {
  const [velues, setValues] = useState(initialState)

  /**
   * handle snack config props
   * @param {Boolean} status open/close snackbar
   * @param {String} message snackbar message
   * @param {String} type error or success message
   * @returns {Object} updated state
   */
  const handleSnack = (status = false, message = '', type = '') => {
    if (message === 'clickaway') return
    setValues({
      ...velues, status, message, type
    })
  }

  return [velues, handleSnack]
}
