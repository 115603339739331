import { Box, Tab, Tabs } from '@material-ui/core'
import { TabContext, TabPanel } from '@material-ui/lab'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router'
import { AppContext } from '../../../../../4-hooks/contexts/App/App.context'
import { MedicalHistory } from '../../../MedicalHistory/MedicalHistory'
import { Treatment } from '../../../Treatment/Treatment'
import { ValidatePictures } from '../../../ValidatePictures/ValidatePictures'

/** */
const InfoTabs = ({ patient, imageList, progress, setInProgress, status, setValues }) => {
  const { stateApp: { functions, user, historyApp } } = useContext(AppContext)
  const { planningId } = useParams()
  const { handleSnack } = functions || {}
  const [tab, setTab] = useState(1)

  /** */
  const handlechange = (event, selecteTab) => {
    setTab(selecteTab)
  }

  return (
    <Box>
      <TabContext value={tab}>
        <Tabs
          value={tab}
          onChange={handlechange}
        >
          {planningId && (status !== '') && <Tab label={'Fotos'} value={1} />}
          <Tab label={'Historial médico'} value={2} />
          <Tab label={'Preferencias De Ortodoncia'} value={3} />
        </Tabs>
        <TabPanel value={1}>
          {planningId && (status !== '')
            && (
              <ValidatePictures
                useDiagnose={false}
                name={patient?.name || ''}
                images={imageList}
                token={user.sessionToken}
                planningId={planningId}
                progress={progress}
                status={status}
                setInProgress={setInProgress}
                handleSnack={handleSnack}
                historyApp={historyApp}
                setValues={setValues}
              />
            )
          }
        </TabPanel>
        <TabPanel value={2}>
          <MedicalHistory
            isReadOnly
          />
        </TabPanel>
        <TabPanel value={3}>
          <Treatment
            isSummary
          />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default InfoTabs