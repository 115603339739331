/* eslint-disable import/prefer-default-export */
/**
 * recovery dto form
 */
export class RecoveryDto {
    email;

    /**
     * constructor recovery form dto
     * @param {*} data recovery form data
     */
    constructor(data = {}) {
      this.email = data.email || ''
    }
}
