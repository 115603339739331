/* eslint-disable no-console */
/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import changeData from '../../../8-common/ResizeImage'
import { uploadPicture } from '../../../7-services/App/Pictures.service'
import { updatePlanning } from '../../../7-services/App/Planning.service'

const zero = 0

/**
 * process image resize
 * @param {*} param0 change data object
 * @param {*} param0._filesSelected input fiel object
 * @param {*} param0.prop .
 * @param {*} param0.styles img styles
 * @param {*} param0.files list of files Patient Pictreus model
 * @param {*} param1 .
 * @param {Array} param1.inputsConfig inputs naming list
 * @param {Function} param1.setInputsConfig change inputs config
 * @param {*} param1.setFiles set patient pictures model
 * @returns {Void} process images
 */
export const imagesProcess = (
  {
    _filesSelected, prop, styles, files
  },
  { inputsConfig, setInputsConfig, setFiles }
) => {
  const reader = new FileReader()
  const file = _filesSelected[0]

  if (prop === 'xray') {
    setFiles({ ...files, [prop]: file })
  } else {
    reader.onload = async () => {
      const input = inputsConfig.find(inputF => inputF.prop === prop)
      const base64image = reader.result
      input.image = (
        <img
          alt="pic-patient"
          src={base64image}
          className={styles.imgPreview}
        />
      )
      input.placeHolder = file.name

      setInputsConfig([...inputsConfig])

      const newImage = await changeData(file, base64image)

      setTimeout(() => {
        setFiles({ ...files, [prop]: newImage })
      // eslint-disable-next-line no-magic-numbers
      }, 300)
    }
  }

  reader.readAsDataURL(file)
}

/**
 * validate if all required pictures are present
 * @param {Object} filesList files Pictures dto
 * @returns {Boolean} is valid form
 */
const validatePictures = (filesList) => {
  const invalidPictures = []
  Object.keys(filesList).forEach(fileKey => {
    if (!filesList[fileKey] && fileKey !== 'xray') {
      invalidPictures.push(fileKey)
    }
  })
  return invalidPictures.length <= zero
}

/**
 * Handle async chain of uploadPicture service calls
 * @param {Array} fileObjects list of files to upload
 * @param {String} customerId Patient's customerId to relate to the pictures
 * @returns {Array} list of file uploaded urls
 */
const reduceUploads = async (fileObjects, customerId) => {
  const resultsAccum = []
  await fileObjects.reduce(async (previousPromise, nextItem) => {
    try {
      await previousPromise
      try {
        const uploaded = await uploadPicture(nextItem.photo, nextItem.name, customerId)
        resultsAccum.push({ name: nextItem.name, photoUrl: uploaded?.data?.file_url })
        return uploaded
      } catch (error) {
        const errorCopy = { ...error }
        errorCopy.msg = 'Error uploading a picture'
        errorCopy.photoName = nextItem.name
        throw errorCopy
      }
    } catch (error) {
      console.log('error previous upload: ', error)
      throw error
    }
  }, Promise.resolve())
  return resultsAccum
}

/**
 * Check if File objects to upload
 * promise chain of upload pictures one by one
 * then update Planning with files url properties
 * @param {Object} param1 .
 * @param {Object} param1.files Pictures dto
 * @param {String} param1.customerId Patient's customerId
 * @param {String} param1.token user's session token
 * @param {String} param1.planningId Planning objectid to update files object
 * @param {Function} param1.handleSnack open snackbar function
 * @param {Function} param1.setInProgress start progress flag
 * @param {Function} param1.historyApp router
 * @param {String} param0.urlNextStep link to next step
 * @param {String} param0.statePlann Planning state
 * @param {String} param0.statusPlann Planning status
 * @returns {Object} response update Planning
 */
export const processUploadPictures = async ({
  files, customerId, token, planningId, handleSnack, setInProgress,
  historyApp, urlNextStep, statePlann, statusPlann, user
}) => {
  if (!validatePictures(files)) {
    handleSnack(true, 'Selecciona las fotografías de tu paciente', 'error')
    return
  }

  const fileObjects = []
  Object.keys(files).forEach(fileKey => {
    if (typeof files[fileKey] !== 'string') {
      fileObjects.push({ name: fileKey, photo: files[fileKey] })
    }
  })

  setInProgress(true)
  const planningFiles = { ...files }
  if (fileObjects.length > zero) {
    try {
      const uploadedFiles = await reduceUploads(fileObjects, customerId)
      uploadedFiles.forEach(upload => {
        if (Object.keys(planningFiles).find(fileKey => fileKey === upload.name)) {
          planningFiles[upload.name] = upload.photoUrl
        }
      })
    } catch (error) {
      console.log('error upload photos: ', error)
      handleSnack(true, `Error al subir las fotos${error.photoName && `: ${error.photoName}`}`, 'error')
      return
    } finally {
      setInProgress(false)
    }
  }
  try {
    setInProgress(true)
    const filesAux = {}
    Object.keys(planningFiles).forEach(fileKey => {
      filesAux[fileKey] = { url: planningFiles[fileKey] }
    })
    const updatePlannReq = {
      files: filesAux
    }
    if (statePlann === 'initial' && (!statusPlann || statusPlann === 'invalid_pictures')) {
      updatePlannReq.state = 'pictures_uploaded'
      updatePlannReq.status = 'waiting_for_review'
    }
    updatePlannReq.updatedBy = user.objectId
    await updatePlanning(token, planningId, updatePlannReq)
    handleSnack(true, 'Éxito al guardar las fotos de la Planeación', 'success')
    urlNextStep && historyApp.push(urlNextStep)
    return
  } catch (error) {
    console.log('error update planning: ', error)
    handleSnack(true, 'Error al guardar fotos de la Planeación', 'error')
    return
  } finally {
    setInProgress(false)
  }
}
