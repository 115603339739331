/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/require-default-props */
import React from 'react'
import {
  Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { ChecksGroupMoonsModel } from './Check.model'
import styles from './ChecksGroupMoons.module.scss'

/**
 * checks group moons component
 * @param {*} props .
 * @returns {Void} component
 */
export const ChecksGroupMoons = (props) => {
  const {
    disableAll, checks, label, textHelper, name, handleChange, ...rest
  } = props

  return (
    <FormControl component="fieldset">
      {
        label && <FormLabel component="legend">{label}</FormLabel>
      }

      <FormGroup {...rest}>
        {
          checks.map((check, idx) => (check.disabled ? (
            <FormControlLabel
              key={idx}
              control={(
                <Checkbox
                  disabled
                  color="primary"
                  checked={check.isChecked}
                  onChange={(evt) => handleChange(evt, name)}
                  name={check.name}
                  size="small"
                />
              )}
              label={check.label}
              className={styles.wrappCheck}
            />
          ) : (
            <FormControlLabel
              key={idx}
              control={(
                <Checkbox
                  disabled={disableAll}
                  color="primary"
                  checked={check.isChecked}
                  onChange={(evt) => handleChange(evt, name)}
                  name={check.name}
                  size="small"
                />
              )}
              label={check.label}
              className={styles.wrappCheck}
            />
          )))
        }
      </FormGroup>
      {
        textHelper && <FormHelperText>{textHelper}</FormHelperText>
      }

    </FormControl>
  )
}

ChecksGroupMoons.propTypes = {
  checks: PropTypes.arrayOf(PropTypes.instanceOf(ChecksGroupMoonsModel)).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  textHelper: PropTypes.string
}
