/* eslint-disable max-params */
/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { InputMoons } from '../../UI'
import { createTreatment, updateTreatment } from '../../../7-services/App/Treatment.service'
import styles from './Treatment.module.scss'

/**
 * handle create/update logic
 * @param {*} param0 .
 * @param {String} param0.urlNextStep link to next step
 * @param {String} param0.token user's session token
 * @param {Object} param0.formValues form values treatment dto
 * @param {String} param0.treatObjId Treatment objectId to identify edit
 * @param {String} param0.planningId Planning objectId for creation
 * @param {Function} param0.handleSnack open snackbar
 * @param {Function} param0.setInProgress activate flag to progress
 * @param {Function} param0.historyApp router
 * @returns {Object} service response new/updated Treatment
 */
export const handleUpsert = async ({
  urlNextStep, token, formValues, treatObjId, planningId, handleSnack, setInProgress, historyApp,
  user
}) => {
  const treatData = buildTreatmentBody(formValues)
  treatData.updatedBy = user.objectId
  setInProgress(true)
  if (treatObjId) {
    try {
      await updateTreatment(token, treatObjId, treatData)
      handleSnack(true, 'Éxito al editar el Tratamiento', 'success')
      urlNextStep && historyApp.push(urlNextStep)
      return
    } catch (error) {
      handleSnack(true, 'Error al editar el Tratamiento', 'error')
      return
    } finally {
      setInProgress(false)
    }
  }
  try {
    await createTreatment(token, planningId, treatData)
    handleSnack(true, 'Éxito al guardar información del Tratamiento', 'success')
    urlNextStep && historyApp.push(urlNextStep)
    return
  } catch (error) {
    handleSnack(true, 'Error al guardar información del Tratamiento', 'error')
    return
  } finally {
    setInProgress(false)
  }
}

/**
 * build service Treatment dto
 * @param {Object} values form values Treatment dto
 * @returns {Object} formatted request body
 */
export const buildTreatmentBody = (values) => ({
  settings: {
    keepSpaces: values.keepSpaces,
    leftMolarClass: values.leftMolarClass,
    rightMolarClass: values.rightMolarClass,
    leftCanineClass: values.leftCanineClass,
    rightCanineClass: values.rightCanineClass,
    verticalOverbite: values.verticalOverbite,
    horizontalOverbite: values.horizontalOverbite,
    arcade: values.arcade,
    alignArchesUsing: values.alignArchesUsing,
    extractParts: values.extractParts,
    doIPR: values.doIPR
  },
  notes: values.notes
})

/**
 * generic render input from options
 * @param {Object} data object data
 * @param {Number} idx index
 * @param {Function} handleInputChange change handler
 * @param {Boolean} isSummary for input auto focus
 * @returns {Void} component
 */
export const wrapInput = (data, idx, handleInputChange, isSummary) => (
  <div
    className={styles.gridContainer}
    key={idx}
  >
    <span>{data.message}</span>
    <InputMoons
      autoFocus={data.name === 'keepSpaces' && !isSummary}
      disabled={isSummary}
      type="text"
      name={data.name}
      autoComplete="off"
      placeholder={data.placeholder}
      value={data.value}
      onChange={handleInputChange}
    />
  </div>
)
