/* eslint-disable import/prefer-default-export */
/* eslint-disable no-useless-catch */
/* eslint-disable max-statements */
/* eslint-disable no-process-env */
import axios from 'axios'

const urlFileUpload = process.env.REACT_APP_FILE_UPLOAD
const bucketName = process.env.REACT_APP_BUCKET

/**
 * upload file
 * @param {Object} fileData File object data
 * @param {String} photoName Name of photo = front, smile, occlusion etc.
 * @param {String} customerId patient's customerId
 * @returns {String} upload url
 */
export const uploadPicture = async (fileData, photoName, customerId, extention) => {
  const ext = fileData.type.split('/')[1]
  const data = new FormData()
  data.append('File', fileData)
  data.append('bucketName', bucketName)
  data.append('isPublic', 'true')
  data.append('folderName', `${customerId}_${Math.floor(Date.now())}`)
  data.append('fileName', `${customerId}-${photoName}.${extention || ext}`)
  try {
    const config = {
      method: 'post',
      url: urlFileUpload,
      data,
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    return await axios(config)
  } catch (error) {
    throw error
  }
}
