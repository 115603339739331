/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import { useLocation } from 'react-router'
import styles from './InformationPanel.module.scss'
import messages from './InformationPanel.messages'

/**
 * info panel comp
 * @param {*} param0 .
 * @returns {Void} component
 */
export const InformationPanel = ({ updateSection, classNameCreateAccount }) => {
  const location = useLocation()
  return (
    <div className={styles.overlayContainer}>
      <div className={styles.overlay}>
        {
          (location.pathname === '/recovery' || location.pathname.includes('update-password'))
          && (
            <div className={`${styles.overlayPanel} ${styles.overlayRight}`}>
              <h1>{messages.recoveryTitle}</h1>
              <p>{messages.recoveryDesc}</p>
            </div>
          )
        }
        {
          (location.pathname === '/login' || location.pathname === '/create-account')
          && (
            <>
              <div className={`${styles.overlayPanel} ${styles.overlayLeft}`}>
                <h1>{messages.createTitle}</h1>
                <p>{messages.createDesc}</p>
                {/* <button className="ghost" id="signIn"
                onClick={() => updateSection('')}>Iniciar sesión</button> */}
              </div>
              <div className={`${styles.overlayPanel} ${styles.overlayRight}`}>
                <h1>{messages.loginTitle}</h1>
                <p>{messages.loginDesc}</p>
                {/* <button className="ghost" id="signUp"
                onClick={() => updateSection(classNameCreateAccount)}>
                Crear cuenta</button> */}
              </div>
            </>
          )
        }

      </div>
    </div>
  )
}
