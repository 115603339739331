import { requestMoons } from '../../8-common/Request'

/**
 * update Treatment service
 * @param {String} token user's session token
 * @param {String} objectId Treatment's objectId to fetch to update
 * @param {Object} treatData Treatment dto to update
 * @returns {Object} updated treatment dto
 */
export const updateTreatment = (token, objectId, treatData) => requestMoons({
  endpoint: `/planning/treatment/${objectId}`,
  method: 'PUT',
  body: treatData,
  customHeaders: {
    Authorization: token
  }
})

/**
 * Create treatment service
 * @param {String} token user's session token
 * @param {String} planningId Planning objectId to relate new Treatment to
 * @param {Object} treatData new Treatment dto to save
 * @returns {Object} new Treatment dto
 */
export const createTreatment = (token, planningId, treatData) => requestMoons({
  endpoint: `/planning/${planningId}/treatment`,
  method: 'POST',
  body: treatData,
  customHeaders: {
    Authorization: token
  }
})
