/* eslint-disable max-statements */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useContext, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useForm } from '../../../4-hooks/useForm'
import { AppContext } from '../../../4-hooks/contexts/App/App.context'
import { MedicalHistoryModel } from '../../../6-models/App/MedicalHistory.model'
import Planning from '../../../6-models/App/Planning.model'
import { ButtonMoons, InputMoons } from '../../UI'
import { CardMoons } from '../../UI/CardMoons/CardMoons'
import { ChecksGroupMoonsModel } from '../../UI/ChecksGroupMoons/Check.model'
import { ChecksGroupMoons } from '../../UI/ChecksGroupMoons/ChecksGroupMoons'
import { DatePickerMoons } from '../../UI/DatePickerMoons/DatePickerMoons'
import { RadioButtonsGroupMoons } from '../../UI/RadioButtonsGroupMoons/RadioButtonsGroupMoons'
import { upsertMedHist } from './MedicalHistory.logic'
import { getPlanningData } from '../../../7-services/App/Planning.service'
import ReturnHistory from '../../UI/ReturnHistory/ReturnHistory'
import NextStep from '../../UI/NextStep/NextStep'
import LoaderMoons from '../../UI/LoaderMoons/LoaderMoons'
import messages from './MedicalHistory.messages'
import styles from './MedicalHistory.module.scss'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * Medical History form planning-patient 1st step
 * @param {Object} param0 .
 * @param {String} param0.urlNextStep url string for next step
 * @param {String} param0.urlBackStep url string for next step
 * @returns {Void} component
 */
export const MedicalHistory = ({ urlNextStep, urlBackStep, isReadOnly = false }) => {
  const { stateApp: { functions, user, historyApp } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const [
    formValues,
    setValues,
    handleInputChange, ,
    progress,
    setInProgress,
    handleCheckGroupChange,
    handleDatePickerChange
  ] = useForm(new MedicalHistoryModel())
  const {
    birthdate,
    gender,
    patientTarget,
    isMedicated,
    hasPathology,
    hadOrthodontics,
    haveDentalImplantsOrBridges,
    haveCrownsVeneersResins,
    alterationInAtm
  } = formValues
  const { planningId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [medHistObjId, setMedHistId] = useState('')
  const [patientId, setPatientId] = useState('')
  const [patientName, setPatientName] = useState('')

  useEffect(() => {
    if (planningId) {
      setIsLoading(true)
      getPlanningData(user.sessionToken, planningId)
        .then(res => {
          const planningData = new Planning(res)
          const { medicalHistory, patient } = planningData
          if (medicalHistory) {
            const medHistData = new MedicalHistoryModel(medicalHistory || {})
            medHistData.birthdate = medHistData?.birthdate?.iso || null
            setValues(medHistData)
            setMedHistId(medicalHistory.objectId)
          }
          const { objectId, name } = patient
          setPatientId(objectId)
          setPatientName(name)
          setIsLoading(false)
        })
        .catch(error => {
          handleSnack(true, 'Error al obtener información del Historial Médico', 'error')
          setIsLoading(false)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    isLoading ? <LoaderMoons size={120} /> : (
      <>
        {
          !isReadOnly
          && (
            <ReturnHistory
              route={user.userType === 'DOCTOR' ? `/${prefijApp}/patients/${user.objectId}` : `/${prefijApp}/patients`}
              historyApp={historyApp}
            />
          )
        }
        {
          !isReadOnly
          && (
            <NextStep
              route={urlNextStep}
              message={messages.optionalMsg}
              btnMsg={messages.optionalLink}
            />
          )}
        <CardMoons
          title={messages.title.replace('[patient]', patientName)}
          actions={(
            <>
              {
                urlBackStep && (
                  <Link
                    className="linkStep"
                    to={urlBackStep}
                  >
                    {messages.buttonBack}
                  </Link>
                )
              }
              {!isReadOnly
                && (
                  <ButtonMoons
                    variant="contained"
                    color="primary"
                    onClick={() => upsertMedHist({
                      urlNextStep,
                      token: user.sessionToken,
                      formValues,
                      medHistObjId,
                      patientId,
                      handleSnack,
                      setInProgress,
                      historyApp,
                      user
                    })}
                    isprogress={Number(progress)}
                  >
                    {messages.button}
                  </ButtonMoons>
                )
              }
            </>
          )}
        >

          <div className={styles.gridContainer}>
            <DatePickerMoons
              disabled={isReadOnly}
              autoFocus
              label="Fecha nacimiento"
              name="birthdate"
              value={birthdate}
              onChange={handleDatePickerChange}
              maxDate={new Date()}
              maxDateMessage="La fecha no puede ser mayor a hoy"
            />
            <div>
              <RadioButtonsGroupMoons
                disableAll={isReadOnly}
                row
                radios={messages.listGender}
                name="gender"
                value={gender}
                onChange={handleInputChange}
                label="Genero"
              />
            </div>
          </div>
          <div className={styles.gridContainer}>
            <span>{messages.question1}</span>
            <div>
              <ChecksGroupMoons
                disableAll={isReadOnly}
                row
                checks={
                  messages.listPatientTarget
                    .map(evt => new ChecksGroupMoonsModel({
                      ...evt,
                      isChecked: patientTarget[evt.name]
                    }))
                }
                name="patientTarget"
                handleChange={handleCheckGroupChange}
              />
            </div>
          </div>
          {
            [
              {
                name: 'isMedicated', placeholder: messages.placeHolder2, message: messages.question2, value: isMedicated
              },
              {
                name: 'hasPathology', placeholder: messages.placeHolder3, message: messages.question3, value: hasPathology
              },
              {
                name: 'hadOrthodontics', placeholder: messages.placeHolder4, message: messages.question4, value: hadOrthodontics
              },
              {
                name: 'haveDentalImplantsOrBridges', placeholder: messages.placeHolder5, message: messages.question5, value: haveDentalImplantsOrBridges
              },
              {
                name: 'haveCrownsVeneersResins', placeholder: messages.placeHolder6, message: messages.question6, value: haveCrownsVeneersResins
              }
            ]
              .map((evt, idx) => (
                <div
                  className={styles.gridContainer}
                  key={idx}
                >
                  <span>{evt.message}</span>
                  <InputMoons
                    disabled={isReadOnly}
                    type="text"
                    name={evt.name}
                    autoComplete="off"
                    placeholder={evt.placeholder}
                    value={evt.value}
                    onChange={handleInputChange}
                  />
                </div>
              ))
          }
          <div className={styles.gridContainer}>
            <span>{messages.question7}</span>
            <div>
              <ChecksGroupMoons
                disableAll={isReadOnly}
                row
                checks={
                  messages.listAlterationInAtm
                    .map(evt => new ChecksGroupMoonsModel({
                      ...evt,
                      isChecked: alterationInAtm[evt.name]
                    }))
                }
                name="alterationInAtm"
                handleChange={handleCheckGroupChange}
              />
            </div>
          </div>

        </CardMoons>
      </>
    )
  )
}
