/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, { useState, useContext } from 'react'
import { AppContext } from '../../../../../4-hooks/contexts/App/App.context'
import { DragAndDropMoons } from '../../../../UI/DragAndDropMoons/DragAndDropMoons'
import { useForm } from '../../../../../4-hooks/useForm'
import styles from './StlUpload.module.scss'
import messages from './StlUpload.messages'
import { ButtonMoons } from '../../../../UI'
import handleUpload from './StlUpload.logic'

/**
 * STL Method : Upload
 * @param {String} planningId Planning obejctId to relate to when create shipping
 * @param {String} customerId patient's customerId for upload file
 * @returns {Void} component
 */
export const StlUpload = ({ planningId, customerId }) => {
  const { stateApp: { functions, user, historyApp } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const [, , , , progress,
    setInProgress] = useForm({})
  const [fileSelected, setFileSelected] = useState(null)

  return (
    <div className={styles.containerStlUpload}>
      <h3>{messages.message1}</h3>
      <p>
        {messages.message4}
        {' '}
        {messages.maxFiles}
        {' '}
      </p>
      <br />
      <DragAndDropMoons
        handledFiles={(files, event) => setFileSelected(files[0])}
        maxFiles={messages.maxFiles}
        placeholder={messages.message2}
        showAcceptedFiles
        typeFiles=".pdf,.zip,.stl"
      />
      <ButtonMoons
        variant="outlined"
        color="primary"
        type="button"
        isprogress={Number(progress)}
        onClick={() => handleUpload(
          {
            user, historyApp, fileSelected, planningId, customerId
          },
          { handleSnack, setInProgress }
        )}
      >
        {messages.message3}
      </ButtonMoons>
    </div>
  )
}
