/* eslint-disable import/prefer-default-export */
/**
 * chekc groups moons model class
 */
export class ChecksGroupMoonsModel {
  /**
   * constructr checks groups model
   * @param {*} object data object model
   */
  constructor(object = {}) {
    this.name = object.name || ''
    this.label = object.label || ''
    this.disabled = object.disabled || false
    this.isChecked = object.isChecked || false
  }
}
