/* eslint-disable import/prefer-default-export */
import React from 'react'
import { DoctorList } from '../../../3-components/App/Doctor/DoctorList/DoctorList'

/**
 * Doctors list component
 * @returns {Void} component
 */
export const DoctorsView = () => (
  <DoctorList />
)
