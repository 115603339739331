/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable import/prefer-default-export */
/**
 * User (doctor, dr moons, admin) model
 */
export class CreateDto {
  /**
   * constructor
   * @param {Object} data user data object
   */
  constructor(data = {}) {
    this.name = data.name || ''
    this.username = data.username || ''
    this.phone = data.phone || ''
    this.countryOps = data.countryOps || ''
    this.role = data.role || ''

    this.street = ''
    this.apt = ''
    this.neighborhood = ''
    this.city = ''
    this.state = ''
    this.zip = ''

    if (data.address) {
      const { address } = data
      this.street = address.street || ''
      this.apt = address.apt || ''
      this.neighborhood = address.neighborhood || ''
      this.city = address.city || ''
      this.state = address.state || ''
      this.zip = address.zip || ''
    }
  }
}
