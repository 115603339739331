/* eslint-disable react/jsx-no-literals */
import React from 'react'
import moment from 'moment-timezone'
import { ButtonMoons } from '../../../3-components/UI/ButtonMoons/ButtonMoons'
import messages from './PlanSummaryView.messages'
import { updatePlanning } from '../../../7-services/App/Planning.service'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * Handle accept treatment action
 * @param {Object} param1.user authenticated user
 * @param {Function} param1.historyApp router history
 * @param {String} param1.planningId if valid is edit, else is create
 * @param {Function} param1.handleSnack function to open snackbar
 * @param {Function} param1.setInProgress activate progress flag
 * @returns {Void} api call
 */
export const acceptTreatment = async ({
  user, historyApp, planningId, handleSnack, setInProgress
}) => {
  setInProgress(true)
  try {
    const updatePlannReq = {
      state: 'approved',
      updatedBy: user.objectId,
      approvedDate: moment().utc(true).toString()
    }
    await updatePlanning(user.sessionToken, planningId, updatePlannReq)
    handleSnack(true, 'Planeación aceptada con éxito', 'success')
    setInProgress(false)
    historyApp.push(`/${prefijApp}/home`)
  } catch (error) {
    handleSnack(true, 'Error al aceptar la Planeación', 'error')
    setInProgress(false)
  }
}

/**
 * get jsx for button header
 * @param {Number} progress is progress loading
 * @param {Object} param1.user authenticated user
 * @param {Function} param1.historyApp router history
 * @param {String} param1.planningId if valid is edit, else is create
 * @param {Function} param1.handleSnack function to open snackbar
 * @param {Function} param1.setInProgress activate progress flag
 * @returns {Object} jsx button
 */
export const getButtonAccept = (progress, {
  user, historyApp, planningId, handleSnack, setInProgress
}) => (
  <ButtonMoons
    color="secondary"
    size="small"
    onClick={() => acceptTreatment({
      user, historyApp, planningId, handleSnack, setInProgress
    })}
    isprogress={Number(progress)}
  >
    {messages.message12}
  </ButtonMoons>
)
