/* eslint-disable react/jsx-no-literals */
import React, { useState } from 'react'
import {
  Card, CardActions, CardHeader
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { ButtonMoons } from '../ButtonMoons/ButtonMoons'
import { IconButtonMoons } from '../IconButtonMoons/IconButtonMoons'
import styles from './NextStep.module.scss'

/**
 * Next Step optional med hist
 * @param {*} param0 .
 * @param {String} param0.message Button message
 * @param {String} param0.btnMsg Button message
 * @param {String} param0.route Route to redirect to
 * @returns {Void} component
 */
const NextStep = ({ message, btnMsg, route }) => {
  const [show, setShow] = useState(true)

  return (
    show && (
      <Card className={styles.cardContainer}>
        <CardHeader
          action={(
            <IconButtonMoons
              edge="start"
              aria-label="close_next_alert"
              icon="cancel"
              color="secondary"
              onClick={() => setShow(false)}
            />
          )}
          subheader={message}
        />
        <CardActions>
          <Link to={route}>
            <ButtonMoons
              size="small"
              color="secondary"
              onClick={(evt) => {
                evt.stopPropagation()
              }}
            >
              {btnMsg}
            </ButtonMoons>
          </Link>
        </CardActions>
      </Card>
    )
  )
}

export default NextStep
