/* eslint-disable import/prefer-default-export */
/**
 * Medical History model
 */
export class MedicalHistoryModel {
  /**
   * constructor
   * @param {*} data .
   */
  constructor(data = {}) {
    this.birthdate = data.birthdate || null
    this.gender = data.gender || ''
    this.patientTarget = data?.quiz?.patientTarget || {
      bite: false,
      alignment: false,
      separation: false,
      esthetic: false
    }
    this.isMedicated = data?.quiz?.isMedicated || ''
    this.hasPathology = data?.quiz?.hasPathology || ''
    this.hadOrthodontics = data?.quiz?.hadOrthodontics || ''
    this.haveDentalImplantsOrBridges = data?.quiz?.haveDentalImplantsOrBridges || ''
    this.haveCrownsVeneersResins = data?.quiz?.haveCrownsVeneersResins || ''
    this.alterationInAtm = data?.quiz?.alterationInAtm || {
      difficultyOpeningYourMouth: false,
      crepitation: false,
      pain: false
    }
  }
}
