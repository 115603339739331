/* eslint-disable react/jsx-no-literals */
import React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { IconMoons } from '../IconMoons/IconMoons'

/**
 * Accordion Moons
 * @param {*} param0 .props
 * @returns {Void} component
 */
const AccordionMoons = ({ title, children }) => (
  <Accordion TransitionProps={{ unmountOnExit: true }}>
    <AccordionSummary
      expandIcon={<IconMoons name="expand_more" />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      {title}
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion>
)

export default AccordionMoons
