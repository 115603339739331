/* eslint-disable complexity */
/* eslint-disable max-params */
/* eslint-disable max-statements */
import moment from 'moment-timezone'
import JSZip from 'jszip'
import { getPlanningData, createPlanning, updatePlanning } from '../../../../7-services/App/Planning.service'
import { uploadPicture } from '../../../../7-services/App/Pictures.service'
import { zero, two } from '../../../../8-common/Constants'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * Handles submit
 * @param {*} event .
 * @param {Object} param1 .
 * @param {Object} param1.formValues values from the form
 * @param {Object} param1.user authenticated user
 * @param {Function} param1.historyApp router history
 * @param {Object} param1.fileDatasheet file value for datasheet
 * @param {Object} param1.fileVideo file value for video url
 * @param {Object} param1.fileStl file value for stl file
 * @param {File} param1.prodStl compressed zip file with multiple STLs
 * @param {String} param1.customerId patient's customerId
 * @param {String} param1.planningId if valid is edit, else is create
 * @param {String} param1.calcTreat calculated treatment
 * @param {String} param1.[patientObjId] Patient objectId used for create
 * @param {Object} param2 .
 * @param {Function} param2.reset function to reset form
 * @param {Function} param2.handleSnack function to open snackbar
 * @param {Function} param2.setInProgress function to set in progress flag
 * @returns {Void} submit
 */
export const handleSubmitForm = async (
  event,
  {
    formValues, user, historyApp, fileDatasheet, fileVideo, fileStl, prodStl,
    lowerArchFileStl, firstOcclusionFileStl, secondOcclusionfileStl,
    customerId, planningId, calcTreat, patientObjId
  },
  { reset, handleSnack, setInProgress }
) => {
  event.preventDefault()
  setInProgress(true)

  const planningData = { ...formValues }

  if (planningData.delimitedModelUrl
    && !validateArchFormDelimitedModelUrl(planningData.delimitedModelUrl)) {
    handleSnack(true, 'El link del modelo de Archform no tiene el formato correcto', 'error')
    setInProgress(false)
    return
  }

  if (planningData.archformUrl
    && !validateArchFormUrl(planningData.archformUrl)) {
    handleSnack(true, 'El link de visualizacion de Archform no tiene el formato correcto', 'error')
    setInProgress(false)
    return
  }

  if (planningData.upperAligners < zero) {
    handleSnack(true, 'Los alineadores superiores no pueden ser negativos', 'error')
    setInProgress(false)
    return
  }

  if (planningData.lowerAligners < zero) {
    handleSnack(true, 'Los alineadores inferiores no pueden ser negativos', 'error')
    setInProgress(false)
    return
  }

  planningData.treatmentMonths = calcTreat || zero
  planningData.upperAligners = parseInt(planningData.upperAligners, 10)
  planningData.lowerAligners = parseInt(planningData.lowerAligners, 10)
  if (planningData?.deliverPromiseDate) {
    planningData.deliverPromiseDate = moment(planningData?.deliverPromiseDate).utc(true).toString()
  }
  if (planningData?.paymentDate) {
    planningData.paymentDate = moment(planningData?.paymentDate).utc(true).toString()
  }
  if (planningData?.approvedDate) {
    planningData.approvedDate = moment(planningData?.approvedDate).utc(true).toString()
  }
  delete planningData.files
  delete planningData.patient
  delete planningData.medicalHistory
  delete planningData.treatment
  delete planningData.appointment
  delete planningData.shipping

  planningData.updatedBy = user.objectId

  if (fileDatasheet) {
    try {
      const uploaded = await uploadPicture(fileDatasheet, 'datasheet', customerId)
      planningData.datasheet = uploaded?.data?.file_url
    } catch (error) {
      setInProgress(false)
      handleSnack(true, 'Error al subir Ficha Técnica', 'error')
      return
    }
  }

  if (fileVideo) {
    try {
      const uploaded = await uploadPicture(fileVideo, 'videoUrl', customerId)
      planningData.videoUrl = uploaded?.data?.file_url
    } catch (error) {
      setInProgress(false)
      handleSnack(true, 'Error al subir Video de Planeación', 'error')
      return
    }
  }

  if (fileStl) {
    try {
      const uploaded = await uploadPicture(fileStl, 'stlFileUrl', customerId, fileStl.name.split('.').pop())
      planningData.stlFileUrl = uploaded?.data?.file_url
    } catch (error) {
      setInProgress(false)
      handleSnack(true, 'Error al subir Archivo STL', 'error')
      return
    }
  }
  if (lowerArchFileStl) {
    try {
      const uploaded = await uploadPicture(lowerArchFileStl, 'stlLowerArchFileUrl', customerId, lowerArchFileStl.name.split('.').pop())
      planningData.stlLowerArchFileUrl = uploaded?.data?.file_url
    } catch (error) {
      setInProgress(false)
      handleSnack(true, 'Error al subir Archivo STL', 'error')
      return
    }
  }
  if (firstOcclusionFileStl) {
    try {
      const uploaded = await uploadPicture(firstOcclusionFileStl, 'stlFirstOcclusionFileUrl', customerId, firstOcclusionFileStl.name.split('.').pop())
      planningData.stlFirstOcclusionFileUrl = uploaded?.data?.file_url
    } catch (error) {
      setInProgress(false)
      handleSnack(true, 'Error al subir Archivo STL', 'error')
      return
    }
  }
  if (secondOcclusionfileStl) {
    try {
      const uploaded = await uploadPicture(secondOcclusionfileStl, 'stlSecondOcclusionFileUrl', customerId, secondOcclusionfileStl.name.split('.').pop())
      planningData.stlSecondOcclusionFileUrl = uploaded?.data?.file_url
    } catch (error) {
      setInProgress(false)
      handleSnack(true, 'Error al subir Archivo STL', 'error')
      return
    }
  }

  if (prodStl) {
    try {
      const uploaded = await uploadPicture(prodStl, 'prodStlFile', customerId)
      planningData.prodStlFileUrl = uploaded?.data?.file_url
    } catch (error) {
      setInProgress(false)
      handleSnack(true, 'Error al subir Archivo STL de producción', 'error')
      return
    }
  }

  if (planningId) {
    try {
      await updatePlanning(user.sessionToken, planningId, planningData)
      handleSnack(true, 'Planeación actualizada con éxito', 'success')
      setInProgress(false)
      window.location = `/${prefijApp}/planning/form/${planningId}`
      // historyApp.push(`/${prefijApp}/patients`)
      return
    } catch (error) {
      handleSnack(true, 'Error al actualizar la Planeación', 'error')
      setInProgress(false)
      return
    }
  }
  if (planningData.state === '') {
    handleSnack(true, 'El Estado no puede ser vacío', 'error')
    setInProgress(false)
    return
  }
  try {
    await createPlanning(user.sessionToken, patientObjId, planningData, user.objectId)
    handleSnack(true, 'Planeación creada con éxito', 'success')
    setInProgress(false)
    historyApp.push(`/${prefijApp}/patients`)
    return
  } catch (error) {
    handleSnack(true, 'Error al crear la Planeación', 'error')
    setInProgress(false)
  }
}

/**
 * Calculates treatment months
 * @param {Number} upperAlign upper aligners
 * @param {Number} lowerAlign lower aligners
 * @returns {Number} months
 */
export const calculateTreatment = (upperAlign, lowerAlign) => {
  const intUpper = parseInt(upperAlign, 10)
  const intLower = parseInt(lowerAlign, 10)
  if (isNaN(intUpper) || isNaN(intLower)) {
    return zero
  }
  if (intUpper > intLower) {
    const res = intUpper / two
    return Math.round(res)
  }
  const res = intLower / two
  return Math.round(res)
}

/**
 * Handle go to new planning form
 * @param {String} patientObjId patient objectId to relate planning to
 * @param {Function} historyApp hisory function
 * @param {Function} reset useForm method set values to clean form
 * @returns {Void} handles redirection
 */
export const goToNewForm = (patientObjId, historyApp, reset) => {
  reset()
  historyApp.push(`/${prefijApp}/planning/form/new/${patientObjId}`)
}

/**
 * Fetches Planning data to edit
 * @param {String} sessionToken user's session token
 * @param {String} planningId plannning objectId
 * @returns {Object} Planning data object
 */
export const fetchData = async (sessionToken, planningId) => {
  try {
    return await getPlanningData(sessionToken, planningId)
  } catch (error) {
    return null
  }
}

/**
 * Validates a propper link
 * @example https://dashboard.archform.com/threedview/7d8c41f180de2bc52e2e3d4f9bcfa24a7accad5a1dc20615a066e8b6685feea6/ItzelV/minimal
 * @param {String} link Archformlink
 * @returns {boolean} Is a valid link
 */
export const validateArchFormUrl = (link) => {
  try {
    const url = new URL(link)
    if (!url.host.includes('archform')) return false
    if (!url.pathname.includes('threedview')) return false
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

/**
 * Validates a propper link
 * @example https://dashboard.archform.com/patients/1986296
 * @param {String} link Archformlink
 * @returns {boolean} Is a valid link
 */
export const validateArchFormDelimitedModelUrl = (link) => {
  try {
    const url = new URL(link)
    if (!url.host.includes('archform')) return false
    if (!url.pathname.includes('patients')) return false
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

/**
 * Converts a blob to a file
 * @param {Blob} blob Blob
 * @param {string} fileName the name of the file
 * @returns {File} file converted file
 */
const blobToFile = (blob, fileName) => {
  blob.lastModifiedDate = new Date()
  blob.name = fileName
  return new File([blob], fileName, {
    type: blob.type
  })
}

/**
 * Compresses multiple files into a single file in zip format
 * @param {object} options Options
 * @param {Array<File>} options.files files Files to compress
 * @param {string} options.folderName The name of the compressed file
 * @returns {File} zip Compressed zip file
 */
export const compressToZip = async ({ files, folderName }) => {
  const zip = new JSZip()
  files.forEach((file) => zip.folder(folderName).file(file.name, file))
  const zipBlob = await zip.generateAsync({ type: 'blob' })
  return blobToFile(zipBlob, 'export.zip')
}

/** */
export const assignPlanning = async (user, planningId, handleSnack, setInProgress) => {
  const planningUpdate = {
    assignedTo: user.objectId,
    updatedBy: user.objectId
  }

  try {
    setInProgress(true)
    await updatePlanning(user.sessionToken, planningId, planningUpdate)
    handleSnack(true, 'Se te ha asignado la planeación', 'success')
  } catch (error) {
    handleSnack(true, 'Error al asignar la planeación', 'error')
  } finally {
    setInProgress(false)
  }
}
