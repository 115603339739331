/* eslint-disable import/prefer-default-export */
import React from 'react'
import { Icon } from '@material-ui/core'

/**
 * Reusable IconMoons component
 * @param {*} param0 .
 * @param {*} param0.name icon name
 * @param {*} param0.color material color
 * @returns {Void} component
 */
export const IconMoons = ({ name, color = 'inherit' }) => (
  <Icon color={color}>{name}</Icon>
)
