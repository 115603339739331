import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { sixty } from '../../../8-common/Constants'
import styles from './LoaderMoons.module.scss'

/**
 * LoaderMoons component
 * @returns {Void} component
 */
const LoaderMoons = ({ size = sixty }) => (
  <div className={styles.LoaderContainer}>
    <CircularProgress size={size} />
  </div>
)

export default LoaderMoons
