/* eslint-disable import/prefer-default-export */
import { zero } from '../../8-common/Constants'

/**
 * Patient class dto
 */
export class PatientDto {
  /**
   * constructor
   * @param {*} data data object
   */
  constructor(data = {}) {
    this.name = data.name || ''
    this.email = data.email || ''
    this.phone = data.phone || ''
    this.countryOps = data.countryOps || ''
    this.finalPrice = data.finalPrice || zero
    this.paidAmount = data.paidAmount || zero
    this.state = data.state || ''
    this.status = data.status || ''
  }
}
