/* eslint-disable react/no-array-index-key */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import sheetStyles from './DragAndDropMoons.module.scss'

// Documentación: https://react-dropzone.js.org/#src

const zero = 0
const fileListLabel = 'Archivos cargados exitosamente'

/**
 * file drag and drop generic omcponent
 * @param {*} param0 .
 * @returns {Void} component
 */
export const DragAndDropMoons = ({
  placeholder,
  handledFiles,
  handledFilesReject = null,
  maxFiles,
  img,
  styles,
  typeFiles,
  showAcceptedFiles
}) => {
  const {
    acceptedFiles, getRootProps, getInputProps
  } = useDropzone({
    maxFiles,
    onDropAccepted: handledFiles,
    onDropRejected: handledFilesReject,
    accept: typeFiles
  })

  return (
    <>
      <div {...getRootProps({ className: sheetStyles.containerDrop })}>
        <input {...getInputProps()} />
        {
          img
        }
        {
          placeholder && <p>{placeholder}</p>
        }
      </div>
      {
        (showAcceptedFiles && acceptedFiles.length > zero)
        && (
          <>
            <br />
            <h5>{fileListLabel}</h5>
            <ul className={sheetStyles.listFilesSucces}>
              {
                acceptedFiles.map((file, index) => (
                  <li key={file.size + index}>
                    {file.name}
                  </li>
                ))
              }
            </ul>
          </>
        )
      }
    </>
  )
}

DragAndDropMoons.propTypes = {
  placeholder: PropTypes.string,
  handledFiles: PropTypes.func.isRequired,
  maxFiles: PropTypes.number.isRequired,
}

DragAndDropMoons.defaultProps = {
  placeholder: '',
}
