import { updatePlanning, getPlanningData } from '../../../7-services/App/Planning.service'
import PlanningModel from '../../../6-models/App/Planning.model'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * validate pictures. planning PUT for files valid pictures state/status
 * @param {*} param0 .
 * @param {String} param0.token users'sessiontoken
 * @param {String} param0.planningId Planning objectId to edit
 * @param {Function} param0.setInProgress set progress flag
 * @param {Function} param0.handleSnack open snackbar
 * @param {Function} param0.historyApp router
 * @param {Function} param0.setValues set plannign values
 * @returns {Void} api call
 */
export const validatePictures = async ({
  token, planningId, setInProgress, handleSnack, historyApp, setValues
}) => {
  try {
    setInProgress(true)
    const updatePlannReq = {
      state: 'pictures_uploaded',
      status: 'valid_pictures'
    }
    await updatePlanning(token, planningId, updatePlannReq)
    const updatedData = await getPlanningData(token, planningId)
    const plannModel = new PlanningModel(updatedData)
    setValues(plannModel)
    // historyApp.push(`/${prefijApp}/planning-validation/${planningId}/diagnose`)
  } catch (error) {
    handleSnack(true, 'Error al actualizar la Planeación', 'error')
  } finally {
    setInProgress(false)
  }
}

/**
 * reject pictures. udpates state/status for invalid pictures
 * @param {*} param0 .
 * @param {String} param0.token users'sessiontoken
 * @param {String} param0.planningId Planning objectId to edit
 * @param {Function} param0.setInProgress set progress flag
 * @param {Function} param0.handleSnack open snackbar
 * @param {Function} param0.historyApp router
 * @returns {Void} api call
 */
export const rejectPictures = async ({
  token, planningId, setInProgress, handleSnack, historyApp
}) => {
  try {
    setInProgress(true)
    const updatePlannReq = {
      state: 'initial',
      status: 'invalid_pictures'
    }
    await updatePlanning(token, planningId, updatePlannReq)
    historyApp.push(`/${prefijApp}/patients`)
  } catch (error) {
    handleSnack(true, 'Error al actualizar la Planeación', 'error')
  } finally {
    setInProgress(false)
  }
}

/**
 * check if buttons should be disabled by state/status
 * @param {String} status Planning status
 * @returns {Boolean} should disable
 */
export const disableActions = (status) => status !== 'waiting_for_review'
