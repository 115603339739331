const ValidatePicturesMessages = {
  message1: 'Rechazar',
  message2: 'Aprobar',
  titleValidate: 'validar fotos de [patient]',
  titleDiagnose: 'visualizar fotos de [patient]',
  trueSmile: 'De frente sonrisa natural',
  profileFront: 'De frente serio',
  profileRight: 'De lado serio',
  frontOcclusion: 'De frente en oclusión',
  rightLateralOcclusion: 'Lateral derecha oclusión',
  leftLateralOcclusion: 'Lateral izquierda oclusión',
  superiorOcclusion: 'Oclusal superior',
  lowerOcclusion: 'Oclusal inferior',
  images: [
    'https://fondosmil.com/fondo/17010.jpg',
    'https://thegarnetmine.com/wp-content/uploads/2020/03/IMG_6126-416x900.png',
    'https://i.pinimg.com/originals/f8/95/9f/f8959fdde775cf3b361532cc509e7f21.jpg',
    'https://thegarnetmine.com/wp-content/uploads/2020/03/IMG_6126-416x900.png',
    'https://mecaluxmx.cdnwm.com/documents/20197/2221244/Soluciones+producto+hombre-Almac%C3%A9n+vertical+autom%C3%A1tico+Clasimat-es_ES.jpg/a6f98622-cae2-4d1f-b6e8-9def77eeb441?t=1484038527000&e=jpg&imageThumbnail=3',
    'https://fondosmil.com/fondo/17010.jpg',

  ]
}

export default ValidatePicturesMessages
