/* eslint-disable max-statements */
/* eslint-disable import/prefer-default-export */
import { updatePassService } from '../../../7-services/LoginService/Login.service'
import { errorCodes } from '../../../8-common/Constants'

/**
 * handle submit
 * @param {*} event event
 * @param {*} formValues form values
 * @param {*} param2 operations
 * @returns {Void} api call
 */
export const handleSubmitFormUpdate = async (
  event,
  formValues,
  { handleSnack, setInProgress }
) => {
  event.preventDefault()

  const messageError = validateUpdateForm(formValues)
  if (messageError) {
    handleSnack(true, messageError, 'error')
    return
  }

  try {
    setInProgress(true)
    delete formValues.repeatPass
    await updatePassService(formValues)
    handleSnack(true, 'Tu petición fue recibida con éxito', 'success')
    setInProgress(false)
    window.location = '/login'
  } catch (error) {
    console.error(error)
    handleSnack(true, errorCodes[error.code] || 'Error al actualizar contraseña', 'error')
    setInProgress(false)
  }
}

/**
 * form validation
 * @param {*} formValues form values
 * @returns {String} messages if invalid
 */
const validateUpdateForm = (formValues) => {
  const { password, repeatPass } = formValues
  let message = ''

  if ((password !== repeatPass) || password === '' || repeatPass === '') {
    message = 'Las contraseñas ingresadas no coinciden y/o no son válidas'
  }

  return message
}
