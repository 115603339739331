/* eslint-disable max-statements */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, {
  useContext, useEffect, useState, useRef
} from 'react'
import moment from 'moment-timezone'
import { AppContext } from '../../../4-hooks/contexts/App/App.context'
import { useForm } from '../../../4-hooks/useForm'
import { ButtonMoons, InputMoons } from '../../UI'
import { IconMoons } from '../../UI/IconMoons/IconMoons'
import messages from './Comments.messages'
import styles from './Comments.module.scss'
import { getClassType, getIconType, addComment, addReplanningComment } from './Comments.logic'
import { getComments } from '../../../7-services/App/Comment.service'
import LoaderMoons from '../../UI/LoaderMoons/LoaderMoons'
import { getTimezone } from '../../../8-common/UtilFunctions'

/**
 * Comments component
 * @param {String} planningId Planning objectId to relate comments to
 * @param {String} countryOps patient's country of operations
 * @param {Object} selectedPlanning Planning
 * @returns {Void} component
 */
export const Comments = ({ planningId, countryOps, planning }) => {
  const { stateApp: { functions, user } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const { userType, name, sessionToken } = user
  const [isLoading, setIsLoading] = useState(false)
  const [comments, setComments] = useState([])
  const [
    formValues,,
    handleInputChange,
    reset,
    progress,
    setInProgress
  ] = useForm({ comment: '' })
  const { comment } = formValues
  const isRendered = useRef(false)

  useEffect(() => {
    isRendered.current = true
    setIsLoading(true)
    getComments(sessionToken, planningId)
      .then(res => {
        if (Array.isArray(res) && isRendered.current) {
          const formattedComments = res.map(commentElem => ({
            text: commentElem.comment || '',
            type: commentElem?.user?.userType || 'FUTURA LABS',
            date: moment(commentElem?.createdAt || null).tz(getTimezone(countryOps)).format('DD-MM-YYYY'),
            user: commentElem?.user?.name || 'FUTURA LABS'
          })).reverse()
          setComments(formattedComments)
          setIsLoading(false)
        }
      })
      .catch(error => {
        setIsLoading(false)
        handleSnack(true, 'Error al cargar comentarios', 'error')
      })

    return () => {
      isRendered.current = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    isLoading ? <LoaderMoons size={120} /> : (
      <div className={styles.commentsContainer}>
        <div className={[styles.structureComment,
          styles.addComment,
          getClassType(userType)].join(' ')}
        >
          <IconMoons name={getIconType(userType)} />
          <div>
            <h3>{name}</h3>
            <span>{moment().tz(getTimezone(countryOps)).format('DD-MM-YYYY')}</span>
            <InputMoons
              type="text"
              name="comment"
              autoComplete="off"
              value={comment}
              onChange={handleInputChange}
              multiline
            />
          </div>
          <ButtonMoons
            size="small"
            isprogress={Number(progress)}
            onClick={() => addComment({
              sessionToken,
              planningId,
              comment,
              userType,
              name,
              comments,
              setComments,
              setInProgress,
              reset,
              handleSnack
            })}
          >
            {messages.message1}
          </ButtonMoons>
          {
            (planning && planning.state === 'planned') && (
              <ButtonMoons
                size="small"
                isprogress={Number(progress)}
                onClick={() => addReplanningComment({
                  planningId,
                  comment,
                  comments,
                  setComments,
                  setInProgress,
                  reset,
                  handleSnack,
                  user
                })}
              >
                {messages.replanning.button}
              </ButtonMoons>
            )}
        </div>

        {comments.map((commentElem, idx) => (
          <div
            className={[styles.structureComment, getClassType(commentElem.type)].join(' ')}
            key={`comment-${commentElem.user}-${commentElem.date}-${idx}`}
          >
            <IconMoons name={getIconType(commentElem.type)} />
            <div>
              <h3>{commentElem.user}</h3>
              <span>{commentElem.date}</span>
              <p>{commentElem.text}</p>
            </div>
          </div>
        ))}
      </div>
    )
  )
}
