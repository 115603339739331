/* eslint-disable max-statements */
import moment from 'moment-timezone'
import { ProfileTypes } from '../../../1-routers/Profiles/Propfiles.type'
import { createComment } from '../../../7-services/App/Comment.service'
import { updatePlanning } from '../../../7-services/App/Planning.service'
import messages from './Comments.messages'
import styles from './Comments.module.scss'

/**
 * Add comment
 * @param {*} param0 .
 * @param {String} param0.sessionToken authenticated user's session token
 * @param {String} param0.planningId Planning objectId to relate comment to
 * @param {String} param0.comment Comment string to publish
 * @param {String} param0.userType auth user's role
 * @param {String} param0.name auth user's name
 * @param {Array} param0.comments list of comments
 * @param {Function} param0.setComments set state for comments
 * @param {Function} param0.setInProgress change progress flag
 * @param {Function} param0.reset reset form
 * @param {Function} param0.handleSnack open snackbar
 * @returns {Void} api call and state update
 */
export const addComment = async ({
  sessionToken, planningId, comment, userType, name, comments, setComments,
  setInProgress, reset, handleSnack
}) => {
  if (comment === '') {
    return
  }

  try {
    setInProgress(true)
    await postComment(sessionToken, planningId, comment, comments, userType, name, reset, setComments)
    setInProgress(false)
    handleSnack(true, 'Comentario publicado exitosamente', 'success')
  } catch (error) {
    setInProgress(false)
    handleSnack(true, 'Error al publicar comentario', 'error')
  }
}

/**
 * get class for comment by user
 * @param {*} userType current user's type role
 * @returns {String} classname
 */
export const getClassType = (userType) => (
  userType === ProfileTypes.doctor ? styles.doctorComment : styles.moonsComment
)

/**
 * get icon name for comment by user
 * @param {*} userType current user's type role
 * @returns {String} icon name
 */
export const getIconType = (userType) => (
  userType === ProfileTypes.doctor ? messages.icon1 : messages.icon2
)


/**
 * Add comment
 * @param {*} param0 .
 * @param {String} param0.sessionToken authenticated user's session token
 * @param {String} param0.planningId Planning objectId to relate comment to
 * @param {String} param0.comment Comment string to publish
 * @param {String} param0.userType auth user's role
 * @param {String} param0.name auth user's name
 * @param {Array} param0.comments list of comments
 * @param {Function} param0.setComments set state for comments
 * @param {Function} param0.setInProgress change progress flag
 * @param {Function} param0.reset reset form
 * @param {Function} param0.handleSnack open snackbar
 * @returns {Void} api call and state update
 */
export const addReplanningComment = async ({
  planningId, comment, comments, setComments,
  setInProgress, reset, handleSnack, user
}) => {
  if (comment === '') {
    handleSnack(true, messages.replanning.info, 'info')
    return
  }

  try {
    setInProgress(true)
    await postComment(user.sessionToken, planningId, comment, comments, user.userType, user.name, reset, setComments)
    const planningUpdate = {
      updatedBy: user.objectId,
      state: 'replanning',
      status: 'on_going'
    }
    await updatePlanning(user.sessionToken, planningId, planningUpdate)
    handleSnack(true, messages.replanning.success, 'success')
  } catch (error) {
    handleSnack(true, messages.replanning.error, 'error')
  } finally {
    setInProgress(false)
  }
}

/**
 * Posts a comment
 * @param {String} sessionToken user session token
 * @param {String} planningId Planning id
 * @param {String} comment Poste comment
 * @param {Array} comments Posted comments
 * @param {String} userType Role
 * @param {String} name user's name
 * @param {Function} reset Clears form values
 * @param {Function} setComments Comments state function
 * @return {*} void
 */
const postComment = async (sessionToken, planningId, comment, comments, userType, name, reset, setComments) => {
  const newComment = await createComment({ token: sessionToken, planningId, comment })
  setComments([
    ...comments,
    {
      text: newComment.comment || comment,
      type: newComment?.user?.userType || userType,
      date: moment(newComment?.createdAt || null).utc(true).format('DD-MM-YYYY'),
      user: newComment?.user?.name || name
    },
  ].reverse())
  reset()
}
