import { RadioModelRadioButtonsGroupMoons } from '../../UI/RadioButtonsGroupMoons/Radio.model'

const TreatmentMessages = {
  title: 'Preferencias de Ortodoncia de [patient]',
  option1: 'Mantener espacios (si aplica) ',
  placeHolder1: 'Especifica entre que dientes',
  option2: 'Corregir clase molar izquierda',
  placeHolder2: 'Especifica',
  option3: 'Corregir clase molar derecha',
  placeHolder3: 'Especifica',
  option4: 'Corregir clase canina izquierda',
  placeHolder4: 'Especifica',
  option5: 'Corregir clase canina derecha',
  placeHolder5: 'Especifica',
  option6: 'Corregir sobremordida vertical',
  option7: 'Corregir sobremordida horizontal',
  option8: 'Extraer piezas',
  placeHolder8: '¿Qué piezas?',
  option9: 'Consideraciones adicionales',
  placeHolder9: 'Notas',
  option10: 'Arcada a tratar',
  option11: 'Alinear arcadas mediante',
  option12: 'Hacer IPR',
  button: 'Guardar',
  buttonBack: 'Paso anterior',
  optionalMsg: 'Este paso no es obligatorio, sin embargo, mientras más información tengamos, podemos mejorar los planes de tratamiento.',
  optionalLink: 'Ir al siguiente paso',
  optionsVerticalOverbite: [
    new RadioModelRadioButtonsGroupMoons({
      text: 'Sí',
      value: 'yes'
    }),
    new RadioModelRadioButtonsGroupMoons({
      text: 'No',
      value: 'no'
    })
  ],
  optionsHorizontalOverbite: [
    new RadioModelRadioButtonsGroupMoons({
      text: 'Sí',
      value: 'yes'
    }),
    new RadioModelRadioButtonsGroupMoons({
      text: 'No',
      value: 'no'
    })
  ],
  optionsArcade: [
    {
      name: 'upper',
      label: 'Superior'
    },
    {
      name: 'low',
      label: 'Inferior'
    },

  ],
  optionsAlignArchesUsing: [
    {
      name: 'lateralExpansion',
      label: 'Expansión lateral'
    },
    {
      name: 'proclination',
      label: 'Proinclinación'
    },
    {
      name: 'wear',
      label: 'Desgaste'
    },
    {
      name: 'extraction',
      label: 'Extracción'
    }
  ],
  optionsDoIPR: [
    new RadioModelRadioButtonsGroupMoons({
      text: 'Sí',
      value: 'yes'
    }),
    new RadioModelRadioButtonsGroupMoons({
      text: 'No',
      value: 'no'
    })
  ]
}

export default TreatmentMessages
