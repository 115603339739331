/* eslint-disable import/prefer-default-export */
import { deleteAllItemsLStorage, getItemLStorage, saveInfoLStorage } from '../../../7-services/LocalStorage.service'
import { logoutUserService } from '../../../7-services/LoginService/Login.service'
import { appTypes } from './App.types'
import { twoHun } from '../../../8-common/Constants'

/**
 * app reducer
 * @param {*} state .
 * @param {*} action .
 * @returns {Void} state change
 */
export const appReducer = (state = {}, action) => {
  switch (action.type) {
  case appTypes.addFunction:
    return {
      ...state,
      functions: {
        ...state.functions,
        ...action.payload
      }
    }
  case appTypes.updateHistory:
    return {
      ...state,
      historyApp: action.payload,
    }

  case appTypes.updateUser: {
    const result = getItemLStorage()
    if (result.success) {
      saveInfoLStorage('app', { ...result.item, user: action.payload })
    } else {
      saveInfoLStorage('app', { user: action.payload })
    }

    return {
      ...state,
      user: action.payload
    }
  }

  case appTypes.logoutUser:

    setTimeout(() => {
      deleteAllItemsLStorage()
      const { historyApp, user: { sessionToken } } = state
      historyApp.replace('/login')
      logoutUserService(sessionToken)
    }, twoHun)

    return {
      ...state,
      user: {}
    }

  default:
    return null
  }
}
