/* eslint-disable import/prefer-default-export */
import { scrollTopInit } from '../../../8-common/UtilFunctions'
import PatientPlanningViewMessages from './PatientPlanningView.messages'

const zero = 0
const one = 1
const two = 2
const three = 3

/**
 * set active step for route component
 * @param {*} param0 .
 * @param {String} param0.component compontnet route
 * @param {*} param1 .
 * @param {Function} param1.setActiveStep change active component by route
 * @returns {Void} handles current step
 */
export const setStepActive = (
  { component },
  { setActiveStep }
) => {
  if (component) {
    switch (component) {
    case PatientPlanningViewMessages.medicalHistory:
      setActiveStep(zero)
      break
    case PatientPlanningViewMessages.treatment:
      setActiveStep(one)
      break
    case PatientPlanningViewMessages.picturesPatient:
      setActiveStep(two)
      break
    case PatientPlanningViewMessages.collectionMethod:
      setActiveStep(three)
      break
    default:
      break
    }
    scrollTopInit('body-home')
  }
}
