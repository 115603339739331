/* eslint-disable import/prefer-default-export */
/* eslint-disable no-negated-condition */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-process-env */
/* eslint-disable no-useless-catch */
import { basicAuthHeader } from './UtilFunctions'
import { deleteAllItemsLStorage } from '../7-services/LocalStorage.service'
import { logoutUserService } from '../7-services/LoginService/Login.service'
import { fourOOne, HTTP_STATUS } from './Constants'

const urlBase = process.env.REACT_APP_URL_API_B2B
const headers = { 'Content-Type': 'application/json' }

/**
 * creates generic moons request
 * @param {*} param0 .
 * @returns {Object} server response
 */
export const requestMoons = async ({
  endpoint = '',
  method = 'GET',
  body = null,
  params = null,
  useBasicAuthHeader = false,
  customHeaders = {}
}) => {
  try {
    const _headers = { ...headers, ...customHeaders }
    const init = {
      method,
      headers: useBasicAuthHeader ? { ...headers, ...basicAuthHeader() } : _headers
    }

    const resp = await fetch(
      `${urlBase}${endpoint}${params ? buildGetUrl(params) : ''}`,
      body ? { ...init, body: JSON.stringify(body) } : init
    )

    if (!resp.ok && resp.status === fourOOne) {
      const { Authorization } = _headers
      deleteAllItemsLStorage()
      window.location = '/login'
      logoutUserService(Authorization)
    }

    if (resp.ok && resp.status === HTTP_STATUS.noContent) {
      return
    }

    if (resp.ok) {
      const cloudResp = await resp.json()
      return cloudResp
    }
    throw await resp.json()
  } catch (err) {
    throw err
  }
}

/**
 * build get url with params
 * @param {*} params .
 * @returns {Object} response
 */
const buildGetUrl = (params) => {
  let stringParameters = ''
  let _id = ''

  if (typeof params !== 'undefined') if (typeof params == 'number' || typeof params == 'string') _id = params.toString()

  if (typeof params !== 'undefined' && _id === '') {
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const valor = params[key]
        stringParameters += stringParameters === '' ? `${key}=${valor}` : `&${key}=${valor}`
      }
    }
  }

  if (stringParameters !== '' || _id !== '') {
    return stringParameters !== '' ? `?${stringParameters}` : `/${_id}`
  }
  return ''
}
