/* eslint-disable max-statements */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-process-env */
import { appTypes } from '../../../4-hooks/contexts/App/App.types'
import { UserModel } from '../../../6-models/App/User.model'
import { loginUserService } from '../../../7-services/LoginService/Login.service'
import { validarEmail } from '../../../8-common/UtilFunctions'

const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * handel submit
 * @param {*} param0 .
 * @param {*} param1 .
 * @returns {Void} component
 */
export const handleSubmitFormLogin = async ({ event, formValues }, {
  reset, handleSnack, setInProgress, historyApp, dispatch
}) => {
  event.preventDefault()

  const messageError = validateLoginForm(formValues)
  if (messageError) {
    handleSnack(true, messageError, 'error')
    return
  }

  try {
    setInProgress(true)
    const res = await loginUserService(formValues)

    if (res && res.sessionToken) {
      reset()
      setInProgress(false)
      dispatch({ type: appTypes.updateUser, payload: new UserModel(res) })
      historyApp.push(`/${prefijApp}/home`)
    }
  } catch (error) {
    console.error(error)
    setInProgress(false)
    handleSnack(true, 'Usuario y/o contraseña inválidos.', 'error')
  }
}

/**
 * validate form
 * @param {*} formValues .
 * @returns {String} message if invalid
 */
const validateLoginForm = (formValues) => {
  const { email, password } = formValues
  let message = ''
  const lengthPass = 4

  /**
   * is message var empty
   * @returns {Boolean} is empty
   */
  const isEmptyMessage = () => message === ''

  if (isEmptyMessage() && (email === '' || password === '')) {
    message = 'Debes ingresar un correo y una contraseña.'
  } else if (isEmptyMessage() && password.length < lengthPass) {
    message = `La contraseña debe tener mínimo ${lengthPass} caracteres.`
  } else if (isEmptyMessage() && !validarEmail(email)) {
    message = 'Debes ingresar un email valido.'
  }

  return message
}
