/* eslint-disable react/jsx-no-literals */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-process-env */
/* eslint-disable import/prefer-default-export */
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { MenuModelNavBarMoons } from '../../../3-components/UI/NavBarMoons/Menu.model'
import { NavBarMoons } from '../../../3-components/UI/NavBarMoons/NavBarMoons'
import { AppContext } from '../../../4-hooks/contexts/App/App.context'
import { appTypes } from '../../../4-hooks/contexts/App/App.types'
import { twoHun } from '../../../8-common/Constants'
import styles from './HomeView.module.scss'

const prefijApp = process.env.REACT_APP_PREFIJ_APP

// layout
/**
 * Home component. Dispatches user routes by role
 * @param {*} param0 .
 * @returns {Void} home, navbar view
 */
export const HomeView = ({ children, menuAccount = [], links = [] }) => {
  const { dispatch, stateApp } = useContext(AppContext)
  const history = useHistory()
  const {
    user: {
      name, email, objectId, role: [role], publicKey
    },
  } = stateApp

  const _menuAccount = [
    new MenuModelNavBarMoons({
      text: 'Actualizar',
      action: () => {
        history.push(`/${prefijApp}/user/${objectId}`)
      }
    }),
    new MenuModelNavBarMoons({
      text: 'Actualizar contraseña',
      action: async () => {
        await dispatch({ type: appTypes.logoutUser })
        setTimeout(() => {
          window.location = `/update-password/${publicKey}`
        }, twoHun)
      }
    }),
    new MenuModelNavBarMoons({
      text: 'Cerrar sesión',
      action: () => {
        dispatch({ type: appTypes.logoutUser })
      }
    })
  ]

  useEffect(() => {
    dispatch({ type: appTypes.updateHistory, payload: history })
  }, [dispatch, history])

  return (
    <div className={styles.container}>
      <NavBarMoons
        menuAccount={_menuAccount}
        account={{ name, role: role.name, email }}
        links={links}
      />
      <div
        id="body-home"
        className={styles.body}
      >
        {children}
      </div>
    </div>
  )
}
