/* eslint-disable max-statements */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-process-env */
const isProd = process.env.REACT_APP_IS_PROD === '1'
const prefijLStorage = 'B2B'

/**
 * save data to LS
 * @param {*} item to save
 * @param {*} info type of data
 * @returns {Object} with success or error fields
 */
export const saveInfoLStorage = (item, info) => {
  try {
    const itemSave = `${prefijLStorage}_${item}`
    const _info = typeof info === 'string' ? info : JSON.stringify(info)
    const key = isProd ? btoa(itemSave) : itemSave
    const data = isProd ? btoa(_info) : _info
    localStorage.setItem(key, data)
    return { success: true }
  } catch (error) {
    return { success: false, error }
  }
}

/**
 * get LS item by key
 * @param {*} key LS key
 * @returns {Object} LS item
 */
export const getItemLStorage = (key) => {
  try {
    const keySave = `${prefijLStorage}_${key}`
    const _key = isProd ? btoa(keySave) : keySave
    let item = localStorage.getItem(_key)
    if (isProd && item) {
      item = atob(item)
    }
    return { success: true, item }
  } catch (error) {
    return { success: false, error }
  }
}

/**
 * delete LS item by key
 * @param {*} key LS key
 * @returns {Object} success or error fields
 */
export const deleteItemLStorage = (key) => {
  try {
    const keySave = `${prefijLStorage}_${key}`
    const _key = isProd ? btoa(keySave) : keySave
    localStorage.removeItem(_key)
    return { success: true }
  } catch (error) {
    return { success: false, error }
  }
}

/**
 * Delete all LS items
 * @returns {Object} success or error fields
 */
export const deleteAllItemsLStorage = () => {
  try {
    for (const key in localStorage) {
      if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
        let _key = null
        try {
          _key = isProd ? atob(key) : key
        } catch (error) {
          _key = key
        }
        if (Object.prototype.hasOwnProperty.call(localStorage, key)
          && _key.includes(prefijLStorage)
        ) {
          localStorage.removeItem(key)
        }
      }
    }
    return { success: true }
  } catch (error) {
    return { success: false, error }
  }
}

/**
 * get object data
 * @param {*} key LS item key
 * @returns {Object} item by key
 */
export const getStoreGlobal = (key) => {
  const store = getItemLStorage(key)
  return store.success && store.item ? JSON.parse(store.item) : null
}
