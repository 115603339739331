/* eslint-disable import/prefer-default-export */
import React from 'react'
import { IconButton } from '@material-ui/core'
import { IconMoons } from '../IconMoons/IconMoons'
import styles from './IconButtonMoons.module.scss'

/**
 * Reusable custom icon button
 * @param {*} param0 .
 * @param {String} param0.icon which material icon to use
 * @param {String} param0.color material color
 * @param {String} param0.className style classname
 * @param {*} param0.rest rest props
 * @returns {Void} componente
 */
export const IconButtonMoons = ({
  icon, color, className, ...rest
}) => (
  <IconButton
    {...rest}
    color={color || 'primary'}
    className={[styles[color], className].join(' ')}
  >
    <IconMoons name={icon} />
  </IconButton>
)
