import { OptionModelSelectMoons } from '../../UI/SelectMoons/Option.model'

const CreateAccountFormMessages = {
  FormH1: 'Nuevo Usuario Administrador',
  titleEdit: 'Editar [name]',
  addrsTitle: 'Dirección',
  name: 'Nombre Completo',
  username: 'Correo electrónico',
  phone: 'Celular',
  countryOps: 'País',
  role: 'Rol',
  street: 'Calle y # Exterior',
  apt: 'No. interior',
  neighborhood: 'Colonia',
  city: 'Ciudad',
  state: 'Estado',
  zip: 'CP',
  textButton: 'Crear',
  txtBtnEdit: 'Guardar',
  backButton: 'Doctores',
  countries: [
    new OptionModelSelectMoons({
      text: 'México',
      value: 'MX'
    }),
    new OptionModelSelectMoons({
      text: 'Colombia',
      value: 'CO'
    })
  ],
  profiles: [
    new OptionModelSelectMoons({
      text: 'Administrador',
      value: 'SUPER_ADMIN'
    })
  ],
  profilesDr: [
    new OptionModelSelectMoons({
      text: 'Doctor',
      value: 'DOCTOR'
    }),
    new OptionModelSelectMoons({
      text: 'Doctor Futura',
      value: 'DOCTOR_MOONS'
    })
  ]
}

export default CreateAccountFormMessages
