import { ROLES } from '../../../../../8-common/Constants'
import { OptionModelSelectMoons } from '../../../../UI/SelectMoons/Option.model'

const PlanningStates = [
  new OptionModelSelectMoons({
    text: 'Inicial',
    value: 'initial',
    assignableRoles: [ROLES.doctor],
    status: [
      new OptionModelSelectMoons({
        text: 'Fotos inválidas',
        value: 'invalid_pictures'
      })
    ]
  }),
  new OptionModelSelectMoons({
    text: 'Fotos subidas',
    value: 'pictures_uploaded',
    assignableRoles: [ROLES.doctor],
    status: [
      new OptionModelSelectMoons({
        text: 'Esperando revisión',
        value: 'waiting_for_review'
      }),
      new OptionModelSelectMoons({
        text: 'Fotos válidas',
        value: 'valid_pictures'
      })
    ]
  }),
  new OptionModelSelectMoons({
    text: 'Diagnosticado',
    value: 'diagnosed',
    assignableRoles: [ROLES.doctor],
    status: [
      new OptionModelSelectMoons({
        text: 'Candidato',
        value: 'candidate'
      })
    ],
  }),
  new OptionModelSelectMoons({
    text: 'STL Requerido',
    value: 'stl_required',
    assignableRoles: [ROLES.doctor],
    status: [
      new OptionModelSelectMoons({
        text: 'Esperando STL',
        value: 'waiting_stl'
      }),
      new OptionModelSelectMoons({
        text: 'Recolección solicitada',
        value: 'pickup_requested'
      }),
      new OptionModelSelectMoons({
        text: 'Esperando cita',
        value: 'waiting_appointment'
      })
    ],
  }),
  new OptionModelSelectMoons({
    text: 'Cita',
    value: 'appointment',
    assignableRoles: [ROLES.doctor],
    status: [
      new OptionModelSelectMoons({
        text: 'Agendada',
        value: 'booked'
      }),
      new OptionModelSelectMoons({
        text: 'No atendió',
        value: 'not_attended'
      }),
      new OptionModelSelectMoons({
        text: 'Atendió',
        value: 'attended'
      })
    ],
  }),
  new OptionModelSelectMoons({
    text: 'STL Cargado',
    value: 'stl_uploaded',
    assignableRoles: [ROLES.doctor],
    status: [
      new OptionModelSelectMoons({
        text: 'Esperando planeación',
        value: 'waiting_for_planning'
      })
    ],
  }),
  new OptionModelSelectMoons({
    text: 'Esperando impresión',
    value: 'printing',
    assignableRoles: [ROLES.doctor],
    status: [
      new OptionModelSelectMoons({
        text: '',
        value: 'waiting_printing'
      }),
    ],
  }),
  new OptionModelSelectMoons({
    text: 'Impresión recibida',
    value: 'scanning_printing',
    assignableRoles: [ROLES.doctor],
    status: [
      new OptionModelSelectMoons({
        text: 'Escaneando impresión',
        value: 'printing_received'
      })
    ],
  }),
  new OptionModelSelectMoons({
    text: 'Delimitado',
    value: 'delimited',
    assignableRoles: [ROLES.doctor],
    status: [
      new OptionModelSelectMoons({
        text: 'En proceso',
        value: 'on_going'
      }),
      new OptionModelSelectMoons({
        text: 'Listo',
        value: 'ready'
      }),
    ],
  }),
  new OptionModelSelectMoons({
    text: 'Planeado',
    assignableRoles: [ROLES.doctor],
    value: 'planned'
  }),
  new OptionModelSelectMoons({
    text: 'Replaneación',
    assignableRoles: [ROLES.doctor],
    value: 'replanning',
    status: [
      new OptionModelSelectMoons({
        text: 'En proceso',
        value: 'on_going'
      }),
      new OptionModelSelectMoons({
        text: 'Listo',
        value: 'ready'
      }),
    ],
  }),
  new OptionModelSelectMoons({
    text: 'Aprobado',
    assignableRoles: [ROLES.doctor],
    value: 'approved'
  }),
  new OptionModelSelectMoons({
    text: 'Exportado',
    assignableRoles: [ROLES.doctor],
    value: 'exported',
    status: [
      new OptionModelSelectMoons({
        text: 'En proceso',
        value: 'on_going'
      }),
      new OptionModelSelectMoons({
        text: 'Listo',
        value: 'ready'
      }),
    ],
  }),
  new OptionModelSelectMoons({
    text: 'Producción',
    assignableRoles: [ROLES.doctor],
    value: 'production',
    status: [
      new OptionModelSelectMoons({
        text: 'Basado',
        value: 'based'
      })
    ],
  }),
  new OptionModelSelectMoons({
    text: 'Enviado',
    assignableRoles: [ROLES.doctor],
    value: 'send'
  }),
  new OptionModelSelectMoons({
    text: 'Entregado',
    assignableRoles: [ROLES.doctor],
    value: 'delivered'
  }),
  new OptionModelSelectMoons({
    text: 'Cerrado',
    assignableRoles: [ROLES.doctor],
    value: 'closed',
    status: [
      new OptionModelSelectMoons({
        text: 'No Candidato',
        value: 'no_candidate'
      }),
      new OptionModelSelectMoons({
        text: 'Terminada',
        value: 'finished'
      })
    ],
  })
]

export default PlanningStates
