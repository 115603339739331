/* eslint-disable max-statements */
import { uploadPicture } from '../../../../../7-services/App/Pictures.service'
import { updatePlanning } from '../../../../../7-services/App/Planning.service'
import stlMessages from '../StlMethod.messages'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * Handle upload stl file
 * updates planning with state: stl_required, status: waiting_stl
 * uploads file
 * updates planning with state: stl_uploaded, status: waiting_for_planning
 * @param {*} param0 .
 * @param {Object} param0.user user data for session token
 * @param {Function} param0.historyApp router
 * @param {Object} param0.fileSelected file object
 * @param {String} param0.planningId Planning objectId for PUT
 * @param {String} param0.customerId Patient's customerId
 * @param {*} param1 .
 * @param {Function} param1.handleSnack open snackbar
 * @param {Function} param1.setInProgress set progress flag
 * @returns {Void} async function
 */
const handleUpload = async (
  {
    user, historyApp, fileSelected, planningId, customerId
  },
  { handleSnack, setInProgress }
) => {
  if (!fileSelected) {
    handleSnack(true, 'Debes escoger un archivo', 'error')
    return
  }

  setInProgress(true)

  try {
    const putPlannReq = {
      stlMethod: stlMessages.uploadStl,
      state: 'stl_required',
      status: 'waiting_stl',
      updatedBy: user.objectId
    }
    await updatePlanning(user.sessionToken, planningId, putPlannReq)
  } catch (error) {
    handleSnack(true, 'Error al elegir método de recolección', 'error')
    setInProgress(false)
    return
  }

  const putPlannReq = {
    state: 'stl_uploaded',
    status: 'waiting_for_planning',
    updatedBy: user.objectId
  }
  try {
    const uploaded = await uploadPicture(fileSelected, 'stlFileUrl', customerId)
    putPlannReq.stlFileUrl = uploaded?.data?.file_url
  } catch (error) {
    setInProgress(false)
    handleSnack(true, 'Error al subir archivo STL', 'error')
    return
  }

  try {
    await updatePlanning(user.sessionToken, planningId, putPlannReq)
    handleSnack(true, 'Éxito al guardar archivo y método de recolección', 'success')
    historyApp.push(`/${prefijApp}/home`)
  } catch (error) {
    handleSnack(true, 'Error al guardar información de método de recolección', 'error')
  } finally {
    setInProgress(false)
  }
}

export default handleUpload
