/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable import/prefer-default-export */
/**
 * Treatment class dto
 */
export class TreatmentModel {
  /**
   * constructor
   * @param {*} data Treatment dto
   */
  constructor(data = {}) {
    this.keepSpaces = data?.settings?.keepSpaces || ''
    this.leftMolarClass = data?.settings?.leftMolarClass || ''
    this.rightMolarClass = data?.settings?.rightMolarClass || ''
    this.leftCanineClass = data?.settings?.leftCanineClass || ''
    this.rightCanineClass = data?.settings?.rightCanineClass || ''
    this.verticalOverbite = data?.settings?.verticalOverbite || ''
    this.horizontalOverbite = data?.settings?.horizontalOverbite || ''
    this.arcade = data?.settings?.arcade || {
      upper: false,
      low: false
    }
    this.alignArchesUsing = data?.settings?.alignArchesUsing || {
      lateralExpansion: false,
      proclination: false,
      wear: false,
      extraction: false
    }
    this.extractParts = data?.settings?.extractParts || ''
    this.doIPR = data?.settings?.doIPR || ''
    this.notes = data.notes || ''
  }
}
