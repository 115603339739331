/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import React from 'react'
import styles from './SnackMoons.module.scss'

/**
 * SnackMoons generic component
 * @param {*} props snack props
 * @returns {Void} component
 */
export const SnackMoons = (props) => {
  const {
    open, onClose, type, message, rest
  } = props

  /**
   * exit
   * @returns {Void} close
   */
  const exit = () => {
    onClose()
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={4000}
      open={open}
      onClose={exit}
      action={(
        <>
          <button
            onClick={exit}
            type="button"
            className={styles.btAction}
          >
            X
          </button>
        </>
      )}
      {...rest}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={type}
        onClose={exit}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  )
}
