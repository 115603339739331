/* eslint-disable import/prefer-default-export */
import React from 'react'
import { Step, StepLabel, Stepper } from '@material-ui/core'
import PropTypes from 'prop-types'
import { CardMoons } from '../CardMoons/CardMoons'
import styles from './StepperMoons.module.scss'

const zero = 0

/**
 * Stepper Moons component
 * @param {*} param0 .
 * @param {*} param0.titlesSteps list of step titles
 * @param {*} param0.contentFinish actions buttons
 * @param {*} param0.contentSteps list of components in stepper
 * @param {*} param0.activeStep current step component
 * @returns {Void} component
 */
export const StepperMoons = ({
  titlesSteps = [],
  contentFinish,
  contentSteps = [],
  activeStep = zero
}) => (
  <>
    <CardMoons className={styles.containerTitles}>
      <Stepper activeStep={activeStep}>
        {titlesSteps.map((label) => (
          <Step key={label}>
            <StepLabel><b className={styles.titleStep}>{label}</b></StepLabel>
          </Step>
        ))}
      </Stepper>
    </CardMoons>
    <div>
      {activeStep === titlesSteps.length ? (
        <div>
          {
            contentFinish
          }
        </div>
      ) : (
        <div>
          {
            contentSteps[activeStep]
          }
        </div>
      )}
    </div>
  </>
)

StepperMoons.propTypes = {
  titlesSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
  contentFinish: PropTypes.element,
  contentSteps: PropTypes.arrayOf(PropTypes.element).isRequired,
  activeStep: PropTypes.number
}
StepperMoons.defaultProps = {
  contentFinish: null,
  activeStep: null
}
