/* eslint-disable import/prefer-default-export */
import React from 'react'
import { Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'

/**
 * tooltip moons
 * @param {*} param0 .
 * @returns {Void} component
 */
export const TooltipMoons = ({ children, arrow = true, ...rest }) => (
  <Tooltip
    {...rest}
    arrow
  >
    {children}
  </Tooltip>
)

TooltipMoons.propTypes = {
  title: PropTypes.string.isRequired
}
