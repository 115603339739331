import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
import styles from './ConfirmationModal.module.scss'




const ConfirmationModal = ({ isOpen, setIsOpen, title, description, primaryButtonLabel, primaryButtonCta, isLoading }) => (
  <Dialog
    open={isOpen}
    fullWidth
    maxWidth="xs"

    onClose={() => setIsOpen(false)}
    classes={{
      paper: styles.modal
    }}
  >
    {isLoading ? (
      <DialogContent >
        <div className={styles.spinner}>
          <CircularProgress size={50} />
        </div>
      </DialogContent>
    ) :
      <>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{description}</DialogContent>
        <DialogActions>
          <Button onClick={() => primaryButtonCta()}>{primaryButtonLabel}</Button>
        </DialogActions>
      </>
    }
  </Dialog>
)

export default ConfirmationModal
