import { OptionModelSelectMoons } from '../../../UI/SelectMoons/Option.model'

const PlanningFormMessages = {
  h1New: 'Nueva planeación para [patient]',
  btnNewPlann: 'Nueva planeación',
  h1Edit: 'Editar planeación de [patient]',
  btnEditShipping: 'Editar Recolección',
  btnPlanningSummary: 'Ver Resumen',
  btnEditPatient: 'Editar Paciente',
  btnEditDoctor: 'Editar Doctor',
  backButton: 'Pacientes',
  lblState: 'Estado',
  lblStatus: 'Estatus',
  phAlignersRange: 'Rango de Alineadores',
  phUpperAlign: 'Alineadores Superiores',
  phLowerAlign: 'Alineadores Inferiores',
  phTreatMonths: 'Meses de Tratamiento',
  lblSeverity: 'Tipo de Paciente',
  lblPatientType: 'Severidad',
  phPatientNotes: 'Notas Paciente',
  phDoctorNotes: 'Notas Doctor',
  phDiagnoseNotes: 'Notas de diagnóstico',
  phDatasheet: 'Liga de la Ficha Técnica',
  lblIsPaid: 'Pagado',
  lblActive: 'Activo',
  phVideoUrl: 'Liga del Video',
  phDeliveryDate: 'Fecha Estimada de Entrega',
  phPaymentDate: 'Fecha de Pago',
  lblTimePayment: 'Hora de Pago',
  phApprovedDate: 'Fecha de Aprobación',
  lblTimeAppr: 'Hora de Aprobación',
  phFileTechnicalDatasheet: 'Subir Ficha Técnica',
  pharchformUrl: 'Link de Archform',
  phDelimitedModelUrl: 'Link de modelo delimitado',
  maxFilesDatash: 1,
  phFileVideo: 'Subir video Planeación',
  maxFilesVideo: 1,
  phFileStl: 'STL Arcada superior',
  phLowerArchFileStl: 'STL Arcada inferior',
  phFirstOcclusionFileStl: 'STL Primera oclusión',
  phSecondOcclusionFileStl: 'STL Segunda oclusión',
  maxFileStl: 1,
  prodFileStl: 'Subir STLs de producción',
  btnNew: 'Crear',
  btnEdit: 'Guardar',
  patientType: [
    new OptionModelSelectMoons({
      text: 'Simple',
      value: 'low'
    }),
    new OptionModelSelectMoons({
      text: 'Moderado',
      value: 'moderate'
    }),
    new OptionModelSelectMoons({
      text: 'Complejo',
      value: 'severe'
    })
  ],
  severity: [
    new OptionModelSelectMoons({
      text: 'Simple',
      value: 'low'
    }),
    new OptionModelSelectMoons({
      text: 'Moderado',
      value: 'moderate'
    }),
    new OptionModelSelectMoons({
      text: 'Complejo',
      value: 'severe'
    })
  ],
  alignersRange: [
    new OptionModelSelectMoons({
      text: 'Simple / 20 Alineadores',
      value: 'low'
    }),
    new OptionModelSelectMoons({
      text: 'Moderado / 21 a 35 Alineadores',
      value: 'moderate'
    }),
    new OptionModelSelectMoons({
      text: 'Complejo / +35 Alineadores',
      value: 'severe'
    })
  ],
  stlMethod: [
    new OptionModelSelectMoons({
      text: 'Subir Archivo STL',
      value: 'upload_stl'
    }),
    new OptionModelSelectMoons({
      text: 'Recolección de impresión',
      value: 'printing'
    }),
    new OptionModelSelectMoons({
      text: 'Escaneo en clínica',
      value: 'appointment'
    })
  ],
}

export default PlanningFormMessages
