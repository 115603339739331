/* eslint-disable react/jsx-no-literals */
import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from '../../../../../4-hooks/contexts/App/App.context'
import { getUserData } from '../../../../../7-services/App/User.service'
import styles from './StlPrinting.module.scss'
import messages from './StlPrinting.messages'
import { useForm } from '../../../../../4-hooks/useForm'
import { DatePickerMoons } from '../../../../UI/DatePickerMoons/DatePickerMoons'
import { ButtonMoons } from '../../../../UI'
import handleSubmitForm from './StlPrinting.logic'
import LoaderMoons from '../../../../UI/LoaderMoons/LoaderMoons'

/**
 * StlPrinting component
 * @param {String} doctorId Patient objectId to fetch data
 * @param {String} planningId Planning obejctId to relate to when create shipping
 * @param {Function} setMethod change screen stl method
 * @returns {Void} component
 */
const StlPrinting = ({ doctorId, planningId, setMethod }) => {
  const { stateApp: { functions, user, historyApp } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const [isLoading, setIsLoading] = useState(false)
  const [addressData, setAddress] = useState(null)
  const [
    formValues, , , ,
    progress,
    setInProgress, ,
    handleDatePickerChange
  ] = useForm({ pickUpDate: null })
  const { pickUpDate } = formValues

  useEffect(() => {
    setIsLoading(true)
    getUserData(doctorId)
      .then(res => {
        const { address } = res
        setAddress(address)
        setIsLoading(false)
      })
      .catch(error => {
        handleSnack(true, 'Error al traer la información de la dirección', 'error')
        setMethod('')
        setIsLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    isLoading ? <LoaderMoons size={120} /> : (
      <div className={styles.gridContainer}>
        <form
          className="generic"
          onSubmit={(event) => handleSubmitForm(
            event,
            {
              pickUpDate, planningId, user, historyApp
            },
            { handleSnack, setInProgress }
          )}
        >
          <p>
            <b>{messages.message1}</b>
            {' '}
            <span>
              Calle:
              {` ${addressData?.street}, `}
            </span>
            <span>
              Colonia:
              {` ${addressData?.neighborhood}, `}
            </span>
            <span>
              Ciudad:
              {` ${addressData?.city}, `}
            </span>
            <span>
              Estado:
              {` ${addressData?.state}, `}
            </span>
            <span>
              CP:
              {` ${addressData?.zip}`}
            </span>
          </p>
          <DatePickerMoons
            label={messages.label1}
            name="pickUpDate"
            value={pickUpDate}
            onChange={handleDatePickerChange}
            showTime
            timeLabel={messages.label2}
            required
          />

          <ButtonMoons
            variant="outlined"
            color="primary"
            type="submit"
            isprogress={Number(progress)}
          >
            {messages.message2}
          </ButtonMoons>

        </form>
      </div>
    )
  )
}

export default StlPrinting
