import { requestMoons } from '../../8-common/Request'

/**
 * Creates Shipping object
 * for stl method printing
 * @param {Object} param0 .
 * @param {String} param0.token user's session token
 * @param {Object} param0.shipping new Shipping data
 * @param {String} param0.planningId Planning objectId to relate shipping to
 * @param {String} param0.timezone shipping timezone. defaults to CDMX
 * @returns {Object} new Shipping object data
 */
export const createShipping = ({
  token, shipping, planningId, timezone = null
}) => {
  const config = {
    endpoint: `/planning/${planningId}/shipping`,
    method: 'POST',
    body: shipping,
    customHeaders: {
      Authorization: token
    }
  }
  if (timezone) {
    config.params = {
      timezone
    }
  }
  return requestMoons(config)
}

/**
 * Update Shipping data from its objectId
 * @param {Object} param0 .
 * @param {String} param0.token user's session token
 * @param {Object} param0.shipping edit Shipping data
 * @param {String} param0.shippingId Shipping objectId to relate shipping to
 * @param {String} param0.timezone shipping timezone. defaults to CDMX
 * @returns {Object} updated shipping data
 */
export const updateShipping = ({
  token, shipping, shippingId, timezone = null
}) => {
  const config = {
    endpoint: `/planning/shipping/${shippingId}`,
    method: 'PUT',
    body: shipping,
    customHeaders: {
      Authorization: token
    }
  }
  if (timezone) {
    config.params = {
      timezone
    }
  }
  requestMoons(config)
}
