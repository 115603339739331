/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-process-env */
import React from 'react'
import PropTypes from 'prop-types'

import { Route, Redirect } from 'react-router-dom'

const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * make public route
 * @param {*} param0 .
 * @param {*} param0.isAuthenticated is valid session
 * @param {*} param0.component to render
 * @returns {Object} unprotected route
 */
export const PublicRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    exact
    render={(props) => (
      isAuthenticated ? (<Redirect to={`/${prefijApp}/home`} />) : (<Component {...props} />)
    )}

  />
)

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired
}
