/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable import/prefer-default-export */
/**
 * user model dto
 */
export class UserModel {
  /**
   * constructor user data model
   * @param {*} data user dto
   */
  constructor(data = {}) {
    this.activated = data.activated || false
    this.countryOps = data.countryOps || ''
    this.createdAt = data.createdAt || ''
    this.email = data.email || ''
    this.name = data.name || ''
    this.objectId = data.objectId || ''
    this.phone = data.phone || ''
    this.publicKey = data.publicKey || ''
    this.role = data.role || []
    this.sessionToken = data.sessionToken || ''
    this.updatedAt = data.updatedAt || ''
    this.username = data.username || ''
    this.userType = data.userType || ''
  }
}
