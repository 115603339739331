/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, {
  useEffect, useContext, useState, useRef
} from 'react'
import { Link, useParams } from 'react-router-dom'
import { useForm } from '../../../4-hooks/useForm'
import { AppContext } from '../../../4-hooks/contexts/App/App.context'
import { TreatmentModel } from '../../../6-models/App/Treatment.model'
import Planning from '../../../6-models/App/Planning.model'
import { ButtonMoons } from '../../UI'
import { CardMoons } from '../../UI/CardMoons/CardMoons'
import { ChecksGroupMoonsModel } from '../../UI/ChecksGroupMoons/Check.model'
import { ChecksGroupMoons } from '../../UI/ChecksGroupMoons/ChecksGroupMoons'
import { RadioButtonsGroupMoons } from '../../UI/RadioButtonsGroupMoons/RadioButtonsGroupMoons'
import ReturnHistory from '../../UI/ReturnHistory/ReturnHistory'
import NextStep from '../../UI/NextStep/NextStep'
import { getPlanningData } from '../../../7-services/App/Planning.service'
import { wrapInput, handleUpsert } from './Treatment.logic'
import LoaderMoons from '../../UI/LoaderMoons/LoaderMoons'
import messages from './Treatment.messages'
import styles from './Treatment.module.scss'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * Treatment component
 * @param {*} param0 .
 * @param {*} param0.urlNextStep url for next step
 * @param {*} param0.urlBackStep url for back step
 * @param {*} param0.hideOptional hide optional alert for planning summary
 * @returns {Void} component
 */
export const Treatment = ({
  urlNextStep, urlBackStep, isSummary = false, hideOptional = false
}) => {
  const { stateApp: { functions, user, historyApp } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const [
    formValues,
    setValues,
    handleInputChange, ,
    progress,
    setInProgress,
    handleCheckGroupChange
  ] = useForm(new TreatmentModel())
  const {
    keepSpaces,
    leftMolarClass,
    rightMolarClass,
    leftCanineClass,
    rightCanineClass,
    verticalOverbite,
    horizontalOverbite,
    arcade,
    alignArchesUsing,
    extractParts,
    doIPR,
    notes
  } = formValues
  const { planningId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [treatObjId, setTreatObjId] = useState('')
  const [patientName, setPatientName] = useState('')
  const isRendered = useRef(false)

  useEffect(() => {
    isRendered.current = true
    if (planningId) {
      setIsLoading(true)
      getPlanningData(user.sessionToken, planningId)
        .then(res => {
          if (isRendered.current) {
            const planningData = new Planning(res)
            const { treatment, patient: { name } } = planningData
            if (treatment) {
              const treatDto = new TreatmentModel(treatment || {})
              setValues(treatDto)
              setTreatObjId(treatment.objectId)
            }
            setPatientName(name)
            setIsLoading(false)
          }
        })
        .catch(error => {
          setIsLoading(false)
          handleSnack(true, 'Error al obtener información del Historial Médico', 'error')
        })

      return () => {
        isRendered.current = false
      }
    }
    return null
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    isLoading ? <LoaderMoons size={120} /> : (
      <>
        {
          !isSummary && (
            <ReturnHistory
              route={user.userType === 'DOCTOR' ? `/${prefijApp}/patients/${user.objectId}` : `/${prefijApp}/patients`}
              historyApp={historyApp}
            />
          )
        }
        {
          !isSummary && !hideOptional && (
            <NextStep
              route={urlNextStep}
              message={messages.optionalMsg}
              btnMsg={messages.optionalLink}
            />
          )
        }
        <CardMoons
          title={messages.title.replace('[patient]', patientName)}
          actions={(
            <>
              {
                urlBackStep && (
                  <Link
                    className="linkStep"
                    to={urlBackStep}
                  >
                    {messages.buttonBack}
                  </Link>
                )
              }

              {
                urlNextStep && (
                  <ButtonMoons
                    variant="contained"
                    color="primary"
                    onClick={() => handleUpsert({
                      urlNextStep,
                      token: user.sessionToken,
                      formValues,
                      treatObjId,
                      planningId,
                      handleSnack,
                      setInProgress,
                      historyApp,
                      user
                    })}
                    isprogress={Number(progress)}
                  >
                    {messages.button}
                  </ButtonMoons>
                )
              }
            </>
          )}
        >

          {
            [
              {
                name: 'keepSpaces', placeholder: messages.placeHolder1, message: messages.option1, value: keepSpaces
              },
              {
                name: 'leftMolarClass', placeholder: messages.placeHolder2, message: messages.option2, value: leftMolarClass
              },
              {
                name: 'rightMolarClass', placeholder: messages.placeHolder3, message: messages.option3, value: rightMolarClass
              },
              {
                name: 'leftCanineClass', placeholder: messages.placeHolder4, message: messages.option4, value: leftCanineClass
              },
              {
                name: 'rightCanineClass', placeholder: messages.placeHolder5, message: messages.option5, value: rightCanineClass
              }
            ].map((elem, idx) => wrapInput(elem, idx, handleInputChange, isSummary))
          }

          <div className={styles.gridContainer}>
            <span>{messages.option6}</span>
            <div>
              <RadioButtonsGroupMoons
                row
                radios={messages.optionsVerticalOverbite}
                name="verticalOverbite"
                value={verticalOverbite}
                onChange={handleInputChange}
                disableAll={isSummary}
              />
            </div>
          </div>

          <div className={styles.gridContainer}>
            <span>{messages.option7}</span>
            <div>
              <RadioButtonsGroupMoons
                row
                radios={messages.optionsHorizontalOverbite}
                name="horizontalOverbite"
                value={horizontalOverbite}
                onChange={handleInputChange}
                disableAll={isSummary}
              />
            </div>
          </div>

          <div className={styles.gridContainer}>
            <span>{messages.option10}</span>
            <div>
              <ChecksGroupMoons
                row
                checks={
                  messages.optionsArcade.map(
                    elemCheck => new ChecksGroupMoonsModel({
                      ...elemCheck,
                      isChecked: arcade[elemCheck.name]
                    })
                  )
                }
                name="arcade"
                handleChange={handleCheckGroupChange}
                disableAll={isSummary}
              />
            </div>
          </div>

          <div className={styles.gridContainer}>
            <span>{messages.option11}</span>
            <div>
              <ChecksGroupMoons
                row
                checks={
                  messages.optionsAlignArchesUsing.map(
                    elemCheck => new ChecksGroupMoonsModel({
                      ...elemCheck,
                      isChecked: alignArchesUsing[elemCheck.name]
                    })
                  )
                }
                name="alignArchesUsing"
                handleChange={handleCheckGroupChange}
                disableAll={isSummary}
              />
            </div>
          </div>

          {
            [
              {
                name: 'extractParts', placeholder: messages.placeHolder8, message: messages.option8, value: extractParts
              }
            ].map((elem, idx) => wrapInput(elem, idx, handleInputChange, isSummary))
          }

          <div className={styles.gridContainer}>
            <span>{messages.option12}</span>
            <div>
              <RadioButtonsGroupMoons
                row
                radios={messages.optionsDoIPR}
                name="doIPR"
                value={doIPR}
                onChange={handleInputChange}
                disableAll={isSummary}
              />
            </div>
          </div>

          {
            [
              {
                name: 'notes', placeholder: messages.placeHolder9, message: messages.option9, value: notes
              }
            ].map((elem, idx) => wrapInput(elem, idx, handleInputChange, isSummary))
          }

        </CardMoons>
      </>
    )
  )
}
