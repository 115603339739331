/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, { useState, useCallback, useMemo } from 'react'
import { IconButtonMoons } from '../../UI/IconButtonMoons/IconButtonMoons'
import { ButtonMoons } from '../../UI/ButtonMoons/ButtonMoons'
import messages from './ValidatePictures.messages'
import styles from './ValidatePictures.module.scss'
import { CardMoons } from '../../UI/CardMoons/CardMoons'
import { zero, one, five } from '../../../8-common/Constants'
import { validatePictures, rejectPictures, disableActions } from './ValidatePictures.logic'

/**
 * Validate pictures component
 * get files pictures. update Planning data with state/status
 * @param {Boolean} props.useDiagnose is diagnose view
 * @param {Boolean} props.name Patient's name
 * @param {Boolean} props.images Planning files object
 * @param {String} props.token users'sessiontoken
 * @param {String} props.planningId Planning objectId to edit
 * @param {Boolean} props.progress progress flag
 * @param {String} props.status Planning status
 * @param {Function} props.setInProgress set progress flag
 * @param {Function} props.handleSnack open snackbar
 * @param {Function} props.historyApp router
 * @param {Function} props.setValues set planning values
 * @returns {Void} component
 */
export const ValidatePictures = ({
  useDiagnose, name, images, token, planningId, progress, status,
  setInProgress, handleSnack, historyApp, setValues
}) => {
  const [indexPreview, setIndexPreview] = useState(null)
  const [showPreview, setShowPreview] = useState(false)
  // const { images } = messages
  const containerValidationImage = document.getElementById('img-validation')

  /**
   * get img src by photo
   * @returns {String} img src
   */
  const getImagePreview = useMemo(() => images[indexPreview]?.file, [images, indexPreview])

  /**
   * diable actions by state/status
   */
  const allowActions = useMemo(() => disableActions(status), [status])

  /**
   * increment slider index
   */
  const incrementIndex = useCallback(() => {
    if (indexPreview < images.length - one) {
      setIndexPreview(indexPreview + one)
    }
  }, [images.length, indexPreview])

  /**
   * decrement slider index
   */
  const decrementIndex = useCallback(() => {
    if (indexPreview > zero) {
      setIndexPreview(indexPreview - one)
    }
  }, [indexPreview])

  return (
    <div
      id="img-validation"
      className={[
        styles.imgValidationContainer, useDiagnose ? styles.heightShared : styles.heightFull,
      ].join(' ')}
      style={{ overflowY: showPreview ? 'hidden' : 'auto' }}
    >

      <CardMoons
        actions={(
          <>
            <ButtonMoons
              variant="contained"
              color="secondary"
              type="button"
              disabled={useDiagnose || allowActions}
              isprogress={Number(progress)}
              onClick={() => rejectPictures({
                token, planningId, setInProgress, handleSnack, historyApp
              })}
            >
              {messages.message1}
            </ButtonMoons>
            <ButtonMoons
              variant="contained"
              color="primary"
              type="button"
              disabled={useDiagnose || allowActions}
              isprogress={Number(progress)}
              onClick={() => validatePictures({
                token, planningId, setInProgress, handleSnack, historyApp, setValues
              })}
            >
              {messages.message2}
            </ButtonMoons>
          </>
        )}
      >
        <div className={styles.titleViewer}>
          {
            useDiagnose ? messages.titleDiagnose
              .replace('[patient]', name) : messages.titleValidate.replace('[patient]', name)
          }
        </div>

        <div
          className={styles.gridContainer}
          style={{ paddingTop: '4em' }}
        >
          {images.map((img, index) => (
            <div
              className={styles.imgPreviewContainer}
              key={`img-preview-${img.label}`}
            >
              <p className={styles.photoLabel}>{messages[img.label]}</p>
              <button
                className={styles.imgButtonPreview}
                type="button"
                onClick={() => {
                  setIndexPreview(index)
                  setShowPreview(true)
                }}
              >
                <img
                  src={img.file}
                  role="presentation"
                  alt={`preview-${img.label}`}
                />
              </button>
            </div>
          ))}
        </div>
      </CardMoons>

      {showPreview && (
        <div
          className={styles.backPreview}
          style={{ top: `${(containerValidationImage?.scrollTop - five) || zero}px` }}
        >
          <IconButtonMoons
            aria-label="arrow_back_ios"
            icon="arrow_back"
            color="secondary"
            onClick={decrementIndex}
            disabled={indexPreview === zero}
            className={styles.btnBack}
          />

          <IconButtonMoons
            aria-label="close"
            icon="close"
            color="secondary"
            onClick={() => setShowPreview(false)}
            className={styles.btnClose}
          />

          <div className={styles.regionImagePreview}>
            <img
              src={getImagePreview}
              alt={`close-up-${indexPreview}`}
            />
          </div>

          <IconButtonMoons
            aria-label="arrow_forward_ios"
            icon="arrow_forward"
            color="secondary"
            disabled={indexPreview === images.length - one}
            onClick={incrementIndex}
            className={styles.btnForward}
          />
        </div>
      )}
    </div>
  )
}
