import { requestMoons } from '../../8-common/Request'

/**
 * Updates Medical History data from objectId
 * @param {String} token user's session token
 * @param {String} objectId Medical History objectId to fetch
 * @param {Object} medHist Medical History data object
 * @returns {Object} Medical History data
 */
export const updateMedHist = (token, objectId, medHist) => requestMoons({
  endpoint: `/medical-history/edit/${objectId}`,
  method: 'PUT',
  body: medHist,
  customHeaders: {
    Authorization: token
  }
})

/**
 * Creates new Medical History
 * @param {String} token user's session token
 * @param {String} patientId planning objectId to fetch
 * @param {Object} medHist Medical History data object
 * @returns {Object} Medical History data
 */
export const createMedHist = (token, patientId, medHist) => requestMoons({
  endpoint: `/medical-history/${patientId}`,
  method: 'POST',
  body: medHist,
  customHeaders: {
    Authorization: token
  }
})
