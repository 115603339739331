/* eslint-disable no-process-env */
/* eslint-disable no-useless-catch */
import { basicAuthHeader } from '../../8-common/UtilFunctions'

const urlBase = process.env.REACT_APP_URL_API_B2B
const headers = { 'Content-Type': 'application/json' }

/**
 * register user service
 * @param {Obiect} createDto new user dto,
 * @returns {Object} new User
 */
export const registerAccountService = async (createDto) => {
  try {
    const resp = await fetch(`${urlBase}/users/register`, {
      method: 'POST',
      body: JSON.stringify(createDto),
      headers: { ...headers, ...basicAuthHeader() }
    })

    if (resp.ok) {
      const cloudResp = await resp.json()
      return cloudResp
    }
    throw await resp.json()
  } catch (err) {
    throw err
  }
}

/**
 * login service
 * @param {*} param0 .
 * @param {*} param0.email user email
 * @param {*} param0..password user password
 * @returns {Object} User data
 */
export const loginUserService = async ({ email, password }) => {
  try {
    const resp = await fetch(`${urlBase}/users/login`, {
      method: 'POST',
      headers: { ...headers, ...basicAuthHeader(email, password) }
    })

    if (resp.ok) {
      const cloudResp = await resp.json()
      return cloudResp
    }
    throw await resp.json()
  } catch (err) {
    throw err
  }
}

/**
 * logout service
 * @param {String} token user's sessionToken
 * @returns {*} succes response
 */
export const logoutUserService = async (token) => {
  try {
    const resp = await fetch(`${urlBase}/users/logout`, {
      method: 'POST',
      headers: { ...headers, Authorization: token }
    })

    if (resp.ok) {
      const cloudResp = await resp.text()
      return cloudResp
    }
    throw await resp.json()
  } catch (err) {
    throw err
  }
}

/**
 * update password service
 * @param {Object} updateDto update user dto
 * @returns {Object} updated user data
 */
export const updatePassService = async (updateDto) => {
  try {
    const resp = await fetch(`${urlBase}/users/update-password`, {
      method: 'POST',
      body: JSON.stringify(updateDto),
      headers: { ...headers, ...basicAuthHeader() }
    })

    if (resp.ok) {
      const cloudResp = await resp.json()
      return cloudResp
    }
    throw await resp.json()
  } catch (err) {
    throw err
  }
}

/**
 * recover password service
 * @param {*} recoveryDto recover dtp
 * @returns {Object} user data
 */
export const recoveryPassService = async (recoveryDto) => {
  try {
    const resp = await fetch(`${urlBase}/users/recover-password`, {
      method: 'POST',
      body: JSON.stringify(recoveryDto),
      headers: { ...headers, ...basicAuthHeader() }
    })

    if (resp.ok) {
      const cloudResp = await resp.json()
      return cloudResp
    }
    throw await resp.json()
  } catch (err) {
    throw err
  }
}
