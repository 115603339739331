/* eslint-disable import/prefer-default-export */
import { useState } from 'react'
import { scrollTopInit } from '../8-common/UtilFunctions'

const zero = 0
const one = 1

/**
 * use Stepper hook
 * @param {*} initialState initial step
 * @returns {Array} hook array of value and setter
 */
export const useStepper = (initialState = zero) => {
  const [activeStep, setActiveStep] = useState(initialState)

  /**
   * change to next
   * @returns {Void} change to next step
   */
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + one)
    scrollTopInit('body-home')
  }

  /**
   * change to previous step
   * @returns {Void} last step
   */
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - one)
    scrollTopInit('body-home')
  }

  /**
   * reset to step 0
   * @returns {Void} reset stepper
   */
  const handleReset = () => {
    setActiveStep(zero)
  }

  return [
    activeStep,
    setActiveStep,
    handleNext,
    handleBack,
    handleReset
  ]
}
