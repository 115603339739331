import { ColumnDataTableModel } from '../../../UI/DataTableMoons/ColumnDataTable.model'
import AssignmentRow from './AssignmentRow/AssignmentRow'
import { renderCellActions, renderCellPatientActions } from './PatientList.logic'

const PersonListMessages = {
  title: 'Pacientes [drName]',
  noRowsMsg: 'Aún no hay pacientes, agrega uno en este ícono de la esquina superior derecha',
  columnsTable: (user, openDeletionConfirmModal, handleSnack, setIsLoading) => [
    new ColumnDataTableModel({
      field: 'customerId',
      headerName: 'CustomerId',
      width: 130
    }),
    new ColumnDataTableModel({
      field: 'name',
      headerName: 'Paciente',
      width: 190,
      cellClassName: 'textCapitalize'
    }),
    new ColumnDataTableModel({
      field: 'start',
      headerName: 'Inicio',
      width: 200,
    }),
    new ColumnDataTableModel({
      field: 'planningState',
      headerName: 'Estado Planeación',
      width: 190,
    }),
    new ColumnDataTableModel({
      field: 'planningStatus',
      headerName: 'Estatus Planeación',
      width: 190,
    }),
    user.role[0].name.includes('ADMIN') ? null : new ColumnDataTableModel({
      field: 'assignedTo',
      headerName: 'Asignación',
      width: 190,
    }),
    user.role[0].name.includes('ADMIN') ? new ColumnDataTableModel({
      field: 'users',
      headerName: 'Asignar',
      sortable: false,
      hideSortIcons: true,
      width: 250,
      disableColumnMenu: true,
      editable: true,
      renderCell: (row) => <AssignmentRow user={user} row={row} handleSnack={handleSnack} setIsLoading={setIsLoading} />
    }) : null,
    new ColumnDataTableModel({
      field: 'lastUpdate',
      headerName: 'Última actualización',
      width: 250,
    }),
    new ColumnDataTableModel({
      field: 'actions',
      headerName: 'Acciones',
      sortable: false,
      hideSortIcons: true,
      width: 130,
      disableColumnMenu: true,
      renderCell: (rowData) => renderCellActions(rowData, user)
    }),
    user.role[0].name.includes('ADMIN') && new ColumnDataTableModel({
      field: 'patientActions',
      headerName: 'Borrar paciente',
      sortable: false,
      hideSortIcons: true,
      width: 130,
      disableColumnMenu: true,
      renderCell: (rowData) => renderCellPatientActions(rowData, user, openDeletionConfirmModal)
    })
  ],
  modal: {
    title: 'Borrar paciente',
    description: '¿Desea borrar al paciente [patient]?',
    primaryButtonLabel: 'Borrar',
    secundaryButtonLabel: 'Cancelar'
  }
}

export default PersonListMessages
