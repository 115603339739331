/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-process-env */
import React, { useContext, useEffect, useMemo } from 'react'
import {
  BrowserRouter as Router,
  Switch
} from 'react-router-dom'
import { SnackMoons } from '../3-components/UI'
import { AppContext } from '../4-hooks/contexts/App/App.context'
import { appTypes } from '../4-hooks/contexts/App/App.types'
import { useSnack } from '../4-hooks/useSnack'
import { LoginRouter } from './Login/LoginRouter'
import { PrivateRoute } from './PrivateRoute'
import { SwitchProfile } from './SwitchProfile'
import { PublicRoute } from './PublicRoute'
import useLocationOrigin from '../4-hooks/useLocationOrigin'

const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * Main App Router
 * @returns {Void} component
 */
export const AppRouter = () => {
  const [snack, handleSnack] = useSnack({ status: false, message: '', type: '' })
  const { dispatch, stateApp: { user } } = useContext(AppContext)
  const countryPath = useLocationOrigin()

  useEffect(() => {
    dispatch({ type: appTypes.addFunction, payload: { handleSnack } })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router basename={countryPath}>
      <div className="mainContainer">
        <Switch>

          {/* <PrivateRoute
              path="/home"
              component={SwitchProfile}
              isAuthenticated={user.sessionToken ? true : false}
          /> */}
          <PrivateRoute
            path={`/${prefijApp}`}
            component={SwitchProfile}
            isAuthenticated={!!user.sessionToken}
          />

          <PublicRoute
            path="/"
            component={LoginRouter}
            isAuthenticated={!!user.sessionToken}
          />

          {/* <Redirect exact from='/home' to="/home/a" /> */}

        </Switch>
      </div>
      <SnackMoons
        open={snack.status}
        message={snack.message}
        type={snack.type}
        onClose={handleSnack}
      />
    </Router>
  )
}
