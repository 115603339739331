/* eslint-disable import/prefer-default-export */
/**
 * return array of planning files urls
 * @param {Object} files Plannign files object
 * @returns {Array} of objects with file url and label
 */
export const getArrayFile = (files) => {
  const listFiles = []
  Object.keys(files).forEach(fileKey => {
    if (fileKey !== 'xray') {
      listFiles.push({ label: fileKey, file: files[fileKey] })
    }
  })
  return listFiles
}
