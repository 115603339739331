/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable import/prefer-default-export */
import { registerAccountService } from '../../../7-services/LoginService/Login.service'
import {
  someEmptyProperty, validarEmail, validatePhone, validateZip
} from '../../../8-common/UtilFunctions'
import { updateUser } from '../../../7-services/App/User.service'
import { zero, three, errorCodes } from '../../../8-common/Constants'
import messagesCopy from './CreateAccountForm.messages'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * handle submit for creation of users (doctors/dr moons) and edition
 * @param {*} event submit event
 * @param {Object} param1 .
 * @param {Object} param1.formValues user form values data
 * @param {Object} param1.userObjId user objectId in case of edit
 * @param {Function} param1.historyApp history function
 * @param {Object} param2 .
 * @param {Function} param2.reset reset form
 * @param {Function} param2.handleSnack open snackbar
 * @param {Function} param2.setInProgress start progress flag
 * @returns {Void} handle submit
 */
export const handleSubmitForm = async (
  event, { formValues, userObjId, historyApp }, { reset, handleSnack, setInProgress }
) => {
  event.preventDefault()

  const messageError = validateCreateForm(formValues, userObjId)
  if (messageError) {
    handleSnack(true, messageError, 'error')
    return
  }

  const userData = { ...formValues }
  userData.address = {
    street: userData?.street?.trim(),
    apt: userData?.apt?.trim(),
    neighborhood: userData?.neighborhood?.trim(),
    city: userData?.city?.trim(),
    state: userData?.state?.trim(),
    zip: userData?.zip?.trim()
  }
  userData.name = userData?.name?.trim()
  userData.email = userData?.email?.trim()
  userData.phone = userData?.phone?.trim()
  delete userData.street
  delete userData.apt
  delete userData.neighborhood
  delete userData.city
  delete userData.state
  delete userData.zip

  if (userObjId) {
    delete userData.role
    try {
      setInProgress(true)
      await updateUser(userObjId, userData)
      setInProgress(false)
      historyApp.push(`/${prefijApp}/doctors`)
      return
    } catch (error) {
      handleSnack(true, errorCodes[error.code] || 'Error al editar información', 'error')
      setInProgress(false)
      return
    }
  }
  try {
    setInProgress(true)
    await registerAccountService(userData)
    handleSnack(true, 'Usuario creado correctamente', 'success')
    reset()
  } catch (error) {
    console.error(error)
    handleSnack(true, errorCodes[error.code] || 'Error al crear usuario', 'error')
    return
  } finally {
    setInProgress(false)
  }
}

/**
 * validation of form
 * @param {*} formValues form data
 * @param {Boolean} isEdit flag to check role or not
 * @returns {String} error message if needed
 */
const validateCreateForm = (formValues, isEdit) => {
  const {
    name, username, phone, zip, role
  } = formValues
  let message = ''

  /**
   * Return if message if empty
   * @returns {Boolean} message empty
   */
  const isEmptyMessage = () => message === ''

  const fieldsToValidate = [
    'name',
    'phone',
    'username',
    'countryOps'
  ]
  !isEdit && fieldsToValidate.push('role')
  if (role === 'DOCTOR') {
    fieldsToValidate.push('street', 'neighborhood', 'city', 'state', 'zip')
  }

  const { invalidFields } = someEmptyProperty(formValues, fieldsToValidate)

  if (isEmptyMessage() && invalidFields.length > zero) {
    const fieldsNames = invalidFields.map(fieldKey => ` ${messagesCopy[fieldKey]}`)
    message = `Campos vacíos o con solo espacios en blanco: ${fieldsNames}`
  }
  if (isEmptyMessage()) {
    const trimmed = name.trim()
    if (trimmed.split(' ').length < three) {
      message = 'Formato de nombre incorrecto. Debe ser: Nombre, Apellido Paterno, Apellido Materno'
    }
  }
  if (isEmptyMessage() && !validarEmail(username)) {
    message = 'Debes ingresar un email válido.'
  }
  if (isEmptyMessage() && !validatePhone(phone)) {
    message = 'Teléfono requerido a 10 dígitos'
  }
  if (isEmptyMessage() && (role === 'DOCTOR') && !validateZip(zip)) {
    message = 'El código postal debe ser de 5 dígitos solamente'
  }

  return message
}
