/* eslint-disable max-statements */
/* eslint-disable complexity */
import { zero } from '../../8-common/Constants'

/**
 * Planning data object
 */
class Planning {
  /**
   * constructor
   * @param {Object} [data] Planning data object
   */
  constructor(data = {}) {
    this.files = data.files || {
      trueSmile: {
        url: ''
      },
      profileFront: {
        url: ''
      },
      profileRight: {
        url: ''
      },
      frontOcclusion: {
        url: ''
      },
      rightLateralOcclusion: {
        url: ''
      },
      leftLateralOcclusion: {
        url: ''
      },
      superiorOcclusion: {
        url: ''
      },
      lowerOcclusion: {
        url: ''
      },
      xray: {
        url: ''
      }
    }
    this.state = data.state || ''
    this.status = data.status || ''
    this.patient = data.patient || {}
    this.medicalHistory = data.medicalHistory || {}
    this.treatment = data.treatment || {}
    this.appointment = data.appointment || {}
    this.shipping = data.shipping || {}
    this.alignersRange = data.alignersRange || ''
    this.upperAligners = data.upperAligners || zero
    this.lowerAligners = data.lowerAligners || zero
    this.treatmentMonths = data.treatmentMonths || zero
    this.patientType = data.patientType || ''
    this.severity = data.severity || ''
    this.patientNotes = data.patientNotes || ''
    this.doctorNotes = data.doctorNotes || ''
    this.diagnoseNotes = data.diagnoseNotes || ''
    this.datasheet = data.datasheet || ''
    this.isPaid = data.isPaid || false
    this.stlMethod = data.stlMethod || ''
    this.stlFileUrl = data.stlFileUrl || ''
    this.stlLowerArchFileUrl = data.stlLowerArchFileUrl || ''
    this.stlFirstOcclusionFileUrl = data.stlFirstOcclusionFileUrl || ''
    this.stlSecondOcclusionFileUrl = data.stlSecondOcclusionFileUrl || ''
    this.active = data.active || false
    this.videoUrl = data.videoUrl || ''
    this.deliverPromiseDate = data.deliverPromiseDate || null
    this.paymentDate = data.paymentDate || null
    this.approvedDate = data.approvedDate || null
    this.archformUrl = data.archformUrl || null
    this.delimitedModelUrl = data.delimitedModelUrl || null
    this.prodStlFileUrl = data.prodStlFileUrl || null
  }
}

export default Planning
