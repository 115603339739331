import { requestMoons } from '../../8-common/Request'

/**
 * Get Planning Data
 * @param {String} token user's session token
 * @param {String} objectId planning objectId to fetch
 * @returns {Object} Plannind data
 */
export const getPlanningData = (token, objectId) => requestMoons({
  endpoint: `/planning/get/${objectId}`,
  method: 'GET',
  customHeaders: {
    Authorization: token
  }
})

/**
 * Updates Planning data from objectId
 * @param {String} token user's session token
 * @param {String} objectId planning objectId to fetch
 * @param {Object} planning planning data object
 * @returns {Object} Plannind data
 */
export const updatePlanning = (token, objectId, planning) => requestMoons({
  endpoint: `/planning/${objectId}`,
  method: 'PUT',
  body: planning,
  customHeaders: {
    Authorization: token
  }
})

/**
 * Creates new Planning
 * @param {String} token user's session token
 * @param {String} patientId planning objectId to fetch
 * @param {Object} planning planning data object
 * @param {String} userId user id who is creating the planning and has auth to modify it
 * @returns {Object} Plannind data
 */
export const createPlanning = (token, patientId, planning, userId) => requestMoons({
  endpoint: `/planning/new/${patientId}/?userId=${userId}`,
  method: 'POST',
  body: planning,
  customHeaders: {
    Authorization: token
  }
})
