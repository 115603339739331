/* eslint-disable max-statements */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, {
  useEffect, useState, useCallback, useContext
} from 'react'
import { Link, useParams } from 'react-router-dom'
import { AppContext } from '../../../4-hooks/contexts/App/App.context'
import { CardMoons } from '../../UI/CardMoons/CardMoons'
import messages from './PicturesPatient.messages'
import styles from './PicturesPatient.module.scss'
import { useForm } from '../../../4-hooks/useForm'
import { DragAndDropMoons } from '../../UI/DragAndDropMoons/DragAndDropMoons'
import { PicturesPatientModel } from '../../../6-models/App/PicturesPatient.model'
import Planning from '../../../6-models/App/Planning.model'
import { IconMoons } from '../../UI/IconMoons/IconMoons'
import { IconButtonMoons } from '../../UI/IconButtonMoons/IconButtonMoons'
import { ButtonMoons } from '../../UI'
import { TooltipMoons } from '../../UI/TooltipMoons/TooltipMoons'
import ReturnHistory from '../../UI/ReturnHistory/ReturnHistory'
import { imagesProcess, processUploadPictures } from './PicturesPatient.logic'
import { getPlanningData } from '../../../7-services/App/Planning.service'
import LoaderMoons from '../../UI/LoaderMoons/LoaderMoons'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * Upload Pictures component
 * @param {*} param0 .
 * @param {*} param0.urlNextStep url for next step
 * @param {*} param0.urlBackStep url for back step
 * @returns {Void} component
 */
export const PicturesPatient = ({ urlNextStep, urlBackStep }) => {
  const { stateApp: { functions, user, historyApp } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const [, , , ,
    progress,
    setInProgress] = useForm(new PicturesPatientModel())
  const [files, setFiles] = useState(new PicturesPatientModel())
  const [inputsConfig, setInputsConfig] = useState(messages.inputsFiles)
  const { planningId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [patientCustomerId, setCustomerId] = useState('')
  const [statePlann, setStatePlann] = useState('')
  const [statusPlann, setStatusPlann] = useState('')
  const [patientName, setPatientName] = useState('')

  useEffect(() => {
    if (planningId) {
      setIsLoading(true)
      getPlanningData(user.sessionToken, planningId)
        .then(res => {
          const planning = new Planning(res)
          if (planning.files) {
            const inputsConfigCopy = [...inputsConfig]
            const picturesDto = new PicturesPatientModel(planning.files || {})
            Object.keys(picturesDto).forEach(picKey => {
              const inputFile = inputsConfigCopy.find(inpFile => inpFile.prop === picKey)
              if (inputFile && picturesDto[picKey] && picKey !== 'xray') {
                inputFile.image = (
                  <img
                    src={picturesDto[picKey]}
                    alt={`uploaded-${picKey}`}
                  />
                )
                inputFile.uploaded = true
              }
              if (inputFile && picturesDto[picKey] && picKey === 'xray') {
                inputFile.image = <IconMoons name="folder_special" />
                inputFile.uploaded = true
              }
            })
            setInputsConfig(inputsConfigCopy)
            setFiles(picturesDto)
          }
          const { state, status, patient: { customerId, name } } = planning
          setCustomerId(customerId)
          setStatePlann(state)
          setStatusPlann(status)
          setPatientName(name)
        })
        .catch(error => {
          handleSnack(true, 'Error al obtener información del Historial Médico', 'error')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * handles image resize processing
   */
  const updateFiles = useCallback((_filesSelected, event, prop) => imagesProcess(
    {
      _filesSelected, prop, styles, files
    },
    { inputsConfig, setInputsConfig, setFiles }
  ), [files, inputsConfig])

  return (
    isLoading ? <LoaderMoons size={120} /> : (
      <>
        <ReturnHistory
          route={user.userType === 'DOCTOR' ? `/${prefijApp}/patients/${user.objectId}` : `/${prefijApp}/patients`}
          historyApp={historyApp}
        />
        <CardMoons
          title={messages.title.replace('[patient]', patientName)}
          actions={(
            <>
              {
                urlBackStep
                && (
                  <Link
                    className="linkStep"
                    to={urlBackStep}
                  >
                    {messages.buttonBack}
                  </Link>
                )
              }

              <ButtonMoons
                variant="contained"
                color="primary"
                onClick={() => processUploadPictures({
                  files,
                  customerId: patientCustomerId,
                  token: user.sessionToken,
                  planningId,
                  setInProgress,
                  historyApp,
                  handleSnack,
                  urlNextStep,
                  statePlann,
                  statusPlann,
                  user
                })}
                isprogress={Number(progress)}
              >
                {messages.button}
              </ButtonMoons>
            </>
          )}
        >

          <div className={styles.gridContainer}>
            {
              inputsConfig.map((inputfile, idx) => {
                const {
                  text, prop, uploaded, maxFiles, image, placeHolder, icon
                } = inputfile
                return (
                  <div key={`${prop}${idx}`}>
                    <h3>{text}</h3>
                    <DragAndDropMoons
                      handledFiles={(fileSelected, event) => updateFiles(fileSelected, event, prop)}
                      maxFiles={maxFiles}
                      img={image || <IconMoons name={icon} />}
                      placeholder={placeHolder}
                      showAcceptedFiles={prop === 'xray'}
                      typeFiles={prop === 'xray' ? '.pdf,.zip' : 'image/*'}
                    />
                    {
                      prop === 'xray' && uploaded && (
                        <TooltipMoons
                          title="Ver y Descargar"
                          placement="left"
                        >
                          <a
                            href={files[prop]}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButtonMoons
                              edge="start"
                              aria-label="download-file"
                              icon="cloud_download"
                              color="primary"
                            />
                          </a>
                        </TooltipMoons>
                      )
                    }
                  </div>
                )
              })
            }
          </div>

        </CardMoons>
      </>
    )
  )
}
