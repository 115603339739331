/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import PropTypes from 'prop-types'

import { Route, Redirect } from 'react-router-dom'

/**
 * Make private route
 * @param {*} param0 .
 * @param {*} param0.isAuthenticated is valid session
 * @param {*} param0.component to render
 * @returns {Object} protected route object
 */
export const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      isAuthenticated ? (<Component {...props} />) : (<Redirect to="/login" />)
    )}
  />
)

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired
}
