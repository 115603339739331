import React, { useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AppRouter } from './1-routers/AppRouter'
import { AppContextProvider } from './4-hooks/contexts/App/App.context'

const moment = require('moment-timezone')

moment.tz.setDefault('America/Mexico_City')

/**
 * App jsx
 * @returns {Void} component
 */
const B2BApp = () => {
  const [queryClient] = useState(() => new QueryClient())

  return (
    <AppContextProvider>
      <QueryClientProvider client={queryClient}>
        <AppRouter />
      </QueryClientProvider>
    </AppContextProvider>
  )
}

export default B2BApp
