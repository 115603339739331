const stlMethodMessages = {
  title: 'Paciente [patient]',
  subtitle: 'Selecciona el método de impresión que sea más cómodo para ti',
  lblShipping: 'Recolección de impresión',
  lblAppntmt: 'Escaneo en clínica Futura Labs',
  lblUpload: 'Subir archivo STL',
  btnSelect: 'SELECCIONAR',
  printing: 'printing',
  appointment: 'appointment',
  uploadStl: 'upload_stl',
  backButton: 'Paso anterior',
  back: 'Regresar',
  msgValidatingPics: 'Estamos validando la información. En cuanto el proceso concluya podrás seleccionar un método de recolección.'
}

export default stlMethodMessages
