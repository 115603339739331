const StlAppointmentMessages = {
  message1: 'Selecciona un día y un horario para ',
  label1: 'Día',
  label2: 'Horario',
  message2: 'Reservar',
  bookingMsg: 'Ve a reservar una cita. Cuando termines el proceso regresa a esta página y refresca para continuar',
  cardsClinics: [
    {
      state: 'Gudalajara',
      name: 'Circunvalación Vallarta',
      adress: 'Calle de prueba 1 colonia test CDMX',
      hours: 'Lun-Sab 10-19 hrs',
      logo: null
    },
    {
      state: 'Monterrey',
      name: 'Circunvalación Vallarta',
      adress: 'Calle de prueba 1 colonia test CDMX',
      hours: 'Lun-Sab 10-19 hrs',
      logo: null
    },
    {
      state: 'Gudalajara',
      name: 'Circunvalación Vallarta',
      adress: 'Calle de prueba 1 colonia test CDMX',
      hours: 'Lun-Sab 10-19 hrs',
      logo: null
    },
    {
      state: 'Monterrey',
      name: 'Circunvalación Vallarta',
      adress: 'Calle de prueba 1 colonia test CDMX',
      hours: 'Lun-Sab 10-19 hrs',
      logo: null
    },
  ]
}

export default StlAppointmentMessages
