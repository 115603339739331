/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import { CardMoons } from '../../UI/CardMoons/CardMoons'
import styles from './Diagnose.module.scss'
import messages from './Diagnose.messages'
import { DiagnoseModel } from '../../../6-models/App/Diagnose.model'
import { useForm } from '../../../4-hooks/useForm'
import { SelectMoons } from '../../UI/SelectMoons/SelectMoons'
import { RadioButtonsGroupMoons } from '../../UI/RadioButtonsGroupMoons/RadioButtonsGroupMoons'
import { ButtonMoons, InputMoons } from '../../UI'

/**
 * diagnose component
 * @returns {Void} diagnose mix validate pic component
 */
export const Diagnose = () => {
  const [
    formValues,,
    handleInputChange
  ] = useForm(new DiagnoseModel())
  const {
    diagnosis, severity, rank, patientNotes, doctorNotes
  } = formValues

  return (
    <CardMoons
      title={messages.message7}
      actions={(
        <ButtonMoons
          variant="contained"
          color="primary"
          onClick={() => null}
        >
          {messages.message6}
        </ButtonMoons>
      )}
    >
      <div className={styles.gridContainer}>
        <SelectMoons
          name="diagnosis"
          items={messages.diagnosisOptions}
          label={messages.message1}
          value={diagnosis}
          onChange={handleInputChange}
        />
        <div>
          <RadioButtonsGroupMoons
            row
            aria-label="severity"
            radios={messages.optionsSeverity}
            name="severity"
            value={severity}
            onChange={handleInputChange}
            label={messages.message2}
          />
        </div>
        <div>
          <RadioButtonsGroupMoons
            radios={messages.optionsRank}
            name="rank"
            value={rank}
            onChange={handleInputChange}
            label={messages.message3}
          />
        </div>
        <div>
          <h3>{messages.message4}</h3>
          <InputMoons
            type="text"
            name="patientNotes"
            autoComplete="off"
            value={patientNotes}
            onChange={handleInputChange}
            multiline
          />
        </div>
        <div>
          <h3>{messages.message5}</h3>
          <InputMoons
            type="text"
            name="doctorNotes"
            autoComplete="off"
            value={doctorNotes}
            onChange={handleInputChange}
            multiline
          />
        </div>
      </div>
    </CardMoons>
  )
}
