/* eslint-disable max-statements */
/* eslint-disable require-jsdoc */
import Resizer from 'react-image-file-resizer'

const zero = 0
const thou = 1000
const num1 = 1130
const num2 = 1080

/**
 * @param {Object} file - file type of image
 * @param {string} imgSrc - base64 of image
 * @return {JSON} json of response from server
 */
const changeData = async (file, imgSrc) => {
  let metadata = {}
  // const file = dataPhoto.photoToUpload

  const img = document.createElement('img')
  img.src = imgSrc

  metadata = getMetadata(img)
  // }
  if (file) {
    const blob = file.slice(zero, file.size, file.type)
    const newFile = new File([blob], file.name.toLowerCase(), { type: file.type })
    const newFileResize = await resizeFile(newFile, metadata)
    if (newFileResize) {
      // return { ...dataPhoto, ...{ photoToUpload: newFileResize } }
      return newFile
    }
  }
  return null
}

const resizeFile = (file, metadata) => new Promise(resolve => {
  Resizer.imageFileResizer(file, metadata.maxWidth, metadata.maxHeight, 'JPEG', metadata.quality, zero,
    uri => {
      resolve(uri)
    },
    'file')
})

const getMetadata = (img) => {
  const width = img.naturalWidth
  const height = img.naturalHeight
  let maxWidth = thou
  let maxHeight = thou
  const quality = 80
  let orientation = 'even'
  if (width > height) {
    orientation = 'landscape'
    maxWidth = num1
    maxHeight = num2
  } else if (width < height) {
    orientation = 'portrait'
    maxWidth = num2
    maxHeight = num1
  }
  return {
    orientation, width, height, maxWidth, maxHeight, quality
  }
}

export default changeData
