/* eslint-disable import/prefer-default-export */
import React from 'react'
import { PatientList } from '../../../3-components/App/Patient/PatientList/PatientList'

/**
 * Patients view
 * @returns {Void} component
 */
export const PatientsView = () => (
  <PatientList />
)
