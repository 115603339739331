import { requestMoons } from '../../8-common/Request'

/**
 * get specific user data: for doctors, dr moons and admin
 * used by admins to get and then edit doctor info
 * used by all users to get then edit their own info
 * @param {String} objectId user's objectid to fetch
 * @returns {Object} User data
 */
export const getUserData = (objectId) => requestMoons({
  endpoint: '/users/fetch',
  method: 'GET',
  params: {
    userId: objectId
  },
  useBasicAuthHeader: true
})

/**
 * edits User data
 * @param {String} userObjId user's objectId identifier
 * @param {Object} userData User data object
 * @returns {Object} updated user data
 */
export const updateUser = (userObjId, userData) => requestMoons({
  endpoint: '/users/edit',
  method: 'PUT',
  params: {
    objectId: userObjId
  },
  body: userData,
  useBasicAuthHeader: true
})
