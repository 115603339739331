/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import { FormControl, InputLabel, Select } from '@material-ui/core'
import PropTypes from 'prop-types'
import { OptionModelSelectMoons } from './Option.model'
import styles from './SelectMoons.module.scss'
import { thousand } from '../../../8-common/Constants'

/**
 * select reusable moons
 * @param {*} props .
 * @returns {Void} component
 */
export const SelectMoons = (props) => {
  const idLabel = `${Number(new Date())}-${Math.floor(Math.random() * thousand)}`

  const {
    items, label, value, onChange, name, required, disabled, onClick
  } = props

  return (
    <FormControl
      size="small"
      variant="filled"
      className={[styles.formControl, required ? 'required' : ''].join(' ')}
    >
      <InputLabel htmlFor={idLabel}>{label}</InputLabel>
      <Select
        native
        value={value}
        onChange={onChange}
        inputProps={{
          name,
          id: idLabel,
        }}
        disabled={disabled}
        onClick={onClick}
        classes={{
          select: styles.fullHeigth
        }}
      >
        <option
          aria-label="Sin selección"
          value={''}
        />
        {items &&
          items.map((item, idx) => (
            <option
              key={idx}
              value={item.value}
            >
              {item.text}
            </option>
          ))
        }
      </Select>
    </FormControl>
  )
}

SelectMoons.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(OptionModelSelectMoons)).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
}
