import { requestMoons } from '../../8-common/Request'

/**
 * get list of all patients
 * @param {String} token user´s session token
 * @returns {Array} of Patient objects
 */
export const getAllPatients = (token) => requestMoons({
  endpoint: '/patients',
  method: 'GET',
  customHeaders: {
    Authorization: token
  }
})

/**
 * gets list of patients by doctor
 * @param {String} token user´s session token
 * @param {String} doctorId doctor´s objectId (userId)
 * @returns {Array} Patient objects list
 */
export const getPatientsByDoctor = (token, doctorId) => requestMoons({
  endpoint: `/patients/${doctorId}`,
  method: 'GET',
  customHeaders: {
    Authorization: token
  }
})

/**
 * fetch specific patient data
 * @param {*} token user´s session token
 * @param {*} patientId Patient objectId to fetch data
 * @returns {Object} Patiento dto
 */
export const getPatientData = (token, patientId) => requestMoons({
  endpoint: `/patient/get/${patientId}`,
  method: 'GET',
  customHeaders: {
    Authorization: token
  }
})

/**
 * creates new Patient
 * @param {Object} param0 .
 * @param {Object} param0.patient Patient object data
 * @param {String} param0.token user´s session token
 * @param {String} param0.userId Doctor objectId to relate Patient to
 * @returns {Object} new Patient object data
 */
export const createPatient = ({ patient, token, userId }) => {
  const config = {
    endpoint: '/patient',
    method: 'POST',
    body: patient,
    customHeaders: {
      Authorization: token
    }
  }
  if (userId) {
    config.params = {
      userId
    }
  }
  return requestMoons(config)
}

/**
 * updates patient data
 * @param {*} param0 .
 * @param {String} param0.token user´s session token
 * @param {String} param0.patientId Patient´s objectId indetifier to update
 * @param {Object} param0.patientData new data Patient dto
 * @returns {Object} updated Patient dto
 */
export const updatePatient = ({ token, patientId, patientData }) => requestMoons({
  endpoint: `/patient/${patientId}`,
  method: 'PUT',
  body: patientData,
  customHeaders: {
    Authorization: token
  }
})

/**
 * delete patient by objectId
 * @param {*} param0 .
 * @param {String} param0.token user´s session token
 * @param {String} param0.patientId Patient´s objectId indetifier to update
 * @returns {*} void
 */
export const deletePatient = ({ token, patientId }) => requestMoons({
  endpoint: `/patient/${patientId}`,
  method: 'DELETE',
  customHeaders: {
    Authorization: token
  }
})
