/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-literals */
import React, { useState, useCallback } from 'react'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { IconButtonMoons } from '../IconButtonMoons/IconButtonMoons'

/**
 * MenuMoons reusable material menu component
 * @returns {Void} component
 */
const MenuMoons = ({ btnName, btnIcon, children }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  /**
   * Handle open menu
   */
  const handleClick = useCallback((event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  /**
   * Handle close menu
   */
  const handleClose = useCallback((event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  return (
    <>
      {
        (btnName || (!btnName && !btnIcon)) && (
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            {btnName || 'Opciones'}
          </Button>
        )
      }
      {
        btnIcon && (
          <IconButtonMoons
            edge="start"
            aria-label="back_history"
            icon={btnIcon}
            color="primary"
            onClick={handleClick}
          />
        )
      }
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          children.map((childElem, index) => childElem && (
            <MenuItem
              key={`child-option-${index}`}
              onClick={handleClose}
            >
              {childElem}
            </MenuItem>
          ))
        }
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </>
  )
}

export default MenuMoons
