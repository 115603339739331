/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { RadioModelRadioButtonsGroupMoons } from './Radio.model'
import styles from './RadioButtonsGroupMoons.module.scss'

/**
 * Radio group moons component
 * @param {*} props .
 * @returns {Void} component
 */
export const RadioButtonsGroupMoons = (props) => {
  const {
    disableAll, radios, label, ...rest
  } = props

  return (
    <FormControl component="fieldset">
      <FormLabel
        component="legend"
        className={styles.label}
      >
        {label}
      </FormLabel>
      <RadioGroup
        color="default"
        {...rest}
      >
        {
          radios.map((radio, idx) => (radio.disabled
            && (
              <FormControlLabel
                key={`radio-${idx}-${radio.value}`}
                value={radio.value}
                disabled
                control={(
                  <Radio
                    size="small"
                    color="primary"
                  />
                )}
                label={radio.text}
                className={styles.wrappCheck}
              />
            )
          ) || (
            <FormControlLabel
              key={`radio-${idx}-${radio.value}`}
              value={radio.value}
              disabled={disableAll}
              control={(
                <Radio
                  size="small"
                  color="primary"
                />
              )}
              label={radio.text}
              className={styles.wrappRadio}
            />
          ))
        }
      </RadioGroup>
    </FormControl>
  )
}

RadioButtonsGroupMoons.propTypes = {
  radios: PropTypes.arrayOf(PropTypes.instanceOf(RadioModelRadioButtonsGroupMoons)).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.node.isRequired
}

RadioButtonsGroupMoons.defaultProps = {
  label: '',
}
