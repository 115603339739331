/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { IconButtonMoons } from '../IconButtonMoons/IconButtonMoons'
import styles from './ReturnHistory.module.scss'

/**
 * Generic arrow return history
 * @param {*} param0 .
 * @returns {Void} component
 */
const ReturnHistory = ({ route, message, historyApp }) => (
  <div className={styles.regionBtnBack}>
    <IconButtonMoons
      edge="start"
      aria-label="back_history"
      icon="arrow_back"
      color="secondary"
      onClick={() => (route ? historyApp.push(route) : historyApp.goBack())}
    />
    <p>{message || 'Regresar'}</p>
  </div>
)

export default ReturnHistory
