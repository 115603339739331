/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, { useContext } from 'react'
import { useParams } from 'react-router'
import messages from './UpdateForm.messages'
import { handleSubmitFormUpdate } from './UpdateForm.logic'
import { useForm } from '../../../4-hooks/useForm'
import { UpdateDto } from '../../../6-models/Login/Update.dto'
import { ButtonMoons, InputMoons } from '../../UI'
import { AppContext } from '../../../4-hooks/contexts/App/App.context'
// import styles from './UpdateForm.module.scss'

/**
 * Update form component
 * @returns {Void} component
 */
export const UpdateForm = () => {
  const { publicKey } = useParams()
  const { stateApp: { functions: { handleSnack } } } = useContext(AppContext)
  const [formValues, , handleInputChange,,
    progress,
    setInProgress] = useForm({ ...new UpdateDto({ publicKey }), repeatPass: '' })
  const { password, repeatPass } = formValues

  return (
    <form
      className="generic"
      onSubmit={(event) => handleSubmitFormUpdate(
        event,
        formValues,
        { handleSnack, setInProgress }
      )}
    >
      <h1>{messages.FormH1}</h1>
      <InputMoons
        type="password"
        name="password"
        autoComplete="off"
        placeholder={messages.placeHolderInput1}
        value={password}
        onChange={handleInputChange}
      />
      <InputMoons
        type="password"
        name="repeatPass"
        autoComplete="off"
        placeholder={messages.placeHolderInput2}
        value={repeatPass}
        onChange={handleInputChange}
      />
      <ButtonMoons
        variant="contained"
        color="primary"
        type="submit"
        isprogress={Number(progress)}
      >
        {messages.textButton}
      </ButtonMoons>

    </form>
  )
}
