/* eslint-disable import/prefer-default-export */
/**
 * row data table model
 */
export class RowDataTableModel {
  /**
   * constructor
   * @param {*} object row data model
   */
  constructor(object = {}) {
    this.id = object.id || ''
    for (const key in object) {
      if (Object.hasOwnProperty.call(object, key)) {
        this[key] = object[key]
      }
    }
  }
}
