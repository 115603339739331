/* eslint-disable import/prefer-default-export */
/**
 * Pictures model
 */
export class PicturesPatientModel {
  /**
   * constructor
   * @param {*} data Patient Pictures data
   */
  constructor(data = {}) {
    this.trueSmile = data?.trueSmile?.url || ''
    this.profileFront = data?.profileFront?.url || ''
    this.profileRight = data?.profileRight?.url || ''
    this.frontOcclusion = data?.frontOcclusion?.url || ''
    this.rightLateralOcclusion = data?.rightLateralOcclusion?.url || ''
    this.leftLateralOcclusion = data?.leftLateralOcclusion?.url || ''
    this.superiorOcclusion = data?.superiorOcclusion?.url || ''
    this.lowerOcclusion = data?.lowerOcclusion?.url || ''
    this.xray = data?.xray?.url || ''
  }
}
