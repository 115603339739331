import { useQuery } from 'react-query'
import { getPlanningData } from '../../7-services/App/Planning.service'



export const usePlanningQuery = (sessionToken, objectId) => {
  return useQuery(['planning', { objectId }], () => getPlanningData(sessionToken, objectId), {
    enabled: !!objectId,
  })
}
