/* eslint-disable import/prefer-default-export */
/**
 * Diagnose model
 */
export class DiagnoseModel {
  /**
   * constructor
   * @param {*} data .
   */
  constructor(data = {}) {
    this.diagnosis = data.diagnosis || ''
    this.severity = data.severity || ''
    this.rank = data.rank || ''
    this.patientNotes = data.patientNotes || ''
    this.doctorNotes = data.doctorNotes || ''
  }
}
