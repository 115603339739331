/* eslint-disable complexity */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { SelectMethod } from './SelectMethod/SelectMethod'
import StlPrinting from './StlPrinting/StlPrinting'
import messages from './StlMethod.messages'
import { image1, image2, image3 } from '../../../../9-assets/images/stlMethod'
import { StlAppointment } from './StlAppointment/StlAppointment'
import { StlUpload } from './StlUpload/StlUpload'
import { IconButtonMoons } from '../../../UI/IconButtonMoons/IconButtonMoons'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * handle change between front select stl method
 * @param {Object} param0 .
 * @param {String} param0.chosenMethod screen user choice
 * @param {String} param0.doctorId doctor's objectId to fetch address data
 * @param {String} param0.patientData Patient object data
 * @param {Object} param1 .
 * @param {Function} param1.setChosenMethod set chosen method to change screen
 * @returns {Void} component from chosen method
 */
export const selectStlMethod = (
  {
    chosenMethod,
    doctorId,
    planningId,
    customerId,
    patientData
  },
  {
    setChosenMethod
  }
) => {
  switch (chosenMethod) {
  case messages.uploadStl:
    return (
      <StlUpload
        setMethod={setChosenMethod}
        planningId={planningId}
        customerId={customerId}
      />
    )
  case messages.printing:
    return (
      <StlPrinting
        doctorId={doctorId}
        planningId={planningId}
        setMethod={setChosenMethod}
      />
    )
  case messages.appointment:
    return (
      <StlAppointment
        planningId={planningId}
        patientData={patientData}
      />
    )
  default:
    return <SelectMethod setMethod={setChosenMethod} />
  }
}

/**
 * get specific method title
 * @param {*} chosenMethod user's screen shosen method
 * @param {*} className styles
 * @returns {Void} component
 */
export const getTitle = (chosenMethod, className = '') => {
  switch (chosenMethod) {
  case messages.uploadStl:
    return (
      <div>
        <img
          src={image3}
          className={className}
          alt="upload-method"
        />
        {messages.lblUpload}
      </div>
    )
  case messages.printing:
    return (
      <div>
        <img
          src={image1}
          className={className}
          alt="printing-shipping-method"
        />
        {messages.lblShipping}
      </div>
    )
  case messages.appointment:
    return (
      <div>
        <img
          src={image2}
          className={className}
          alt="appointment-method"
        />
        {messages.lblAppntmt}
      </div>
    )
  default:
    return <></>
  }
}

/**
 * get waiting message for user depending on the status
 * @param {String} state Planning state
 * @param {String} status Planning status
 * @param {String} planningId Planning objectId
 * @param {Function} historyApp router
 * @returns {String} message
 */
export const getWaitMessage = ({
  state, status, planningId, historyApp
}) => {
  switch (status) {
  case 'waiting_for_review':
  case 'valid_pictures':
    return 'Estamos validando las fotos. En cuanto el proceso concluya podrás seleccionar un método de recolección.'

  case 'pickup_requested':
    return 'Hemos recibido tu solicitud de recolección de impresión. Pronto recibirás notificaciones del estatus del proceso.'

  case 'waiting_printing':
    return 'Estamos en el proceso de recolección de impresión. Pronto recibirás notificaciones del estatus del proceso.'

  case 'printing_received':
    return 'Hemos recibido la impresión y comenzaremos a generar el archivo STL correspondiente. Pronto recibirás notificaciones del estatus del proceso.'

  case 'waiting_appointment':
    return 'Hemos recibido tu solicitud de cita. Pronto recibirás notificaciones del estatus del proceso.'

  case 'booked':
    return 'La cita de tu paciente fue agendada. Pronto recibirás notificaciones del estatus del proceso.'

  case 'not_attended':
    return 'El paciente no asistió a la cita. Pronto recibirás notificaciones del estatus del proceso.'

  case 'attended':
    return 'El paciente asistió a la cita y comenzaremos el proceso de generación del archivo STL. Pronto recibirás notificaciones del estatus del proceso.'

  case 'waiting_for_planning':
    return 'Estamos en el proceso de creación de la Planeación de tu paciente. Pronto recibirás notificaciones del estatus del proceso.'

  case 'approved':
    return (
      <>
        <p>Has aprobado la Planeación. Dirígete al resumen para ver los detalles</p>
        <IconButtonMoons
          edge="start"
          aria-label="back_history"
          icon="arrow_forward"
          color="primary"
          onClick={() => historyApp.push(`/${prefijApp}/planning-sumary/${planningId}`)}
        />
      </>
    )

  case 'no_candidate':
    return 'El paciente ha sido determinado como No Candidato. Pronto recibirás notificaciones del estatus del proceso.'

  case 'finished':
    return (
      <>
        <p>Esta Planeación ha terminado su proceso. Dirígete al resumen para ver los detalles</p>
        <IconButtonMoons
          edge="start"
          aria-label="back_history"
          icon="arrow_forward"
          color="primary"
          onClick={() => historyApp.push(`/${prefijApp}/planning-sumary/${planningId}`)}
        />
      </>
    )

  case '': {
    if (state === 'planned') {
      return (
        <>
          <p>
            Hemos terminado la Planeación.
            Dirígete al resumen para ver los detalles y poder aprobarla
          </p>
          <IconButtonMoons
            edge="start"
            aria-label="back_history"
            icon="arrow_forward"
            color="primary"
            onClick={() => historyApp.push(`/${prefijApp}/planning-sumary/${planningId}`)}
          />
        </>
      )
    }
    return 'Estamos validando la información. Pronto podrás continuar con el proceso'
  }

  default: return 'Estamos validando la información. Pronto podrás continuar con el proceso'
  }
}
