/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

/**
 * date picker moons component
 * @param {*} props .
 * @returns {Void} component
 */
export const DatePickerMoons = (props) => {
  const {
    onChange, name, showTime, timeLabel = 'Hora', disabled, ...rest
  } = props
  return (
    <MuiPickersUtilsProvider
      utils={MomentUtils}
    >
      <>
        <KeyboardDatePicker
          disabled={disabled}
          variant="inline"
          format="DD/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          invalidDateMessage="Fecha inválida"
          name={name}
          onChange={(evt) => onChange(evt, name)}
          {...rest}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
        {
          showTime && (
            <KeyboardTimePicker
              {...rest}
              margin="normal"
              id="time-picker"
              label={timeLabel}
              name={name}
              onChange={(evt) => onChange(evt, name)}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          )
        }
      </>
    </MuiPickersUtilsProvider>
  )
}
