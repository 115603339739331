import React from 'react'
import { FormGroup, FormControlLabel, Switch } from '@material-ui/core'

/**
 * Custom switch moons comp
 * @param {*} props switch props
 * @returns {Void} switch moons component
 */
const SwitchMoons = (props) => {
  const {
    label, color, checked, onChange, name, required
  } = props

  return (
    <FormGroup row>
      <FormControlLabel
        control={(
          <Switch
            checked={checked}
            onChange={onChange}
            name={name}
            color={color}
          />
        )}
        label={label}
        required={required}
      />
    </FormGroup>
  )
}

export default SwitchMoons
