/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, { useContext, useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from 'react-router-dom'
import { AccesView } from '../../2-views/Login/AccesView/AccesView'
import { RecoveryView } from '../../2-views/Login/RecoveryView/RecoveryView'
import { UpdateView } from '../../2-views/Login/UpdateView/UpdateView'
import { InformationPanel } from '../../3-components/Login/InformationPanel/InformationPanel'
import { AppContext } from '../../4-hooks/contexts/App/App.context'
import { appTypes } from '../../4-hooks/contexts/App/App.types'
import useLocationOrigin from '../../4-hooks/useLocationOrigin'
import { threeHun } from '../../8-common/Constants'
import styles from './LoginRouter.module.scss'

/**
 * Login router
 * @returns {Void} router login
 */
export const LoginRouter = () => {
  const routCreate = '/create-account'
  const classRightSection = 'rightPanelActive'

  const [section, setSection] = useState('')
  const history = useHistory()
  const { dispatch } = useContext(AppContext)
  const countryPath = useLocationOrigin()

  console.log("countryPath", countryPath)
  useEffect(() => {
    setTimeout(() => {
      if (window.location.pathname === routCreate) setSection(classRightSection)
    }, threeHun)
    dispatch({ type: appTypes.updateHistory, payload: history })
  }, [dispatch, history])

  return (
    <div className={styles.loginContainer}>
      <Router basename={countryPath}>
        <div className={`${styles.container} ${section}`}>
          <Switch>
            <Route
              exact
              path="/login"
              component={AccesView}
            />
            <Route
              exact
              path="/create-account"
              component={AccesView}
            />
            <Route
              exact
              path="/recovery"
              component={RecoveryView}
            />
            <Route
              exact
              path="/update-password/:publicKey"
              component={UpdateView}
            />
            <Redirect to="/login" />
          </Switch>
          <InformationPanel
            updateSection={setSection}
            classNameCreateAccount={classRightSection}
          />
        </div>
      </Router>
    </div>

  )
}
