const shippingMessages = {
  backBtn: 'Pacientes',
  btnEdit: 'Editar',
  title: 'Editar recolección de [patient]',
  phDatetime: 'Fecha de la Recolección',
  lblTimeDatetime: 'Hora de la Recolección',
  phLabelUrl: 'Etiqueta',
  phState: 'Estado',
  phStatus: 'Estatus',
  lblChangeStatus: 'Flujo Recolección solicitada',
  alertStatus: 'Aviso: esto causará un cambio en el Estado/Estatus (imprimiendo/esperando impresión) de la Planeación'
}

export default shippingMessages
