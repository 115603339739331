/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import { CreateAccountForm } from '../../../Login/CreateAccountForm/CreateAccountForm'

/**
 * doctor detail component
 * @param {*} param0 .
 * @returns {Void} component
 */
export const DoctorDetail = ({ isEdit, model }) => (
  <CreateAccountForm title="Nuevo Doctor" />
  // <span>dasdasd</span>
)
