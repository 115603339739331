import { Select } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { updatePlanning } from '../../../../../7-services/App/Planning.service'
import { OptionModelSelectMoons } from '../../../../UI/SelectMoons/Option.model'
import { SelectMoons } from '../../../../UI/SelectMoons/SelectMoons'


/** */
const AssignmentRow = ({ row, user, handleSnack, setIsLoading }) => {
  const [users, setUsers] = useState([])
  const [chosenDr, setChosenDr] = useState('')

  useEffect(() => {
    const formattedUsers = row.row.users.map(u => new OptionModelSelectMoons({
      text: u.name,
      value: u.objectId
    }))
    setUsers(formattedUsers)
    if (row?.row?.planning?.assignedTo) {
      setChosenDr(row?.row?.planning?.assignedTo.objectId)
    }
  }, [])

  /** */
  const assignPlanning = async (event) => {
    setChosenDr(event.target.value)
    const planningUpdate = {
      assignedTo: event.target.value,
      updatedBy: user.objectId
    }

    try {
      setIsLoading(true)
      await updatePlanning(user.sessionToken, row.row.planning.objectId, planningUpdate)
      handleSnack(true, 'Se ha asignado la planeación', 'success')
    } catch (error) {
      handleSnack(true, 'Error al asignar la planeación', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <SelectMoons
      name={'chosenDr'}
      items={users}
      value={chosenDr}
      onChange={assignPlanning}
      onClick={(evt) => evt.stopPropagation()}
    />
  )
}

export default AssignmentRow
