export const zero = 0
export const one = 1
export const two = 2
export const three = 3
export const five = 5
export const ten = 10
export const fifty = 50
export const twoHun = 200
export const fourOOne = 401
export const threeHun = 300
export const thousand = 1000
export const sixty = 60
export const stateKeys = {
  initial: 'Inicial',
  pictures_uploaded: 'Fotos subidas',
  diagnosed: 'Diagnosticado',
  stl_required: 'STL Requerido',
  printing: 'Imprimiendo',
  scanning_printing: 'Escaneando e imprimiendo',
  appointment: 'Cita',
  stl_uploaded: 'STL Cargado',
  planned: 'Planeado',
  production: 'Producción',
  closed: 'Cerrado',
  delimited: 'Delimitado',
  replanning: 'Replaneación',
  approved: 'Aprobado',
  exported: 'Exportado',
  send: 'Enviado',
  delivered: 'Entregado'
}
export const statusKeys = {
  '': 'Vacío',
  invalid_pictures: 'Fotos inválidas',
  waiting_for_review: 'Esperando revisión',
  valid_pictures: 'Fotos válidas',
  candidate: 'Candidato',
  waiting_stl: 'Esperando STL',
  pickup_requested: 'Recolección solicitada',
  waiting_appointment: 'Esperando cita',
  waiting_printing: 'Esperando impresión',
  printing_received: 'Impresión recibida',
  booked: 'Agendada',
  not_attended: 'No atendió',
  attended: 'Atendió',
  waiting_for_planning: 'Esperando planeación',
  approved: 'Aprobada',
  no_candidate: 'No Candidato',
  finished: 'Terminada',
  on_going: 'En proceso',
  ready: 'Listo'
}
export const errorCodes = {
  invalid_phone_format: 'Formato de teléfono incorrecto. Aceptados: 0000000000/+521000000000/00 0000 0000/00-00-00-00-00',
  invalid_name_format: 'Formato de nombre incorrecto. Debe ser: Nombre, Apellido Paterno, Apellido Materno',
  invalid_country_format: 'Formato de país incorrecto. Debe ser MX/CO',
  invalid_email_format: 'Formato de correo incorrecto. Debe ser: nombre@domain',
  role_does_not_exist: 'El rol que buscas otorgar no existe',
  user_already_registered: 'Ya se ha registrado un usuario con este correo',
  user_not_found: 'Usuario no encontrado'
}
export const countryTz = {
  CO: 'America/Bogota',
  MX: 'America/Mexico_City'
}

export const COUNTRIES = ['mx', 'co']

export const HTTP_STATUS = {
  ok: 200,
  noContent: 204
}

export const ROLES = {
  doctor: 'DOCTOR',
  superAdmin: 'SUPER_ADMIN',
  doctorInterno: 'DOCTOR_MOONS',
  admin: 'ADMIN'
}
