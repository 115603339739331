/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import styles from './FormWrapper.module.scss'

/**
 * form wrapper component
 * @param {*} param0 .
 * @param {*} param0.children children to wrap
 * @param {*} param0.classContainer styles for container
 * @returns {Void} component
 */
export const FormWrapper = ({ children, classContainer }) => (
  <div className={`${styles.formContainer} ${classContainer}`}>
    {
      children
    }
  </div>
)
