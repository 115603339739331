/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, { useContext, useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useForm } from '../../../4-hooks/useForm'
import { CreateDto } from '../../../6-models/Login/Create.dto'
import { handleSubmitForm } from './CreateAccountForm.logic'
import { ButtonMoons, InputMoons } from '../../UI'
import { AppContext } from '../../../4-hooks/contexts/App/App.context'
import { SelectMoons } from '../../UI/SelectMoons/SelectMoons'
import { IconButtonMoons } from '../../UI/IconButtonMoons/IconButtonMoons'
import { CardMoons } from '../../UI/CardMoons/CardMoons'
import { TooltipMoons } from '../../UI/TooltipMoons/TooltipMoons'
import { getUserData } from '../../../7-services/App/User.service'
import LoaderMoons from '../../UI/LoaderMoons/LoaderMoons'
import { compareObjects } from '../../../8-common/UtilFunctions'
import styles from './CreateAccountForm.module.scss'
import messages from './CreateAccountForm.messages'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * Create/Edit account form component
 * @param {*} param0 .
 * @param {String} param0.title form title
 * @returns {Void} component
 */
export const CreateAccountForm = ({ title }) => {
  const { stateApp: { functions, historyApp } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const [
    formValues,
    setValues,
    handleInputChange,
    reset,
    progress,
    setInProgress
  ] = useForm(new CreateDto())
  const {
    name,
    username,
    phone,
    countryOps,
    role,
    street,
    apt,
    neighborhood,
    city,
    state,
    zip
  } = formValues
  const { userObjId } = useParams()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [serviceData, setServiceData] = useState({})
  const [saveUserType, setUserType] = useState('')

  useEffect(() => {
    if (userObjId) {
      setIsLoading(true)
      getUserData(userObjId)
        .then(res => {
          const user = new CreateDto(res)
          setValues(user)
          setIsLoading(false)
          setServiceData(user)
          setUserType(res?.userType || '')
        })
        .catch(error => {
          setIsLoading(false)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    isLoading ? <LoaderMoons size={120} /> : (
      <>
        <div className={[styles.gridContainer, styles.header].join(' ')}>
          <div className={styles.regionBtnBack}>
            <IconButtonMoons
              edge="start"
              aria-label="back_history"
              icon="arrow_back"
              color="primary"
              onClick={() => historyApp.push(`/${prefijApp}/doctors`)}
            />
            <p>{messages.backButton}</p>
          </div>
        </div>

        <form
          onSubmit={(evt) => handleSubmitForm(
            evt, { formValues, userObjId, historyApp }, { reset, handleSnack, setInProgress }
          )}
        >

          <CardMoons
            title={userObjId ? messages.titleEdit.replace('[name]', name) : title || messages.FormH1}
            actions={(
              <ButtonMoons
                variant="contained"
                color="primary"
                type="submit"
                isprogress={Number(progress)}
                disabled={userObjId ? compareObjects(formValues, serviceData) : false}
              >
                {userObjId ? messages.txtBtnEdit : messages.textButton}
              </ButtonMoons>
            )}
          >
            {
              progress ? <LoaderMoons size={100} /> : (
                <>
                  <div className={styles.gridContainer}>

                    <InputMoons
                      autoFocus
                      type="text"
                      name="name"
                      autoComplete="off"
                      placeholder={messages.name}
                      value={name}
                      onChange={handleInputChange}
                      required
                    />

                    <InputMoons
                      type="text"
                      name="username"
                      autoComplete="off"
                      placeholder={messages.username}
                      value={username}
                      onChange={handleInputChange}
                      required
                    />

                    <TooltipMoons
                      title="Teléfono requerido a 10 dígitos"
                      placement="left"
                    >
                      <div>
                        <InputMoons
                          type="text"
                          name="phone"
                          autoComplete="off"
                          placeholder={messages.phone}
                          value={phone}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </TooltipMoons>

                    <SelectMoons
                      name="countryOps"
                      items={messages.countries}
                      label={messages.countryOps}
                      value={countryOps}
                      onChange={handleInputChange}
                      required
                    />

                    {
                      !userObjId && (
                        <SelectMoons
                          name="role"
                          items={location.pathname.includes('doctor') ? messages.profilesDr : messages.profiles}
                          label={messages.role}
                          value={role}
                          onChange={handleInputChange}
                          required
                        />
                      )
                    }
                  </div>

                  {
                    ((role === 'DOCTOR') || (saveUserType === 'DOCTOR')) && (
                      <>
                        <h1 className={styles.addressTitle}>{messages.addrsTitle}</h1>

                        <div className={styles.gridContainer}>

                          <InputMoons
                            type="text"
                            name="street"
                            autoComplete="off"
                            placeholder={messages.street}
                            value={street}
                            onChange={handleInputChange}
                          />

                          <InputMoons
                            type="text"
                            name="apt"
                            autoComplete="off"
                            placeholder={messages.apt}
                            value={apt}
                            onChange={handleInputChange}
                          />
                          <InputMoons
                            type="text"
                            name="neighborhood"
                            autoComplete="off"
                            placeholder={messages.neighborhood}
                            value={neighborhood}
                            onChange={handleInputChange}
                          />
                          <InputMoons
                            type="text"
                            name="zip"
                            autoComplete="off"
                            placeholder={messages.zip}
                            value={zip}
                            onChange={handleInputChange}
                          />

                          <InputMoons
                            type="text"
                            name="city"
                            autoComplete="off"
                            placeholder={messages.city}
                            value={city}
                            onChange={handleInputChange}
                          />

                          <InputMoons
                            type="text"
                            name="state"
                            autoComplete="off"
                            placeholder={messages.state}
                            value={state}
                            onChange={handleInputChange}
                          />

                        </div>
                      </>
                    )
                  }

                </>
              )
            }

          </CardMoons>

        </form>
      </>
    )
  )
}
