/* eslint-disable no-negated-condition */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { RowDataTableModel } from '../../../UI/DataTableMoons/RowDataTable.model'
import { ButtonMoons } from '../../../UI'
import MenuMoons from '../../../UI/MenuMoons/MenuMoons'
import { deletePatient, getAllPatients, getPatientsByDoctor } from '../../../../7-services/App/Patient.service'
import { ROLES, stateKeys, statusKeys } from '../../../../8-common/Constants'
import { IconButtonMoons } from '../../../UI/IconButtonMoons/IconButtonMoons'
import { getAllUsersByRole } from '../../../../7-services/App/Doctor.service'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * get list of patients. then transform to table data
 * @param {Object} user user´s info
 * @param {String} doctorId doctor´s objectId to filter patients by
 * @returns {Array} list of patients in material data grid
 */
export const getRowsPatients = async (user, doctorId = null) => {
  const one = 1
  let patients = []
  let users = []
  try {
    patients = doctorId ? await getPatientsByDoctor(user.sessionToken, doctorId)
      : await getAllPatients(user.sessionToken)
    if (user.role[0].name.includes(ROLES.admin)) users = await getAllUsersByRole([ROLES.doctor, ROLES.superAdmin, ROLES.doctorInterno])
  } catch (error) {
    console.error('Error GET Patients: ', error)
  }

  return patients.map((patient, idx) => new RowDataTableModel({
    id: idx + one,
    customerId: patient.customerId,
    name: patient.name,
    email: patient.email,
    number: patient.phone,
    planningState: stateKeys[patient?.planning?.state || ''],
    planningStatus: statusKeys[patient?.planning?.status || ''],
    objectId: patient.objectId,
    planningId: patient?.planning?.objectId,
    state: patient?.planning?.state,
    status: patient?.planning?.status,
    lastUpdate: moment(patient?.planning?.updatedAt).format('DD/MM/YY, h:mm a'),
    start: moment(patient?.planning?.createdAt).format('DD/MM/YY'),
    assignedTo: patient?.planning?.assignedTo ? patient.planning.assignedTo.name : 'Sin asignar',
    users,
    planning: patient.planning
  }))
}

/**
 * handles render creation of jsx components
 * @param {*} rowData patient row data
 * @param {Object} user current app user
 * @returns {Object} jsx component
 */
export const renderCellActions = (rowData, user) => (
  <MenuMoons btnIcon="menu_open">
    <Link to={`/${prefijApp}/patient/${rowData.row?.objectId}`}>
      <ButtonMoons
        size="small"
        onClick={(evt) => {
          evt.stopPropagation()
        }}
      >
        Actualizar
      </ButtonMoons>
    </Link>
    {
      user?.role[0]?.name === 'DOCTOR' && showPlanningSummary(rowData.row?.state, rowData.row?.status) ? (
        <Link to={`/${prefijApp}/planning-sumary/${rowData.row?.planningId}`}>
          <ButtonMoons
            size="small"
            onClick={(evt) => {
              evt.stopPropagation()
            }}
          >
            Planeación
          </ButtonMoons>
        </Link>
      ) : null
    }
    {
      user?.role[0]?.name !== 'DOCTOR' ? (
        <Link to={`/${prefijApp}/planning/form/${rowData.row?.planningId}`}>
          <ButtonMoons
            size="small"
            onClick={(evt) => {
              evt.stopPropagation()
            }}
          >
            Planeación
          </ButtonMoons>
        </Link>
      ) : null
    }
  </MenuMoons>
)

/**
 * 
 */
export const renderCellPatientActions = (rowData, user, openDeletionConfirmModal) => (
  <IconButtonMoons
    edge="start"
    aria-label="back_history"
    icon="delete"
    color="primary"
    onClick={(event) => {
      event.stopPropagation()
      openDeletionConfirmModal(rowData)
    }}
  />
)

/**
 * handles row click in patient list
 * @param {Object} rowData data table row data doctor
 * @param {Function} historyApp history router
 * @returns {Void} handles routing
 */
export const onPatientClick = (rowData, historyApp) => {
  if (rowData.field !== 'actions') {
    if (rowData?.row?.state === 'initial' && (rowData?.row?.status === '' || !rowData?.row?.status)) {
      historyApp.push(`/${prefijApp}/patient-planning/${rowData?.row?.planningId}/medical-history`)
      return
    }
    if (rowData?.row?.status === 'invalid_pictures') {
      historyApp.push(`/${prefijApp}/patient-planning/${rowData?.row?.planningId}/pictures-patient`)
      return
    }
    historyApp.push(`/${prefijApp}/patient-planning/${rowData?.row?.planningId}/collection-method`)
  }
}

/**
 * Show or hide planning summary link
 * @param {String} state Planning state
 * @param {String} status Planning status
 * @returns {Boolean} show/hide
 */
const showPlanningSummary = (state, status) => {
  if (state === 'planned' && (status === '' || !status)) {
    return true
  }
  if (state === 'planned' && status === 'approved') {
    return true
  }
  if (state === 'production') {
    return true
  }
  if (state === 'closed' && status === 'finished') {
    return true
  }
  return false
}

/**
 * Deletes the selected patient
 * @param {String} patient Id to be deleted
 * @param {Object} user Logged user info
 * @param {Function} setIsOpenModal modal state function
 * @param {Function} setInProgress Loading spinner state function
 * @param {Function} handleSnack Snackbar state function
 * @param {Function} loadPatients Patients fetch function
 * @return {*} void
 */
export const deleteSelectedPatient = async (patient, user, setIsOpenModal, setInProgress, handleSnack, loadPatients) => {
  try {
    setInProgress(true)
    await deletePatient({
      patientId: patient.row.objectId,
      token: user.sessionToken
    })
    handleSnack(true, 'Paciente borrado con éxito', 'success')
    loadPatients()
  } catch (error) {
    handleSnack(true, 'Error al borrar el Paciente', 'error')
  } finally {
    setInProgress(false)
    setIsOpenModal(false)
  }
}
