/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, {
  useEffect, useState, useContext, useRef, useCallback
} from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CardMoons } from '../../../UI/CardMoons/CardMoons'
import { DataTableMoons } from '../../../UI/DataTableMoons/DataTableMoons'
import messages from './DoctorList.messages'
import { AppContext } from '../../../../4-hooks/contexts/App/App.context'
import { getRowsDoctors, onDoctorClick, getListTitle } from './DoctorList.logic'
import { TooltipMoons } from '../../../UI/TooltipMoons/TooltipMoons'
import { IconMoons } from '../../../UI/IconMoons/IconMoons'
import NoRowsButton from '../../../UI/NoRowsButton/NoRowsButton'
// import styles from './DoctorList.module.scss'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * doctor list component
 * @returns {Void} component
 */
export const DoctorList = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(null)
  const [doctors, setDoctors] = useState([])
  const isRendered = useRef(false)
  const { stateApp: { historyApp, functions } } = useContext(AppContext)
  const { handleSnack } = functions || {}
  const location = useLocation()

  useEffect(() => {
    isRendered.current = true
    setIsLoading(true)
    getRowsDoctors(location)
      .then(_doctors => {
        if (isRendered.current) {
          setDoctors(_doctors)
          setIsError(null)
          setIsLoading(false)
        }
      })
      .catch(error => {
        handleSnack(true, 'Error al traer la lista de pacientes', 'error')
        setIsError(true)
        setIsLoading(false)
      })

    return () => {
      isRendered.current = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * No rows componente custom overlay
   * @returns {Void} component
   */
  const renderNoRows = useCallback(() => (
    <NoRowsButton
      message={messages.noRowsMsg}
    />
  ), [])

  return (
    <CardMoons
      title={messages.title.replace('[typeList]', getListTitle(location))}
      heightFull
      actionHeader={(
        <TooltipMoons
          title="Nuevo Doctor"
          placement="left"
        >
          <Link to={`/${prefijApp}/doctor/new`}>
            <IconMoons name="add_circle" />
          </Link>
        </TooltipMoons>
      )}
    >
      <DataTableMoons
        id={Math.random()}
        rows={doctors}
        columns={messages.columnsTable}
        pageSize={8}
        loading={isLoading}
        error={isError}
        components={{
          NoRowsOverlay: renderNoRows
        }}
        onCellClick={(rowData) => onDoctorClick(rowData, historyApp)}
      />
    </CardMoons>
  )
}
