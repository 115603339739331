/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { MedicalHistory } from '../../../3-components/App/MedicalHistory/MedicalHistory'
import { Treatment } from '../../../3-components/App/Treatment/Treatment'
// import { ButtonMoons } from '../../../3-components/UI'
import styles from './PatientPlanningView.module.scss'
import messages from './PatientPlanningView.messages'
import { StepperMoons } from '../../../3-components/UI/StepperMoons/StepperMoons'
import { useStepper } from '../../../4-hooks/useStepper'
import { PicturesPatient } from '../../../3-components/App/PicturesPatient/PicturesPatient'
import { setStepActive } from './PatientPlanningView.logic'
import StlMethod from '../../../3-components/App/Planning/StlMethod/StlMethod'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * Patient Planning View to handle medical records stepper
 * @returns {Void} component
 */
export const PatientPlanningView = () => {
  const [
    activeStep,
    setActiveStep, , ,
    // handleReset
  ] = useStepper(null)
  // Parametros Nombre del componente y Id del planning
  const { component } = useParams()

  useEffect(() => {
    if (
      component !== 'treatment' && component !== 'medical-history'
      && component !== 'pictures-patient' && component !== 'collection-method'
    ) {
      window.location = `/${prefijApp}/home`
      return
    }
    setStepActive({ component }, { setActiveStep })
  }, [component, setActiveStep])

  return (
    <div className={styles.containerPatientPlanning}>
      <StepperMoons
        titlesSteps={messages.titles}
        contentSteps={
          [
            <MedicalHistory
              key="med-hist-step"
              urlNextStep="./treatment"
            />,
            <Treatment
              key="treat-step"
              urlNextStep="./pictures-patient"
              urlBackStep="./medical-history"
            />,
            <PicturesPatient
              key="pictires-step"
              urlNextStep="./collection-method"
              urlBackStep="./treatment"
            />,
            <StlMethod
              key="stl-method-step"
              urlBackStep="./pictures-patient"
            />
          ]
        }
        activeStep={activeStep}

        /*
         * contentFinish={
         *     <ButtonMoons size='small' onClick={handleReset}>
         *         {messages.buttonFinish}
         *     </ButtonMoons>
         * }
         */
      />
    </div>
  )
}
