import { RadioModelRadioButtonsGroupMoons } from '../../UI/RadioButtonsGroupMoons/Radio.model'

const MedicalHistoryMessages = {
  title: 'Historial Médico de [patient]',
  birthDate: 'Fecha de nacimiento',
  question1: '1. ¿Qué objetivos buscas en tu paciente?',
  question2: '2. ¿El paciente toma algún medicamento con regularidad?',
  placeHolder2: '¿Cuál?',
  question3: '3. ¿El paciente tiene alguna patología sistemática?',
  placeHolder3: '¿Cuál?',
  question4: '4. ¿El paciente ha tenido ortodoncia?',
  placeHolder4: '¿Cuándo? y ¿Por cuánto tiempo?',
  question5: '5. ¿El paciente ha tenido puentes o implantes dentales?',
  placeHolder5: '¿En qué dientes?',
  question6: '6. ¿El paciente tiene coronas / carillas / resinas vestibulares?',
  placeHolder6: '¿En qué dientes?',
  question7: '7. ¿Presenta alteración en la ATM?',
  button: 'Guardar',
  buttonBack: 'Paso anterior',
  optionalMsg: 'Este paso no es obligatorio, sin embargo, mientras más información tengamos, podemos mejorar los planes de tratamiento.',
  optionalLink: 'Ir al siguiente paso',
  listGender: [
    new RadioModelRadioButtonsGroupMoons({
      text: 'Femenino',
      value: 'F'
    }),
    new RadioModelRadioButtonsGroupMoons({
      text: 'Masculino',
      value: 'M'
    })
  ],
  listPatientTarget: [
    {
      name: 'bite',
      label: 'Mordida'
    },
    {
      name: 'alignment',
      label: 'Alineación'
    },
    {
      name: 'separation',
      label: 'Separación'
    },
    {
      name: 'esthetic',
      label: 'Estética'
    }
  ],
  listAlterationInAtm: [
    {
      name: 'difficultyOpeningYourMouth',
      label: 'Dificultad para abrir la boca'
    },
    {
      name: 'crepitation',
      label: 'Chasquidos o crepitación'
    },
    {
      name: 'pain',
      label: 'Dolor'
    }
  ]
}

export default MedicalHistoryMessages
