/* eslint-disable import/prefer-default-export */
/**
 * menu nav bar model
 */
export class MenuModelNavBarMoons {
  /**
   * constructor
   * @param {*} object nav bar model
   */
  constructor(object = {}) {
    this.text = object.text || ''
    this.icon = object.icon || ''
    this.action = object.action || (() => null)
  }
}
