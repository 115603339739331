/* eslint-disable max-statements */
import moment from 'moment-timezone'
import { updatePlanning } from '../../../7-services/App/Planning.service'
import { updateShipping } from '../../../7-services/App/Shipping.service'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * Handle edit Shipping for pickup_requested
 * PUT Planngin state: printing, status: waiting_printing
 * Edit Shipping: label field
 * @param {*} event submit event
 * @param {Object} param1 .
 * @param {Object} param1.formValues values from the form : Shipping dto
 * @param {Object} param1.user authenticated user
 * @param {Function} param1.historyApp router history
 * @param {String} param1.planningId Planning objectId to PUT state/status
 * @param {String} param1.shippingId Shipping objectId to PUT
 * @param {Boolean} param1.changeStatus flag to update Planning or not
 * @param {Object} param2 .
 * @param {Function} param2.handleSnack function to open snackbar
 * @param {Function} param2.setInProgress function to set in progress flag
 * @returns {Void} api call
 */
export const handleSubmitForm = async (
  event,
  {
    formValues, user, historyApp, planningId, shippingId, changeStatus
  },
  { handleSnack, setInProgress }
) => {
  event.preventDefault()

  const messageError = validateForm(formValues)
  if (messageError) {
    handleSnack(true, messageError, 'error')
    return
  }

  setInProgress(true)
  const shippingData = { ...formValues }
  shippingData.datetime = moment(shippingData.datetime).utc(true).toString()
  shippingData.updatedBy = user.objectId

  try {
    await updateShipping({
      token: user.sessionToken,
      shipping: shippingData,
      shippingId,
    })
  } catch (error) {
    handleSnack(true, 'Error al actualizar la Recolección', 'error')
    setInProgress(false)
    return
  } finally {
    if (!changeStatus) {
      setInProgress(false)
      historyApp.push(`/${prefijApp}/patients`)
    }
  }

  if (changeStatus) {
    try {
      const updateReq = {
        state: 'printing',
        status: 'waiting_printing'
      }
      await updatePlanning(user.sessionToken, planningId, updateReq)
      historyApp.push(`/${prefijApp}/patients`)
    } catch (error) {
      handleSnack(true, 'Error al editar la Planeación', 'error')
    } finally {
      setInProgress(false)
    }
  }
}

/**
 * validate form
 * @param {*} formValues Form Shipping dto
 * @returns {String} error message if exists
 */
const validateForm = (formValues) => {
  const { datetime, label } = formValues
  let message = ''

  /**
   * Return if message if empty
   * @returns {Boolean} message empty
   */
  const isEmptyMessage = () => message === ''

  if (isEmptyMessage() && !datetime) {
    message = 'Necesitas escoger una fecha'
  } else if (isEmptyMessage() && !label) {
    message = 'Etiqueta es campo requerido'
  }

  return message
}

export default handleSubmitForm
