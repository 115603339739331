const CommentsMessages = {
  message1: 'Comentar',
  icon1: 'account_circle',
  icon2: 'group_work',
  replanning: {
    button: 'Solicitar replaneación',
    success: 'Se ha solicitado la replaneación',
    error: 'Error al solicitar la replaneación',
    info: 'Por favor escribe el motivo de la replaneación'
  }
}

export default CommentsMessages
