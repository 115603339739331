/* eslint-disable import/prefer-default-export */
import { useState } from 'react'

/**
 * hook use form
 * @param {*} initialState form state object
 * @returns {Array} state ariables and functions
 */
export const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState)
  const [progress, setProgress] = useState(false)

  /**
   * reset form values
   * @returns {Void} reset form
   */
  const reset = () => {
    setValues(initialState)
    setProgress(false)
  }

  /**
   * handle change for normal input
   * @param {*} param0 event.target
   * @returns {Object} update state
   */
  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value
    })
  }

  /**
   * set progress flag
   * @param {*} status progress flag
   * @returns {Void} update state
   */
  const setInProgress = (status) => {
    setProgress(status)
  }

  /**
   * handle input change for check group
   * @param {*} param0 event.target
   * @param {String} nameProperty field name
   * @returns {Void} update state
   */
  const handleCheckGroupChange = ({ target }, nameProperty) => {
    setValues({
      ...values,
      [nameProperty]: { ...values[nameProperty], [target.name]: target.checked }
    })
  }

  /**
   * handle change for date picker
   * @param {*} param field value
   * @param {String} property field name
   * @returns {Void} update state
   */
  const handleDatePickerChange = (param, property = '') => {
    setValues({
      ...values,
      [property]: param
    })
  }

  /**
   * handle change for switch
   * @param {Objct} param0 event.target
   * @returns {Void} update state
   */
  const handleSwitchChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.checked
    })
  }

  return [
    values,
    setValues,
    handleInputChange,
    reset,
    progress,
    setInProgress,
    handleCheckGroupChange,
    handleDatePickerChange,
    handleSwitchChange
  ]
}
