import { requestMoons } from '../../8-common/Request'

/**
 * Create comment api call
 * @param {*} param0 .
 * @param {*} param0.token autheticated user's token
 * @param {*} param0.planningId Planning objectId to relate comment to
 * @param {*} param0.comment comment object with string
 * @returns {Void} api call
 */
export const createComment = ({ token, planningId, comment }) => requestMoons({
  endpoint: `/planning/${planningId}/comment`,
  method: 'POST',
  body: { comment },
  customHeaders: {
    Authorization: token
  }
})

/**
 * get list of comments related to planning
 * @param {String} token autheticated user's token
 * @param {String} planningId Planning objectId to relate comments to
 * @returns {Array} list of comments
 */
export const getComments = (token, planningId) => requestMoons({
  endpoint: `/planning/${planningId}/comments`,
  method: 'GET',
  customHeaders: {
    Authorization: token
  }
})
