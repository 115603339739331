/* eslint-disable react/jsx-no-literals */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import { FormWrapper } from '../../../3-components/Login/FormWrapper/FormWrapper'
import { CreateAccountForm } from '../../../3-components/Login/CreateAccountForm/CreateAccountForm'
import { LoginForm } from '../../../3-components/Login/LoginForm/LoginForm'

/**
 * Access view component
 * @returns {Void} component
 */
export const AccesView = () => (
  <>
    <FormWrapper classContainer="signUpContainer">
      <CreateAccountForm />
    </FormWrapper>
    <FormWrapper classContainer="signInContainer">
      <LoginForm />
    </FormWrapper>
  </>
)
