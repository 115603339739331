/* eslint-disable no-console */
import { updatePlanning } from '../../../../../7-services/App/Planning.service'

// eslint-disable-next-line no-process-env
const prefijApp = process.env.REACT_APP_PREFIJ_APP

/**
 * handle request appointment
 * @param {Object} param0 .
 * @returns {Void} api call
 */
const handleRequestAppmnt = async ({ planningId, user, historyApp }) => {
  try {
    const putPlannReq = {
      stlMethod: 'appointment',
      state: 'stl_required',
      status: 'waiting_appointment'
    }
    await updatePlanning(user.sessionToken, planningId, putPlannReq)
    historyApp.push(`/${prefijApp}/patient-planning/${planningId}/collection-method`)
  } catch (error) {
    console.log('error update planning', error)
  }
}

export default handleRequestAppmnt
