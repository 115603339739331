/* eslint-disable max-statements */
/* eslint-disable import/prefer-default-export */
import { recoveryPassService } from '../../../7-services/LoginService/Login.service'
import { validarEmail } from '../../../8-common/UtilFunctions'
import { errorCodes } from '../../../8-common/Constants'

/**
 * handle submit recovery
 * @param {Object} event event
 * @param {Object} formValues form data
 * @param {Object} param2 .
 * @param {Function} param2.reset reset form func
 * @param {Function} param2.handleSnack open snackbar
 * @param {Function} param2.setInProgress change progress flag
 * @returns {Void} handle submit
 */
export const handleSubmitFormRecovery = async (
  event, formValues, { reset, handleSnack, setInProgress }
) => {
  event.preventDefault()

  const messageError = validateRecoveryForm(formValues)
  if (messageError) {
    handleSnack(true, messageError, 'error')
    return
  }

  try {
    setInProgress(true)
    await recoveryPassService(formValues)
    handleSnack(true, 'Los pasos para recuperar tu contraseña se han enviado a tu email.', 'success')
    reset()
    setInProgress(false)
  } catch (error) {
    console.error(error)
    handleSnack(true, errorCodes[error.code] || 'Error al recuperar contraseña', 'error')
    setInProgress(false)
  }
}

/**
 * validate recover form
 * @param {*} formValues form data
 * @returns {String} message if invalid
 */
const validateRecoveryForm = (formValues) => {
  const { email } = formValues
  let message = ''

  /**
   * is empty message
   * @returns {Boolean} is empty string
   */
  const isEmptyMessage = () => message === ''

  if (isEmptyMessage() && email === '') {
    message = 'Debes ingresar un correo.'
  }
  if (isEmptyMessage() && !validarEmail(email)) {
    message = 'Debes ingresar un email valido.'
  }

  return message
}
